import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import  MenuHeader from "../home/MenuHeader"
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import http from "../../http-common";
import FormRx from './FormRx';
const drawerWidth = 240;
moment.locale("th");
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
}));

export default function Rxorder() {
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const history = useHistory();
  const [open,setOpen] = React.useState(false);
  const [rxlistuser,setRxlistuser] = React.useState([{id:"",visit_date:"",visit_time:"",pt_prefix_id:"",pt_firstname:"",pt_lastname:""}]);
  const [selectuser,setSelectuser]= React.useState("");
  const [rowsdata,setRowdata] = React.useState<any>([{id:"",visit_date:"",visit_time:"",pt_prefix_id:"",pt_firstname:"",pt_lastname:"", ptid: "",visit_status:""}]);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
const [statusdata, setStatusdata] = React.useState('2');

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusdata(event.target.value as string);
    getlistoder(moment(selectedDate).format('YYYY-MM-DD'),event.target.value);
    
  };
   const getlistoder = async (dates:any,statusnew:any)=>{
     
    await  http.get("rxorder/listvisitrxorder?stdate="+dates+"&statusdata="+statusnew, header).then(json => {
      setRxlistuser(json.data.items)
      setSelectuser("")
      setRowdata([])
    }).catch(function (error) {
      history.replace({
        pathname: `/login`,
      })
    });
}
  useEffect(() =>{
   getlistoder(moment(selectedDate).format('YYYY-MM-DD'),statusdata);
  }, [])
 
  

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    getlistoder(moment(date).format('YYYY-MM-DD'),statusdata);
  };
  return (
    <div className={classes.root}>
       
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
         <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <MenuHeader/>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid  container spacing={3}>
            <Grid  item xs={12}>
               {appContext.patient}
               <Card >
               <CardHeader
      titleTypographyProps={{variant:'subtitle1' }} title="จ่ายยา"/>
      <CardContent>
      <Grid  container spacing={2}>
        
          <Grid  item xs={4}>
          <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                                                <DatePicker
                                                    id="nextdate"
                                                    label="วันที่มารับบริการ"
                                                    name="nextdate"
                                                    format="DD/MM/YYYY"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={selectedDate}
                                                    yearOffset={543}
                                                    onChange={(e:any) => {
                                                      if(e !== null){
                                                        console.log(e);
                                                       handleDateChange(e)
                                                      }
                                                    }
                                                    }
                                                    inputVariant="outlined"
                                                
                                                />
        </MuiPickersUtilsProvider>
<br></br>
        <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">เลือกสถานะ</InputLabel>
        <Select
          native
          value={statusdata}
          onChange={handleChange}
          label="สถานะ"
          inputProps={{
            name: 'statuss',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="รอจ่ายยา" value="" />
          <option value={2}>รอจ่ายยา</option>
          <option value={3}>จ่ายยาแล้ว</option>
        </Select>
      </FormControl>
          <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>ลำดับ</TableCell>
            <TableCell>วันที่/เวลา</TableCell>
            <TableCell>ชื่อ สกุล</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rxlistuser.map((row,i) => (
            <TableRow key={row.id}>
               <TableCell>{i+1}</TableCell>
          <TableCell>{row.visit_date} {row.visit_time}</TableCell>
          <TableCell>{row.pt_prefix_id}{row.pt_firstname} {row.pt_lastname}</TableCell>
              <TableCell><Button  onClick={() => {
                setSelectuser(row.pt_prefix_id+row.pt_firstname+" "+row.pt_lastname)
                setRowdata(row)
                
              }}  color="secondary" >
                      เลือก
                    </Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
          </Grid>
          <Grid  item xs={8}>
{ selectuser != "" ? <FormRx selectusers={selectuser} 
handleRefashdrug={() => {
  getlistoder(moment(selectedDate).format('YYYY-MM-DD'),statusdata);
}}
rowsdata={rowsdata} /> :""}
          </Grid>
      </Grid>
    </CardContent>
    </Card>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}