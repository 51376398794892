import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import ArchiveIcon from '@material-ui/icons/Archive';
import PersonIcon from '@material-ui/icons/Person';
import CardTravel from '@material-ui/icons/CardTravel';
import CreditCard from '@material-ui/icons/CreditCard';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useHistory, useRouteMatch} from "react-router-dom";
export default function  MainListItems(){
  const history = useHistory();

  const changeMenu = (viewPath:any) => {
      history.push(viewPath);
     // props.onChange();
  }
  return (
<div><div>
<List>
    <ListItem onClick={() => changeMenu('/')} button>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="EMR" />
    </ListItem>
    <ListItem onClick={() => changeMenu('/patient')}  button>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Patient"  />
    </ListItem>
    <ListItem onClick={() => changeMenu('/rxorder')}  button>
      <ListItemIcon>
        <CardTravel />
      </ListItemIcon>
      <ListItemText primary="จ่ายยา"  />
    </ListItem>
    <ListItem onClick={() => changeMenu('/finance')}  button>
      <ListItemIcon>
        <CreditCard />
      </ListItemIcon>
    <ListItemText primary="การเงิน"  />
    </ListItem>
    <ListItem onClick={()=>changeMenu('/appointment')} button>
      <ListItemIcon>
        <EventIcon />
      </ListItemIcon>
      <ListItemText primary="Appointment"  />
    </ListItem>
    <ListItem onClick={() => changeMenu('/drug')} button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Drug"   />
    </ListItem>
    <ListItem onClick={() => changeMenu('/excellimport')} button>
      <ListItemIcon>
        <ArchiveIcon />
      </ListItemIcon>
      <ListItemText primary="ItemImport" />
    </ListItem>
    <ListItem onClick={() => changeMenu('packgate')} button>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Packgate" />
    </ListItem>
   
    <ListItem onClick={() => changeMenu('report')} button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Report" />
    </ListItem>
    <ListItem onClick={() => changeMenu('/listuser')} button>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Usermanage"  />
    </ListItem>
    <ListItem  onClick={() => changeMenu('/config')} button>
      <ListItemIcon>
        <BrightnessHighIcon />
      </ListItemIcon>
      <ListItemText primary="Config" />
    </ListItem>
    </List>
  </div>
  </div>
  )
}

