import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import http from "../../http-common";
import Switch from '@material-ui/core/Switch';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '780px',
},
layout: {
  width: 'auto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},

  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  MuiFormControlmarginNormal:{
    marginBottom:'8px'
},
  title: {
    flexGrow: 1,
    backgroundColor:"#cccccc",
    paddingTop:'15px',
    paddingRight:'40px',
    paddingBottom:'15px',
    paddingLeft:'15px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const initialState = {
  items: [],
  amphur:[],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_amphur": {
        const {amphur} = action.payload;
        return {
            ...state,
            amphur
        }
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function Unitform(props:any) {
    
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const [page, setPage] = React.useState(0);
  
  const [openunitform,setOpenunitform] =  React.useState(false);
  const [pega_namelist,setPega_namelist] =  React.useState([{id_unit:"",name_unit:""}]);
  const [pega_name,setPega_name] =  React.useState([{id_unit:"",name_unit:""}]);
  const [drug_unit,setDrugunit]= React.useState([{id_drug_unit:"",price_sale_unit:"",price_drug_unit:"",qty_drug_unit:"",id_drug:"",id_unit:""}]);
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const {id,pega,drug_units} = props;
  const [qty_drug_unit,setQty_drug_unit] = React.useState("");
  const [price_drug_unit,setPrice_drug_unit] = React.useState("");
  const [price_sale_unit,setPrice_sale_unit] =  React.useState("");
  const [id_drug,setId_drug] = React.useState("");
  const [id_drug_unit,setId_drug_unit] = React.useState("");
  const [states, setState] = React.useState({
    status_active: false
  });
  const handleCloseOpendia = () => {
    props.handleCloseOpendia();
  };

  useEffect(() => {
  
       http.get('drug/unitlist', header).then(json => {
        setPega_namelist(json.data.items)
      
      }).catch(function (error) {
        history.replace({
          pathname: `/login`,
        })
      })
    
     if(props.open){
      setPega_name(pega)
      setDrugunit([{id_drug_unit:drug_units.id_drug_unit,price_sale_unit:drug_units.price_sale_unit,price_drug_unit:drug_units.price_drug_unit,qty_drug_unit:drug_units.qty_drug_unit,id_drug:drug_units.id_drug,id_unit:drug_units.id_unit}])
      //setQty_drug_unit(drug_units.qty_drug_unit)
      setQty_drug_unit(drug_units[0].qty_drug_unit)
      setPrice_drug_unit(drug_units[0].price_drug_unit)
      setPrice_sale_unit(drug_units[0].price_sale_unit)
      setId_drug(drug_units[0].id_drug);
      setId_drug_unit(drug_units[0].id_drug_unit);
      console.log(drug_units)
      if(drug_units[0].status_active == '1'){
        setState({ ...states, ['status_active']: true });
      }else{
        setState({ ...states, ['status_active']: false });
      }
      
     }
  }, [props.open,pega,drug_units])

const doSubmit = (data: any) => {
  console.log(data)
    // http.post("drug?id="+id, data, header)
    // .then(function (response: any) {
    //     if(response.data.ok){
    //         alert(response.data.message)
    //         history.replace({
    //             pathname: `/drug`,
    //           })
    //     } else {
    //         alert(response.data.message)
    //     }
        
    // })
};
const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setState({ ...states, [event.target.name]: event.target.checked });
};
const handleSave = (e:any) => {
  const data = {
    pega_name:pega_name,
    qty_drug_unit:qty_drug_unit,
    price_drug_unit:price_drug_unit,
    price_sale_unit:price_sale_unit,
    id_drug:id_drug,
    status_active:states.status_active
  }
  
    http.post("drug/saveunitform?id="+id_drug_unit, data, header)
    .then(function (response: any) {
        if(response.data.ok){
            alert(response.data.message)
            props.handleCloseOpendia();
        } else {
            alert(response.data.message)
        } 
    })
  // 
}
const form = (
  <React.Fragment>
      <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate>
        <Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={handleCloseOpendia} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">Unit form</DialogTitle>
   <DialogContent>
     
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
          <Typography className={classes.title}  gutterBottom>
          เพิ่ม/แก้ไข ข้อมูลหน่วยการขาย
        </Typography>
          </Grid>
            <Grid item xs={12} sm={12}>
            <Autocomplete
       value={pega_name[0]}
      id="pega_name"
      onChange={(event: any, newValue: any) => {
        setPega_name([{id_unit:newValue.id_unit,name_unit:newValue.name_unit}]);
          }}
          getOptionSelected={(option, value) => option.id_unit === value.id_unit}
         getOptionLabel={(option) => option.name_unit + ""}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ชื่อหน่วย"
          variant="outlined"
        />
      )}
    />
          </Grid>
          <Grid item xs={4} sm={4}>
          <TextField
          value={qty_drug_unit}
          onChange={(e) => {
            setQty_drug_unit(e.target.value)
        }}
                                            name="qty_drug_unit"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="qty_drug_unit"
                                            label="จำนวน"  
                                            inputRef={register()
                                        }
                                        />
          </Grid>  
          <Grid item xs={4} sm={4}>
          <TextField
          value={price_drug_unit}
          onChange={(e) => {
            setPrice_drug_unit(e.target.value)
        }}
                                            name="price_drug_unit"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            
                                            id="price_drug_unit"
                                            label="ราคาต่อหน่วยทุน" 
                                            inputRef={register()
                                            }
                                        />
          </Grid>  
          <Grid item xs={4} sm={4}>
          <TextField
          value={price_sale_unit}
          onChange={(e) => {
            setPrice_sale_unit(e.target.value)
        }}
                                            name="price_sale_unit"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="price_sale_unit"
                                            label="ราคาต่อหน่วยขาย"  
                                            inputRef={register()
                                            }
                                        />
          </Grid>  
          <Grid item xs={4} sm={4}>
          <FormGroup row>
      <FormControlLabel
        control={<Switch checked={states.status_active} onChange={handleChange} name="status_active" />}
        label="สถานะ"
      />
      </FormGroup>
            </Grid>
          </Grid>
    </DialogContent>
    <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleCloseOpendia} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>
 </form>
    </React.Fragment>
);
  return (
    <div className={classes.root}>
    {form}
    </div>
  );
}