import React from "react"
import dateFormat from 'dateformat'
import {deepPurple, green, grey, red} from '@material-ui/core/colors';

dateFormat.i18n = {
    dayNames: [
        "อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์",
        "อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์"
    ],
    monthNames: [
        "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค.",
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

export function calAge(dob1: string) {
let age_now:any;
   // let age_now = '-';
    if (dob1) {
        // dob1 = dob1.split(delimiter)
        // dob1 = dob1[2] + delimiter + dob1[1] + delimiter + dob1[0]
        const today = new Date();
        let birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        age_now = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
    }

    return age_now;
}

export function calMonth(dob1: string) {
    var today = new Date();
    var DOB = new Date(dob1);
    var totalMonths = (today.getFullYear() - DOB.getFullYear()) * 12 + today.getMonth() - DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    var years = today.getFullYear() - DOB.getFullYear();
    if (DOB.getMonth() > today.getMonth())
        years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate())
            years = years - 1;

    var days;
    var months;

    if (DOB.getDate() > today.getDate()) {
        months = (totalMonths % 12);
        if (months === 0)
            months = 11;
        var x = today.getMonth();
        switch (x) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12: {
                var a = DOB.getDate() - today.getDate();
                days = 31 - a;
                break;
            }
            default: {
                let a = DOB.getDate() - today.getDate();
                days = 30 - a;
                break;
            }
        }

    }
    else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth())
            months = (totalMonths % 12);
        else
            months = (totalMonths % 12) + 1;
    }
    if(!isNaN(years)) {
        return  'อายุ '+ years + ' ปี ' + months + ' เดือน ' + days + ' วัน';
    }else {
        return "";
    }
   // const  return  'อายุ '+ years + ' ปี NaN' + months + ' เดือน ' + days + ' วัน';
    
    }
export function calDay(dob1: string) {
        let age_now:any;
           // let age_now = '-';
            if (dob1) {
                // dob1 = dob1.split(delimiter)
                // dob1 = dob1[2] + delimiter + dob1[1] + delimiter + dob1[0]
                const today = new Date();
                let birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
                age_now = today.getFullYear() - birthDate.getFullYear();
                age_now= today.getDate();
            }
        
            return age_now;
        }
export function thDate(date:any, format = "dd mmmm yyyy") {
    //   let result = '';
    //   if(date){
    //     date = date.split(delimiter)
    //     date = (date[0] + 543) + delimiter + date[1] + delimiter + date[2]

    //     result = dateFormat(date, "d mmmm yyyy");
    //   }
    try{
        return dateFormat(date, format);
    }catch (e){
        console.error( 'parse date error',date)
        return date;
    }

}

export function convertDrugStatus(status:any) {
    let result = ''
    switch (status) {
        case "create":
            result = "เตรียมใบยา";
            break;
        case "send":
            result = "ส่งใบยา";
            break;
        case "receive":
            result = "รับใบยา";
            break;
        case "pay":
            result = "จ่ายยา";
            break;
        case "DONE":
            result = "ผลออกแล้ว";
            break;
        default:
            break;
    }

    return result
}

export function convertOrderStatus(status:any) {
    // let result = ''
    // switch (status) {
    //     case "create":
    //         result = (<span style={{backgroundColor: grey[100], color: red[300]}}>{"เตรียมส่งตรวจ"}</span>)
    //         break;
    //     case "send":
    //         result = (<span style={{backgroundColor: grey[100], color: green[300]}}>{"ส่งตรวจแล้ว"}</span>);
    //         break;
    //     case "receive":
    //         result = (<span style={{backgroundColor: deepPurple[100], color: deepPurple[300]}}>{"รับทำการแล้ว"}</span>);
    //         break;
    //     case "DONE":
    //         result = (<span style={{backgroundColor: grey[100], color: green[300]}}>{"ออกผลแล้ว"}</span>);
    //         break;
    //     default:
    //         break;
    // }

    // return result
}

export function currencyFormat(num:any) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
