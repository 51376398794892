import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import http from "../../http-common";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
const drawerWidth = 240;
moment.locale("th");
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  multilineColor:{
    color:'red'
},
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
}));

export default function Formconfirm(props:any) {
    const classes = useStyles()
    const {opendialogeditdrug,rowsdata,editdata,sumdata} = props;
    const [iddata,setIddata] = React.useState("");
    const [monyselect,setMonyselect] = React.useState("");
    const [bill_recive_price,setBill_recive_price] = React.useState("");
    const [bill_tron,setBill_tron]= React.useState("");
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [drugselect,setDrugselect]= React.useState([{tradname: "",strength:"",dosage:"",type_item:"",id:""}]);
    const [order_tran_pay,setOrder_tran_pay]= React.useState("");
    const handleClosedialog = ()=>{
        props.handleClosedialog();
    }
    useEffect(() =>{
      setIddata("")
      console.log(rowsdata)
    },[opendialogeditdrug,editdata,rowsdata])
    const handleSave = (e:any) => {
        const data = {
           vn:rowsdata.id,
           bil_sumprice:sumdata,
           bill_recive_price:bill_recive_price,
           bill_tron:bill_tron,
           bill_type:monyselect,
           bil_status:'A',
           bill_date:moment().format('YYYY-MM-DD')
        }
            http.post( "bill/",data, header).then(json => {
              props.handleClosedialog();
             });          
            }
 
            

    const handleChange = (event:any) => {
        const name = event.target.value;
        setMonyselect(name)
    };
     const onCalprice = async (value:any)=>{
         const sumprice = Number(value) - Number(sumdata);
         setBill_tron(String(sumprice));
     };
    const form = (
        <React.Fragment>
             <form onSubmit={e => {
    handleSave(e)
}}>
<Dialog fullWidth={true} maxWidth="md" open={opendialogeditdrug} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">ชำระเงินออกใบเสร็จ</DialogTitle>
<DialogContent>
<Grid container spacing={2}>
<Grid item xs={12} sm={12}>
           <TextField
                   name="bil_sumprice"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   value={sumdata}
                   aria-readonly={true}
                   id="bil_sumprice"
                   label="จำนวนเงินที่ต้องชำระ"
                   autoFocus
               />
</Grid>
<Grid item xs={12} sm={12}>
           <TextField
                   name="bill_recive_price"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   value={bill_recive_price}
                   InputProps={{
                    className: classes.multilineColor
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    setBill_recive_price(value);
                   onCalprice(value);
                   }}
                   aria-readonly={true}
                   id="bill_recive_price"
                   label="รับเงินมา"
                   autoFocus
               />
</Grid>
<Grid item xs={12} sm={12}>
           <TextField
           value={bill_tron}
                   name="bill_tron"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   onChange={(e) => {
                    // const value = e.target.value;
                    // setOrder_qty(value);
                    // onCalprice(value);
                   }}
                   aria-readonly={true}
                   id="bill_tron"
                   label="ทอน"
               />
</Grid>
<Grid item xs={12} sm={12}>
<FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">การชำระเงิน</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          label="Age"
          value={monyselect}
          onChange={handleChange}
        >
          <MenuItem value={"เงินสด"}>เงินสด</MenuItem>
          <MenuItem value={"เงินโอน"}>เงินโอน</MenuItem>
          <MenuItem value={"บัตรเคดิส"}>บัตรเคดิส</MenuItem>
        </Select>
      </FormControl>
    </Grid>
</Grid>
</DialogContent>
<DialogActions>
<Button variant="contained" onClick={handleSave} color="primary">
บันทึกข้อมูล
</Button>
<Button variant="contained" onClick={handleClosedialog} color="secondary">
Cancel
</Button>
</DialogActions>
</Dialog>
</form>
        </React.Fragment>
    );
    return (
        <div className={classes.root}>
        {form}
        </div>
      );
}