import React, {useEffect,useState, useRef,useReducer} from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CanvasDraw from "../lib/index";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import { constants } from 'crypto';
import http from "../../http-common";
import CssBaseline from "@material-ui/core/CssBaseline";
import DrawCanvas from "./DrawCanvas";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Bp from './bp/Bp';
import useSafeFunction from "../usermanage/useSafeFunction";
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import 'moment/locale/th';
import * as mqtt from "mqtt"
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
  pullRight: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY: "auto",
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
  const initialState = {
    items: [],
    loading: false,
    error: null,
    filter: null
   
  };
  function reducer(state: any, action: any) {
    switch (action.type) {
      case "fetch_start": {
        return {
          ...state,
          loading: true,
          error: null
        };
      }
      case "fetch_success": {
        const { items } = action.payload;
        return {
          ...state,
          items,
          loading: false,
         
        };
      }
      case "fetch_failure": {
        const { error } = action.payload;
        return {
          ...state,
          items: [],
          loading: false,
          error
        };
      }
      default:
        throw new Error("Missing action type");
    }
  }
export default function Pe(props:any) {
    const classes = useStyles();
    const [state, $dispatch] = useReducer(reducer, {
      ...initialState
    });
    const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
    const [opdscreen, setOpdscreen]= React.useState<any>([]);
    const {initData, saveCallback,loaddatanew} = props;
    const [visitdate, setVisitdate] = React.useState('');
    const [width,setWidth] =  React.useState<any>('');
    const [height,setHeight] =  React.useState('');
    const [temp,setTemp] =  React.useState('');
    const [vn, setVn] = React.useState('');
    const [bw, setBw] = React.useState('');
    const [bps, setBps] = React.useState('');
    const [bpd, setBpd] = React.useState('');
    const [cc,setCc]= React.useState('');
    const [pulse, setPulse] = React.useState('');
    const [pi,setPi]= React.useState('');
    const [ph,setPh]= React.useState('');
    const [fh,setFh]= React.useState('');
    const [notecc,setNotecc]= React.useState('');
    const [notepe,setNotepe]= React.useState('');
    const [pe, setPe] = React.useState('');
    const [diag, setDiag] = React.useState('');
    const [bmp, setBmp] = React.useState('');
    const [bmi,setBMI] = React.useState('');
    const [pega_name,setPega_name] =  React.useState([{pega_name:""}]);
    const [pega_namelist,setPega_namelist] =  React.useState([{pega_name:""}]);
    const [pe_ga_detail,setPe_ga_detail]= React.useState([{pega_detail_name:""}]);
    const [pe_ga_detaillist,setPe_ga_detaillist]= React.useState([{pega_detail_name:""}]);
    const [pe_ga_comment,setPe_ga_comment]= React.useState('');
    const [heent,setHeent] =  React.useState([{pega_name:""}]);
    const [heent_detail,setHeent_detail] =  React.useState([]);
    const [heent_detail_list,setHeent_detail_list] =  React.useState([{name_heent:""}]);
    const [need,setNeed]= React.useState<any[]>([{pe_ga:""}]);
    const [hart,setHart]= React.useState<any[]>([{pe_ga:""}]);
    const [heent_comment,setHeent_comment]= React.useState('');
    const [lungs,setLungs]= React.useState([{pega_name:""}]);
    const [lungs_detail,setLungs_detail]= React.useState<any[]>([{pe_ga:""}]);
    const [lungs_comment,setLungs_comment]= React.useState('');
    const [abbdomen,setAbbdomen] = React.useState([{pega_name:""}]);
    const [abbdomen_detail,setAbbdomen_detail]= React.useState<any[]>([]);
    const [abbdomenldetailist,setAbbdomenldetailist] = React.useState([{name_abbdomen:""}]);
    const [abbdomen_comment,setAbbdomen_comment]= React.useState('');
    const [ext,setExt] =  React.useState([{pega_name:""}]);
    const [image_sex,setImage_sex] = React.useState<any>("http://45.32.107.235/1074311.jpg");
    const [ext_comment,setExt_comment]= React.useState('');
    const [neck_comment,setNeck_comment]= React.useState('');
    const [hart_comment,setHart_comment]= React.useState('');
    const [pecodelist,setPecodelist] = React.useState<any>([{ name:""}]);
    const [opendialog, setOpendialog] = React.useState(false);
    const [focus1,setFocus1] = React.useState(true);
    const [focus2,setFocus2] = React.useState(false);
    const [openmodal, setOpenmodal] = React.useState(false);
    const drawingRef = useRef();
    const [drawingData, setDrawingData] = React.useState<any>(null);
    const [diagcode,setDiagcode]= React.useState<any>([{ code:"",name:"",code3:""}]);
    const [options, setOptions] = React.useState([{code:"",name:"",code3:""}]);
    const [openunitform,setOpenunitform] =  React.useState(false);
    const [selectedDates, setSelectedDates] = React.useState<Date | null>(
      new Date(),
  );
  const topic = `bp/savebp`;

  const [visit_times,setVisit_times] = React.useState('');
  const [bpss,setBpss]= React.useState('');
  const [bpds,setBpds]= React.useState('');
  const [pulses,setPulses]= React.useState('');
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const [deleteid, setDeleteid] = useState(null);
  const [opencf, setOpencf] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [opensnack, setOpensnack] = React.useState(false);
  const [druggallergylist,setDruggallergylist]= React.useState([{name_druggallergy:""}]);
  const [druggallergy,setDruggallergy]= React.useState([{name_druggallergy:""}]);
  const [druggallergy_detail,setDruggallergy_detail] = React.useState("");
  const [gadetails,setGadetails]= React.useState<any>([]);
  const dispatch = useSafeFunction($dispatch);
  const handleOpenmodal = () => {
   
    setDrawingData(localStorage.getItem("savedDrawing"))
    setOpenmodal(true);
    setImage_sex(localStorage.getItem("sex"));
  };
const handleDateChanges=(date: Date | null)=>{
  setSelectedDates(date);
}
  const handleClosemodal = () => {
    setOpenmodal(false);
  };
    const handleClosedialog = () => {
        setOpendialog(false);
      };
      React.useEffect(() => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
          http.get("pecode/getpelist", header).then(json => {
            setPega_namelist(json.data.pelist);
            setPe_ga_detaillist(json.data.pegadetail);
            setHeent_detail_list(json.data.heent);
            setAbbdomenldetailist(json.data.abbdomen);
            setDruggallergylist(json.data.druggallergy);
          });

          // {initData.map((row: any, index: any) => {
          // })}

if(loaddatanew){

        if (initData.length >  0 ) {
         
            setVisitdate(initData[0].visit_date);
            setVn(initData[0].vn);
            setWidth(initData[0].width);
            setHeight(initData[0].height);
            setTemp(initData[0].temp);
            setBmp(initData[0].bmp);
            setPulse(initData[0].pulse);
            setCc(initData[0].cc)
            setBpd(initData[0].bpd)
            setBps(initData[0].bps)
            setPe(initData[0].pe)
            setDiag(initData[0].diag)
            setFh(initData[0].fh)
            setPh(initData[0].ph)
            setPi(initData[0].pi)
            setNotecc(initData[0].notecc)
            setBMI(initData[0].bmi)     
            setPega_name([{pega_name:checknullalll(initData[0].pe_ga)}]);
            setGadetails(checknullalll2(initData[0].pe_ga_detail));
            setPe_ga_comment(initData[0].pe_ga_comment);
            setHeent([{pega_name:checknullalll(initData[0].pe_heent)}]);
            setHeent_detail(checknullalll2(initData[0].pe_heent_detail));
            setHeent_comment(initData[0].pe_heent_comment);
            setLungs([{pega_name:checknullalll(initData[0].lungs)}]);
            setLungs_comment(initData[0].lungs_detail);
            setAbbdomen([{pega_name:checknullalll(initData[0].abbdomen)}]);
            setAbbdomen_detail(checknullalll2(initData[0].abbdomen_detail));
            setAbbdomen_comment(initData[0].abbdomen_comment)
            setExt([{pega_name:checknullalll(initData[0].ext)}]);
            setDruggallergy([{name_druggallergy:checknullalll(initData[0].druggallergy)}]);
            setDruggallergy_detail(initData[0].druggallergy_detail)
            setExt_comment(initData[0].ext_comment);
            if(initData[0].pe_image !== null){
              setDrawingData(initData[0].pe_image)
            }
           setNotepe(initData[0].note_pe);
            if(initData[0].diagcode !== null){
              onChangeHandle(initData[0].diagcode)
              setDiagcode([{ code:initData[0].diagcode,name:initData[0].name,code3:""}])
            }
            localStorage.setItem(
              "savedDrawing",initData[0].pe_image
            )
           console.log("pe:xxxx");
        }else{
          setDrawingData('{"lines": [{"points": [{"x": 341.2833038424281, "y": 498.7916849895688}, {"x": 341.2833038424281, "y": 498.7916849895688}, {"x": 341.2833038424281, "y": 498.7916849895688}], "brushColor": "#FE0000", "brushRadius": 3}], "width": 500, "height": 500}');
          localStorage.setItem(
            'savedDrawing', '{"lines": [{"points": [{"x": 341.2833038424281, "y": 498.7916849895688}, {"x": 341.2833038424281, "y": 498.7916849895688}, {"x": 341.2833038424281, "y": 498.7916849895688}], "brushColor": "#FE0000", "brushRadius": 3}], "width": 500, "height": 500}'
          )
        }
      }
      }, [initData,loaddatanew]);

    function calBMI(h:any , w:any) {
            const data = Number(w / ((h / 100) * (h / 100))).toFixed(2);
            setBMI(data);
    }
function checknullalll(data:any){
  if(data !== null){
    return data;
  }else{
    return "";
  }
}
function checknullalll2(data:any){
  if(data !== null){
    return JSON.parse(data);
  }else{
    
  }
}
    function changeWeight(value:any) {
        setWidth(value);
        calBMI(height,value);
    }

    function changeHeight(value:any) {
        setHeight(value);
        calBMI(value,width);
    }
    const { register: registersave, getValues, handleSubmit: handleSubmitsave, setValue: setValuesave, control, setError } = useForm();
    const handleClickOpendialog = () => {
         setOpendialog(true);
       };
       const handleClickOpendialogbp = ()=>{
        setOpenunitform(true);
        reloaddata();
      }
      const handleCloseOpendia = ()=>{
        setOpenunitform(false);
      }
    const onChangeHandlelistitem = async (value:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
         await http.get("pecode/byq?q="+value, header).then(json => {
          setPecodelist(json.data.items);
          })
    };
    const onChangeHandle = async (value:any) => {
      // console.log(value);
      const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
      //const url = "http://localhost:4000/v1/patient/"
       await http.get("diag?query="+value+"&limit=10", header).then(json => {
         setOptions(json.data.items);
        })
     };
    const handleGetpelast = async()=>{
      const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
      await http.get("opdscreen/getpelastvisit?vn="+initData[0].vn+"&ptid="+initData[0].ptid, header).then(json => {
        setWidth(json.data.items[0].width);
        setHeight(json.data.items[0].height);
        setTemp(json.data.items[0].temp);
        setBMI(json.data.items[0].bmi);
        setBps(json.data.items[0].bps);
        setBpd(json.data.items[0].bpd);
        setPulse(json.data.items[0].pulse);
        setCc(json.data.items[0].cc);
        setPi(json.data.items[0].pi);
        setPh(json.data.items[0].ph);
        setFh(json.data.items[0].fh);
        setNotecc(json.data.items[0].notecc);
        setPe(json.data.items[0].pe);
        setDiag(json.data.items[0].diag);
        setNotepe(json.data.items[0].note_pe);
       })
    }
    const handleSavepe=(e:any)=>{

     
const data = {
  vn:vn,
  dataimage:localStorage.getItem("savedDrawing"),
  pe_image_png:localStorage.getItem("savedDrawingimg"),
  pega:pega_name,
  pe_ga_detail:gadetails,
  pe_ga_comment:pe_ga_comment,
  heent:heent,
  heent_detail:heent_detail,
  need:need,
  hart:hart,
  heent_comment:heent_comment,
  lungs:lungs,
  lungs_detail:lungs_detail,
  lungs_comment:lungs_comment,
  abbdomen:abbdomen,
  abbdomen_detail:abbdomen_detail,
  abbdomen_comment:abbdomen_comment,
  ext:ext,
  ext_comment:ext_comment,
  neck_comment:neck_comment,
  hart_comment:hart_comment,
  diagcode:diagcode,
  diag:diag,
  note_pe:notepe
}
const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    // console.log(localStorage.getItem("savedDrawing"));
     http.post('opdscreen/saveopd',data, header).then(json => {
      Swal.fire(
          'บันทึกข้อมูลสำเร็จ',
          '',
          'success'
        );
        setOpenmodal(false);
  });
    }
    const handleSave = (e:any) => {
        setOpendialog(false);
        const tempData = initData || [{}];
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        const data = {
            vn:vn,
            width:width,
            height:height,
            temp:temp,
            bmp:bmp,
            pulse:pulse,
            cc:cc,
            bpd:bpd,
            bps:bps,
            pe:pe,
            diag:diag,
            druggallergy:druggallergy,
            druggallergy_detail:druggallergy_detail,
            bmi:bmi,
            pi:pi,
            ph:ph,
            fh:fh,
            notecc:notecc,
        }
        tempData[0].vn = vn;
        tempData[0].width = width;
        tempData[0].height= height;
        tempData[0].temp= temp;
        tempData[0].bmp= bmp;
        tempData[0].pulse = pulse;
        tempData[0].cc = cc;
        tempData[0].bpd = bpd;
        tempData[0].druggallergy = druggallergy;
        tempData[0].druggallergy_detail = druggallergy_detail;
        tempData[0].bps = bps;
        tempData[0].pe = pe;
        tempData[0].diag = diag;
        tempData[0].bmi = bmi;
        tempData[0].pi = pi;
        tempData[0].ph = ph;
        tempData[0].fh = fh;
        tempData[0].notecc = notecc;
        saveCallback(tempData);
        http.post('opdscreen/',data, header).then(json => {
            Swal.fire(
                'บันทึกข้อมูลสำเร็จ',
                '',
                'success'
              );
        });
       
    };

    const handleSavebphistory=()=>{
      const tempData = initData || [{}];
      const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
      const data = {
          vn:vn,
          width:width,
          height:height,
          temp:temp,
          bmp:bmp,
          pulse:pulses,
          cc:cc,
          bpd:bpds,
          bps:bpss,
          druggallergy:druggallergy,
          druggallergy_detail:druggallergy_detail,
          pe:pe,
          diag:diag,
          bmi:bmi,
          pi:pi,
          ph:ph,
          fh:fh,
          notecc:notecc,
      }
      tempData[0].vn = vn;
      tempData[0].width = width;
      tempData[0].height= height;
      tempData[0].temp= temp;
      tempData[0].bmp= bmp;
      tempData[0].pulse = pulses;
      tempData[0].cc = cc;
      tempData[0].bpd = bpds;
      tempData[0].bps = bpss;
      tempData[0].druggallergy = druggallergy;
      tempData[0].druggallergy_detail = druggallergy_detail;
      tempData[0].pe = pe;
      tempData[0].diag = diag;
      tempData[0].bmi = bmi;
      tempData[0].pi = pi;
      tempData[0].ph = ph;
      tempData[0].fh = fh;
      tempData[0].notecc = notecc;
      saveCallback(tempData);
      setBps(bpss);
      setBpd(bpds);
      setPulse(pulses)
      http.post('opdscreen/',data, header).then(json => {
        setOpenunitform(false);
       
      }).catch(function (error) {
        // history.replace({
        //   pathname: `/login`,
        // })
      });
    }
    const saveBphistory = ()=>{
      const data = {
          vn:vn,
          visit_date:selectedDates,
          visit_time:visit_times,
          bps:bpss,
          bpd:bpds,
          pulse:pulses
      }
      http.post('bp/',data, header).then(json => {
          reloaddata();
        }).catch(function (error) {
          // history.replace({
          //   pathname: `/login`,
          // })
        })
    }
    const reloaddata =()=>{
      dispatch({ type: "fetch_start" });
      http.get('bp/getpelastvisit?vn='+vn, header).then(json => {
        const { items } = json.data
        dispatch({ type: "fetch_success", payload: { items } });
      }).catch(function (error) {
        // history.replace({
        //   pathname: `/login`,
        // })
      })
    }

    const handleRemoveItem = (id: any) => {
      setOpencf(true);
      setDeleteid(id);
    }
    const handleClosecf = () => {
      setOpencf(false);
    };
    const handleDisagreecf = () => {
      setOpencf(false);
    };
    const handleAgreecf = () => {
      setOpencf(false);
      http.delete("bp/" + deleteid,header)
        .then(function (response: any) {
          reloaddata();
          setMessage("ลบรายการสำเร็จ")
          setOpensnack(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const handleClosesnack = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      setOpensnack(false);
    };
   return ( 
    <React.Fragment>
         <CssBaseline/>
    <Card className={classes.root}>
    <CardHeader
    
      action={
        <Button variant="contained" onClick={handleClickOpendialog} size="small" color="primary">
        Edit <BorderColorIcon/>
      </Button>
      }
      titleTypographyProps={{variant:'subtitle1' }}
      title={props.cerrentdatetime}
    />
  
    <CardContent>
    <Grid container spacing={2}>
      <Grid item xs={2}>
      <Typography variant="body2" align="left">
        น้ำหนัก
        </Typography>
       
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {width}
        
        </Typography>
       
        </Grid>
        <Grid item xs={2}>
      <Typography variant="body2" align="left">
        ส่วนสูง
        </Typography>
       
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {height != null ? height :""}
        </Typography>
        </Grid>
        <Grid item xs={2}>
      <Typography variant="body2" align="left">
      BMI
        </Typography>
       
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {bmi != null ? bmi :""}
        </Typography>
        </Grid>
        <Grid item xs={2}>
      <Typography variant="body2" align="left">
        อุณหภูมิ
        </Typography>
       
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {temp != null ?temp :""}
        </Typography>
        </Grid>
        <Grid item xs={2}>
      <Typography variant="body2" align="left">BP</Typography>
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {bps != null ? bps :""}/{bpd != null ? bpd :""}
        </Typography>
        </Grid>
        <Grid item xs={2}>
      <Typography variant="body2" align="left">ชีพจร</Typography>
        </Grid>
        <Grid item xs={2}>
        <Typography   variant="body2" align="right">
        {pulse != null ?pulse :""}
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="h6" align="left">
        Chief Complaint
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="body1" align="left">
        {cc != null ? cc :""}
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="h6" align="left">
        PE  <Button variant="contained" onClick={handleOpenmodal} size="small" color="primary">PE </Button>
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="body1" align="left">
        {pe != null ? pe :""}
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="h6" align="left">
        Diag
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Typography   variant="body1" align="left">
        {diag != null ? diag :""}
        </Typography>
        </Grid>
    </Grid>
    </CardContent>
  </Card>
     <form onSubmit={e => {
                        handleSave(e)
                    }}>
     <Dialog fullWidth={true} maxWidth="md" open={opendialog} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">Screen Infomation</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="visit_date"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setVisitdate(e.target.value)
                                    }}
                                       
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={visitdate}
                                       id="visit_date"
                                       label="Visit Date"
                                       
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="vn"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setVn(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={vn}
                                       id="vn"
                                       label="Visit ID"
                                       
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
       <Button variant="contained" onClick={handleGetpelast} color="primary">
       ดึงข้อมูลล่าสุดมา
     </Button>
       </Grid>
     
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="width"
                                       variant="outlined"
                                       onChange={(e) => {
                                       
                                        console.log(e)
                                        setWidth(e.target.value)
                                        changeWeight(e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                      const enterKey = 13;
                                      if(e.which == enterKey){
                                        setFocus1(false);
                                        setFocus2(true);
                                        console.log(focus1);
                                      }
                                    }}
                                    type="number"
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={width}
                                       id="width"
                                       label="น้ำหนัก"
                                       focused
                                      
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="height"
                                       variant="outlined"
                                       type="number"
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setHeight(e.target.value)
                                        changeHeight(e.target.value);
                                    }}
                                       id="height"
                                       value={height}
                                       label="ส่วนสูง"
                                       inputProps={{ autoFocus: focus2 }}
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
           
       <TextField
          onChange={(e) => {
            console.log(e.target.value)
            setBMI(e.target.value)
        }}
        type="number"
                                       name="bmi"
                                       variant="outlined"
                                       value={bmi}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="bmi"
                                       label="BMI"
                                       
                                       
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="temp"
                                       variant="outlined"
                                       value={temp}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       type="number"
                                       onChange={(e) => {
                                        setTemp(e.target.value)
                                    }}
                                       id="temp"
                                       label="อุณหภูมิ"
                                       
                                       
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
                               <TextField
                               value={bps}
                                       name="bps"
                                       variant="outlined"
                                       required
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       type="number"
                                       id="bps"
                                       label="ความดันตัวบน(Systolic)"
                                       
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setBps(e.target.value)
                                    }}
                                     
                                   />
       </Grid>
       
       <Grid item xs={3} sm={3}>
       <TextField
         onChange={(e) => {
            console.log(e.target.value)
            setBpd(e.target.value)
        }}
                                       name="bpd"
                                       variant="outlined"
                                       value={bpd}
                                       fullWidth
                                       type="number"
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="bpd"
                                       label="ความดันตัวล่าง(Diastolic)"
                                       
                                   />
       </Grid>
       <Grid item xs={2} sm={2}>
       <Button variant="contained" onClick={handleClickOpendialogbp} color="primary">BP</Button>
      </Grid>
       <Grid item xs={4} sm={4}>
       <TextField
          onChange={(e) => {
            console.log(e.target.value)
            setPulse(e.target.value)
        }}
        type="number"
                                       name="pulse"
                                       variant="outlined"
                                       value={pulse}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="pulse"
                                       label="ชีพจร"
                                       
                                       
                                     
                                   />
       
       </Grid>
       <Grid item xs={3} sm={3}>
       <Autocomplete
       value={druggallergy[0]}
      id="druggallergy"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setDruggallergy([{"name_druggallergy":newValue.name_druggallergy}]);
        }
      }}
      getOptionSelected={(option, value) => option.name_druggallergy == value.name_druggallergy}
         getOptionLabel={(option) => option.name_druggallergy +""}
         options={druggallergylist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="การแพ้ยา"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={5} sm={5}>
       <TextField
          onChange={(e) => {
            console.log(e.target.value)
            setDruggallergy_detail(e.target.value)
        }}
                                       name="druggallergy_detail"
                                       variant="outlined"
                                       value={druggallergy_detail}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="druggallergy_detail"
                                       label="รายละเอียดการแพ้ยา"
                                       
                                       
                                     
                                   />
         </Grid>
       <Grid item xs={6} sm={6}>
       <Autocomplete
      id="searchdrug"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setCc(checknullalll(cc)+" "+newValue.name);
        }
      }}
      
         getOptionLabel={(option) => option.name +" "}
         options={pecodelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="เลือกข้อความ cc"
          variant="outlined"
          onChange={ev => {
           // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandlelistitem(ev.target.value);
            }
          }}
        />
      )}
    />
   
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="cc"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCc(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cc"
                                       label="Chief Complaint"
                                       multiline
                                       rows={3}
                                       
                                     value={cc}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="pi"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setPi(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="Present illness(PI)"
                                       label="Present illness(PI)"
                                       multiline
                                       rows={3}
                                       
                                     value={pi}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="ph"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setPh(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="Past history(PH)"
                                       label="Past history(PH)"
                                       multiline
                                       rows={3}
                                       
                                     value={ph}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="fh"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setFh(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="Family history(FH)"
                                       label="Family history(FH)"
                                       multiline
                                       rows={3}
                                       
                                     value={fh}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="notecc"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setNotecc(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="Note"
                                       label="Note"
                                       multiline
                                       rows={3}
                                       
                                     value={notecc}
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
       <Box display="flex" justifyContent="flex-end" >
       
       {/* <Button  variant="contained" onClick={(e) => {
         setPe('normal');
       }} >Normal</Button> */}
</Box>
       {/* <TextField
          onChange={(e) => {
            console.log(e.target.value)
            setPe(e.target.value)
        }}
                                       name="pe"
                                       variant="outlined"
                                       
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="pe"
                                       label="PE"
                                       multiline
                                       rows={3}
                                       
                                       value={pe}
                                   /> */}
     
  
   
       </Grid>
     
        
         
      
     </Grid>
    
   </DialogContent>
   <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>


 </form>

 <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openmodal}
        onClose={handleClosemodal}
       
      >
          <div className={classes.paper2} >
          <Grid container spacing={3}>
    <br></br>
        <Grid item xs={12} sm={12}>
        <br/>
                   
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>   <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/><br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/><br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/><br/>
                    <br/>
                    <br/>
        <Typography component="h1" variant="h4" align="center">
                        Physical Examination
                    </Typography>
        </Grid>
        <Grid item xs={4} sm={4}>
       <Autocomplete
       
       value={pega_name[0]}
      id="pega_name"
      onChange={(event: any, newValue: any) => {
        setPega_name([{pega_name:newValue.pega_name}]);
         console.log(newValue)
          }}
          getOptionSelected={(option, value) => option.pega_name === value.pega_name}
         getOptionLabel={(option) => option.pega_name + ""}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="GA"
          variant="outlined"
        />
      )}
    />
          </Grid>
          <Grid item xs={4} sm={4}>
          <Autocomplete
        multiple
        value={gadetails}
        id="pe_ga_comment"
        options={pe_ga_detaillist}
        onChange={(event: any, newValue: any) => {
          if(newValue != null){
          setGadetails(newValue)
          }
        }}
        getOptionLabel={(option) => option.pega_detail_name}
        getOptionSelected={(option, value) => option.pega_detail_name === value.pega_detail_name}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="GA"  />
        )}
      />
      
         </Grid>
         <Grid item xs={4} sm={4}>
         <TextField
                                       name="ga_comment"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setPe_ga_comment(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="ga_comment"
                                       label="GA Comment"
                                       
                                     value={pe_ga_comment}
                                   />
         </Grid>
       <Grid item xs={4} sm={4}>
       <Autocomplete
       value={heent[0]}
      id="searchdrug"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setHeent([{pega_name:newValue.pega_name}]);
        }
      }}
         getOptionSelected={(option, value) => option.pega_name === value.pega_name}
         getOptionLabel={(option) => option.pega_name +" "}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="HEENT"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={4} sm={4}>
       <Autocomplete
       multiple
       value={heent_detail}
      id="heent_detail"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setHeent_detail(newValue);
        }
      }}
        getOptionSelected={(option, value) => option.name_heent === value.name_heent}
         getOptionLabel={(option) => option.name_heent +" "}
         options={heent_detail_list}
      renderInput={(params) => (
        <TextField
          {...params}
          label="HEENT"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={4} sm={4}>
         <TextField
                                       name="heent_comment"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setHeent_comment(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="heent_comment"
                                       label="Heent Comment"
                                       
                                     value={heent_comment}
                                   />
         </Grid>
       
         <Grid item xs={4} sm={4}>
       <Autocomplete
      id="lungs"
      value={lungs[0]}
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setLungs([{pega_name:newValue.pega_name}]);
        }
      }}
      getOptionSelected={(option, value) => option.pega_name === value.pega_name}
         getOptionLabel={(option) => option.pega_name +" "}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Lungs"
          variant="outlined"
        />
      )}
    />
         </Grid>
        
         <Grid item xs={8} sm={8}>
         <TextField
                                       name="lungs_comment"
                                       variant="outlined"
                                       onChange={(e:any) => {
                                        console.log(e.target.value)
                                        setLungs_comment(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cc"
                                       label="Lungs Comment"
                                       
                                     value={lungs_comment}
                                   />
         </Grid>
         <Grid item xs={4} sm={4}>
       <Autocomplete
       value={abbdomen[0]}
      id="abbdomen"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setAbbdomen([{pega_name:newValue.pega_name}]);
        }
      }}
         getOptionSelected={(option, value) => option.pega_name === value.pega_name}
         getOptionLabel={(option) => option.pega_name +" "}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Abbdomen"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={4} sm={4}>
       <Autocomplete
       multiple
       value={abbdomen_detail}
      id="abbdomen_detail"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setAbbdomen_detail(newValue);
        }
      }}
         getOptionLabel={(option) => option.name_abbdomen +" "}
         options={abbdomenldetailist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Abbdomen"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={4} sm={4}>
         <TextField
                                       name="abbdomen_comment"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setAbbdomen_comment(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cc"
                                       label="Abbdomen Comment"
                                       
                                     value={abbdomen_comment}
                                   />
         </Grid>
         <Grid item xs={4} sm={4}>
       <Autocomplete
      id="ext"
      value={ext[0]}
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setExt([{pega_name:newValue.pega_name}]);
        }
      }}
      getOptionSelected={(option, value) => option.pega_name === value.pega_name}
         getOptionLabel={(option) => option.pega_name +" "}
         options={pega_namelist}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ext"
          variant="outlined"
        />
      )}
    />
         </Grid>
         <Grid item xs={4} sm={4}>
         <TextField
                                       name="ext_comment"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setExt_comment(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="ext_comment"
                                       label="ext Comment"
                                       
                                     value={ext_comment}
                                   />
         </Grid>
        <Grid item xs={12} sm={12}>
      <DrawCanvas ref={drawingRef} drawingData={drawingData} imgSrc={image_sex}/>
        </Grid>
        <Grid item xs={12} sm={12}>
      
      <Typography  gutterBottom>
      Diag
    </Typography>
   
   <Box display="flex" justifyContent="flex-end" >
   
   <Button  variant="contained" onClick={(e) => {
     setDiag('Healthy');
   }} >Healthy</Button>
   
</Box>

   <TextField
                                   name="diag"
                                   variant="outlined"
                                   onChange={(e) => {
                                    console.log(e.target.value)
                                    setDiag(e.target.value)
                                }}
                                   fullWidth
                                   InputLabelProps={{
                                           shrink: true
                                   }}
                                   id="diag"
                                   label="Diag"
                                   multiline
                                   rows={3}
                                   
                                   value={diag}
                               />
   </Grid>
   <Grid item xs={12} sm={12}>
        <Autocomplete
        value={diagcode[0]}
      id="iddiagcode"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          setDiagcode([{code:newValue.code,name:newValue.name}]);
          setDiag(newValue.code+":"+newValue.name);
        }
      }}
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionLabel={(option) => option.code +" :"+ option.name}
         options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Diagcode"
          variant="outlined"
          onChange={ev => {
           // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
        />
      )}
    />
          </Grid>
          <Grid item xs={12} sm={12}>
          <TextField
                                   name="notepe"
                                   variant="outlined"
                                   onChange={(e) => {
                                    console.log(e.target.value)
                                    setNotepe(e.target.value)
                                }}
                                   fullWidth
                                   InputLabelProps={{
                                           shrink: true
                                   }}
                                   id="notepe"
                                   label="Note"
                                   multiline
                                   rows={3}
                                   
                                   value={notepe}
                               />
          </Grid>
        <Grid item xs={12} sm={12}>
        <div className={classes.pullRight}>
        <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSavepe}
                                    className={classes.button}>
                                    บันทึก
                                </Button>
        <Button onClick={handleClosemodal}   className={classes.button} variant="contained" color="secondary">
                            ปิด
                        </Button>
                        </div>
        </Grid>
        
        </Grid>
          </div>
     
      </Modal>
    

<Dialog fullWidth={true} maxWidth="md" open={openunitform} onClose={handleCloseOpendia} aria-labelledby="form-dialog-title">
   
   <DialogContent>
   
              
               <Card >
               <CardHeader
      title="ข้อมูลการวัดความดัน"
    />
      <CardContent>
        <div  style={{textAlign: 'left'}}>
        <form>
        <Grid container spacing={2}>
        <Grid item xs={2}>
        <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                                                <DatePicker
                                                    id="visit_dates"
                                                    label="วันที่นัดมา"
                                                    name="visit_dates"
                                                    format="DD/MM/YYYY"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={selectedDates}
                                                    yearOffset={543}
                                                    onChange={(e:any) => {
                                                      if(e !== null){
                                                        console.log(e);
                                                       handleDateChanges(e)
                                                      }
                                                    }
                                                    }
                                                    inputVariant="outlined"
                                                
                                                />
        </MuiPickersUtilsProvider>
      
        </Grid>
        <Grid item xs={2}>
        <TextField
                                            name="visit_times"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="visit_times"
                                            label="เวลา"
                                            type="time"
                                            onChange={(e) => {
                                                setVisit_times(e.target.value)
                                               
                                            }}
                                            onKeyDown={(e) => {
                                                if(e.code === 'Enter'){
                                                  
                                                //  alert('Enter... (KeyDown, use keyCode)');
                                                }
                                              }}
                                            value={visit_times}
                                        />
        </Grid>
        <Grid item xs={2}>
        <TextField
        type="number"
         variant="outlined"
         fullWidth
         InputLabelProps={{
                 shrink: true
         }}
         id="bpss"
         label="Bps"  
         onChange={(e) => {
          setBpss(e.target.value)
         
      }} 
      value={bpss}         
                         />
        </Grid>
        <Grid item xs={2}>
        <TextField
            type="number"
            onChange={(e) => {
              setBpds(e.target.value)
             
          }}    
        
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="bpd"
                                            label="bpd"
                                            value={bpds}  
                                            
                                        />
        </Grid>
        <Grid item xs={2}>
        <TextField
            type="number"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pulse"
                                            label="ชีพจร"
                                            onChange={(e) => {
                                              setPulses(e.target.value)
                                             
                                          }}   
                                          value={pulses}  
                                            
                                        />
        </Grid>
        <Grid item xs={2}>
        <Button variant="contained" onClick={saveBphistory}   style={{textAlign: 'left'}} color="primary">เพิ่ม </Button>
        </Grid>  
        </Grid>
      
        </form>
        <TableContainer component={Paper}>
      <Table stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">วันที่</TableCell>
            <TableCell align="center">เวลา</TableCell>
            <TableCell align="center">BPS</TableCell>
            <TableCell align="center">BPD</TableCell>
            <TableCell align="center">ชีพจร</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {state.items.map((row: any, index: any) => {
             return (
            <TableRow key={row.id}>
              <TableCell align="center">{index+1}</TableCell>
             <TableCell align="center">{thDate(moment(row.visit_date).add(543, 'year').format('YYYY-MM-DD'))}</TableCell>
              <TableCell align="center">{row.visit_time}</TableCell>
              <TableCell align="center">{row.bps}</TableCell>
              <TableCell align="center">{row.bpd}</TableCell>
              <TableCell align="center">{row.pulse}</TableCell>
              <TableCell align="center"><Button variant="outlined" onClick={() => handleRemoveItem(row.id)} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="secondary" >
                      <DeleteIcon/>
                    </Button>
                    </TableCell>
            </TableRow>
          )
             })}
        </TableBody>
      </Table>
    </TableContainer>
     </div>
     </CardContent>
     </Card>
    </DialogContent>
    <DialogActions>
    <Button variant="contained" onClick={handleSavebphistory} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleCloseOpendia} color="secondary">
       ปิด
     </Button>
   </DialogActions>
    </Dialog>
    <Dialog
        open={opencf}
        onClose={handleClosecf}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText >
            คุณต้องการลบรายการนี้หรือไม่
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagreecf} color="secondary" >
            ยกเลิก
            </Button>
          <Button onClick={handleAgreecf} startIcon={<DeleteIcon />} color="primary" autoFocus>
            ยืนยันการลบ
            </Button>
        </DialogActions>
      </Dialog>
      <Snackbar

open={opensnack}
onClose={handleClosesnack}
message={message}
autoHideDuration={3000}
/>
 </React.Fragment>
   );
}