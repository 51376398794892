import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import http from "../../http-common";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '780px',
},
layout: {
  width: 'auto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},

  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  MuiFormControlmarginNormal:{
    marginBottom:'8px'
},
  title: {
    flexGrow: 1,
    backgroundColor:"#cccccc",
    paddingTop:'15px',
    paddingRight:'40px',
    paddingBottom:'15px',
    paddingLeft:'15px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const initialState = {
  items: [],
  amphur:[],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_amphur": {
        const {amphur} = action.payload;
        return {
            ...state,
            amphur
        }
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function PatientForm(props:any) {
    
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  let  id  = props.match.params.id;
  console.log(id)
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [provicelist, setProvicelist] = React.useState<any>([]);
  const [opendialog, setOpendialog] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
const [tumbollist, setTumbollist] = React.useState<any>([]);
const [amphurlist, setAmphur] = React.useState<any>([]);
const [titleautocom,setTitleautocom]= React.useState<any>([]);
const [nationlist,setNationlist] = React.useState<any>([]);
const [religionlist,setReligionlist] = React.useState<any>([]);
const [occlist,setOcclist] = React.useState<any>([]);
const [survey, setSurvey] = React.useState(undefined);
const [ptsex,setPtsex] = React.useState('');
const handleInputChange = (e:any) => {
  setSurvey(e.target.value);
};
const calageyear = (e:any)=>{
  const startDate = moment(selectedDate);
  const timeEnd = moment(new Date());
  const diff = timeEnd.diff(startDate);
  const diffDuration = moment.duration(diff);
  // console.log(diffDuration.minutes)
  setValuesave('pt_age', diffDuration.years());
}
// const [ptcid, setPtcid] = React.useState('');

  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const url =  process.env.REACT_APP_SECRET_NAME+'/v1/patient?limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=';
  const urlassettbs = process.env.REACT_APP_SECRET_NAME+'/v1/patient/'
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    http.get("patient/province", header).then(json => {
      setProvicelist(json.data.province);
      setTitleautocom(json.data.prefix);
      setNationlist(json.data.nation);
      setReligionlist(json.data.religion);
      setOcclist(json.data.occ);
    })
    if (typeof id !== 'undefined') {
       
        http.get("patient/byid?id=" +id, header).then(json2 => {
                    http.get('patient/amphur?province_id=' + json2.data.items[0].pt_addr_province, header).then(jsondistrictrs => {
                        setAmphur(jsondistrictrs.data.amphur)
                        //setTumbollist([]);
                       setValuesave('pt_addr_amphur',json2.data.items[0].pt_addr_amphur)
                    })
                    http.get('patient/distric?province_id=' + json2.data.items[0].pt_addr_province + '&aumphur_id=' + json2.data.items[0].pt_addr_amphur, header).then(jsonsubdistrict => {
                        setTumbollist(jsonsubdistrict.data.distric);
                       setValuesave('pt_addr_tumbon',json2.data.items[0].pt_addr_tumbon)
                    })
                    setValuesave('pt_addr_province',json2.data.items[0].pt_addr_province)
                    setValuesave('pt_addr_zipcode', json2.data.items[0].pt_addr_zipcode);
                    setValuesave('disease', json2.data.items[0].disease);
                    setValuesave('druggallergy', json2.data.items[0].druggallergy);
                    setValuesave('pt_cid', json2.data.items[0].pt_cid);
                    setValuesave('pt_sex', json2.data.items[0].pt_sex);
                    setValuesave('pt_age', json2.data.items[0].pt_age);
                    setValuesave('pt_prefix_id', json2.data.items[0].pt_prefix_id);
                    setValuesave('pt_firstname', json2.data.items[0].pt_firstname);
                    setValuesave('pt_lastname', json2.data.items[0].pt_lastname);
                    setValuesave('pt_age', json2.data.items[0].pt_age);
                    setValuesave('pt_national_id', json2.data.items[0].pt_national_id);
                    setValuesave('pt_origin_id', json2.data.items[0].pt_origin_id);
                    setValuesave('pt_religion_id', json2.data.items[0].pt_religion_id);
                    setValuesave('pt_mstatus', json2.data.items[0].pt_mstatus);
                    setValuesave('pt_occ', json2.data.items[0].pt_occ);
                    setValuesave('pt_address', json2.data.items[0].pt_address);
                    setValuesave('pt_moi', json2.data.items[0].pt_moi);
                    setValuesave('pt_addr_province', json2.data.items[0].pt_addr_province);
                    setValuesave('pt_phone', json2.data.items[0].pt_phone);
                    setSelectedDate(new Date(json2.data.items[0].pt_bdate));
                })
    }

  }, [])
const handleClosedialog = () => {
  setOpendialog(false);
};


const handleDateChange = (date: Date | null) => {
 // setSelectedDate(date);
  // var yearfull = new Date().getFullYear() - (new Date(date).getFullYear());
  //       setValuesave('AGE', yearfull)
};

const [values, setValues] = React.useState({
  email: '',
  password: '',
});
const handleChange = (name:any) => (event:any) => {
  setValues({ ...values, [name]: event.target.value });
};


const mstatus = [
  { title: 'โสด', year: 1994 },
  { title: 'สมรส', year: 1972 },
  { title: 'อย่าร้าง', year: 1974 }
]

const initialStates = {
  B_PV: ""

};
const [
  { B_PV},
  setState
] = useState(initialStates);
const onChange2 = (e: any) => {
  const { name, value } = e.target;
  setState(prevState => ({ ...prevState, [name]: value }));
}
const handleChangeprovince = (event: React.ChangeEvent<{ value: unknown }>) => {
  http.get('patient/amphur?province_id=' + event.target.value, header).then(json => {
      setAmphur(json.data.amphur)
      setTumbollist([]);
  })
}
const handleChangeampher = (event: React.ChangeEvent<{ value: unknown }>) => {
  const pt_addr_province = getValues('pt_addr_province')

  http.get('patient/distric?province_id=' + pt_addr_province + '&aumphur_id=' + event.target.value, header).then(json => {
      setTumbollist(json.data.distric);
  })
}
const doSubmit = (data: any) => {
 
  http.post("patient/", data, header)
    .then(function (response: any) {
        if(response.data.ok){
            Swal.fire(
              response.data.message,
              '',
              'success'
            );
            history.replace({
                pathname: `/patient`,
              })
        }else{
            alert(response.data.message)
        }   
    })
};
const handleClickOpendialog = ()=>{
    history.replace({
      pathname: `/patient`,
    })
  }
const form = (
  <React.Fragment>
     <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          
          <Typography className={classes.title}  gutterBottom>
          ข้อมูลผู้รับบริการ
        </Typography>
          </Grid>
         
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="pt_hn"
                                            variant="outlined"
                                          
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            // InputProps={{
                                            //   readOnly: true,
                                            // }}
                                            label="HN"
                                            inputRef={register}
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="pt_cid"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            error={errorspt.pt_cid ? true :false}
                                            helperText={errorspt.pt_cid ? ('กรุณากรอกเลขบัตรประจำตัวประชาชน'):('')}
                                            id="pt_cid"
                                            label="เลขบัตรประจำตัวประชาชน"
                                            inputRef={register({
                                                required: true
                                            })
                                            }
                                          
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            </Grid>
            <Grid item xs={3} sm={3}>
              
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">คำนำหน้า</InputLabel>
                                                <Controller
                                                    name="pt_prefix_id"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_prefix_id"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="คำนำหน้า"
                                                        >
                                                            {titleautocom.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.prefix_name}>{row.prefix_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>    
            </Grid>
            <Grid item xs={3} sm={3}>
                                    <TextField
                                            name="pt_firstname"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pt_firstname"
                                            label="ชื่อ"
                                            error={errorspt?.pt_firstname?.type === "required" && (true)}
                                            helperText={errorspt?.pt_firstname?.type === "required" && ('กรุณากรอกชื่อ')}
                                             inputRef={register({
                                                required: true
                                            })
                                          }
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="pt_lastname"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pt_lastname"
                                            error={errorspt?.pt_lastname?.type === "required" && (true)}
                                            helperText={errorspt?.pt_lastname?.type === "required" && ('กรุณากรอกสกุล')}
                                            label="สกุล"
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
      <DatePicker
        label="วันเกิด"
        format="DD/MM/YYYY"
        InputLabelProps={{
          shrink: true,
      }}
      inputVariant="outlined"
        yearOffset={543}
        name="pt_bdate"
        onChange={(e:any) => {
          if(e !== null){
            setSelectedDate(e)
          }
        }
      }
       value={selectedDate}
        inputRef={register({
          required: true
      })
  }
      />
     
    </MuiPickersUtilsProvider>
                                            <Button  color="primary" onClick={calageyear}>
              คำนวนอายุ
            </Button>  
            </Grid>
            <Grid item xs={2} sm={2}>
                                    <TextField
                                            name="pt_age"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                           
                                            error={errorspt?.pt_age?.type === "required" && (true)}
                                            helperText={errorspt?.pt_age?.type === "required" && ('กรุณากรอกอายุ')}
                                            id="pt_age"
                                            label="อายุ"  
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                   ปี
                                                </InputAdornment>
                                            ),
                                        }}
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">เพศ</InputLabel>
                                                <Controller
                                                    name="pt_sex"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_sex"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="เพศ"
                                                        >
                                                            
                                                                <MenuItem value="ชาย">ชาย</MenuItem>
                                                                <MenuItem value="หญิง">หญิง</MenuItem>
                                                           
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>  
          
            </Grid>
            <Grid item xs={12}>
          <Typography className={classes.title}  gutterBottom>
          ข้อมูลบุคคล
        </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
          <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">สัญชาติ</InputLabel>
                                                <Controller
                                                    name="pt_national_id"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_national_id"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="สัญชาติ"
                                                        >
                                                            {nationlist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.national_name}>{row.national_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>                 
            </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">เชื่อชาติ</InputLabel>
                                                <Controller
                                                    name="pt_origin_id"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_origin_id"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="เชื่อชาติ"
                                                        >
                                                            {nationlist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.national_name}>{row.national_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">ศาสนา</InputLabel>
                                                <Controller
                                                    name="pt_religion_id"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_religion_id"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="ศาสนา"
                                                        >
                                                            {religionlist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.religion_name}>{row.religion_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>
            </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">สถานะภาพ</InputLabel>
                                                <Controller
                                                    name="pt_mstatus"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_mstatus"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="สถานะภาพ"
                                                        >
                                                            {mstatus.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.title}>{row.title}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                            </FormControl>
    
            </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">อาชีพปัจจุบัน</InputLabel>
                                                <Controller
                                                    name="pt_occ"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_occ"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="อาชีพปัจจุบัน"
                                                        >
                                                            {occlist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.occ_name}>{row.occ_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />
                            </FormControl>           
            </Grid>
            <Grid item xs={12}>
          <Typography className={classes.title}  gutterBottom>
          ข้อมูลที่อยู่
        </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="pt_address"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pt_address"
                                            label="บ้านเลขที่"
                                            error={errorspt?.pt_address?.type === "required" && (true)}
                                            helperText={errorspt?.pt_address?.type === "required" && ('กรุณากรอกบ้านเลขที่')}
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="pt_moi"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            error={errorspt?.pt_moi?.type === "required" && (true)}
                                            helperText={errorspt?.pt_moi?.type === "required" && ('กรุณากรอกหมู่')}
                                            id="pt_moi"
                                            label="หมู่"
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
           
            </Grid>
            <Grid item xs={3} sm={3}>
        
            <FormControl variant="outlined" fullWidth error={errorspt.pt_addr_province ? true :false}
                                                    >
                                                <InputLabel id="demo-simple-select-outlined-label">จังหวัด</InputLabel>
                                                <Controller
                                                    control={control}
                                                    name="pt_addr_province"
                                                    defaultValue=""
                                                    
                                                    onChange={() => console.log("hellow")}
                                                  
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_addr_province"
                                                            label="จังหวัด"
                                                            value={value ? value : ""}
                                                            name={name}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                                handleChangeprovince(e)
                                                            }
                                                            }
                                                            inputRef={register({
                                                                required: true
                                                            })
                                                        }
                                                        >
                                                            {provicelist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.PROVINCE_ID}>{row.PROVINCE_NAME}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>
                                                    )
                                                    }
                                                />
                            <FormHelperText>{errorspt.pt_addr_province ? "กรุณาเลือกจังหวัด":""}</FormHelperText>
                                            </FormControl>
     
                                  
            </Grid>
            <Grid item xs={3} sm={3}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">อำเภอ</InputLabel>
                                                <Controller
                                                    name="pt_addr_amphur"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="pt_addr_amphur"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                                handleChangeampher(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="อำเภอ"
                                                        >
                                                            {amphurlist.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.AMPHUR_ID}>{row.AMPHUR_NAME}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>
                                   
            </Grid>
            <Grid item xs={3} sm={3}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">ตำบล</InputLabel>
                                                <Controller
                                                    name="pt_addr_tumbon"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (<Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="pt_addr_tumbon"
                                                        value={value ? value : ""}
                                                        onChange={(e) => {
                                                            onChange(e)
                                                             onChange2(e)
                                                        }}
                                                        label="ตำบล"
                                                        name={name}
                                                    >
                                                        {tumbollist.map((row: any, i: number) => (
                                                            <MenuItem key={i} value={row.DISTRICT_ID}>{row.DISTRICT_NAME}</MenuItem>
                                                        )
                                                        )}

                                                    </Select>
                                                    )
                                                    }
                                                />
                                            </FormControl>
          
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="pt_addr_zipcode"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pt_addr_zipcode"
                                            label="รหัสไปรษณีย์"
                                            
                                            inputRef={register()
                                          }
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            <TextField
                                            name="pt_phone"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="pt_phone"
                                            label="เบอร์โทรศัพท์"
                                            error={errorspt?.pt_phone?.type === "required" && (true)}
                                            helperText={errorspt?.pt_phone?.type === "required" && ('กรุณากรอกเบอร์โทรศัพท์')}
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            </Grid>
            <Grid item xs={12} sm={12}>
            <Typography className={classes.title}  gutterBottom>
          ข้อมูลอื่นๆ
        </Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
                                    <TextField
                                            name="disease"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="disease"
                                            label="โรคประจำตัว"
                                            
                                            inputRef={register()
                                          }
                                          
                                        />
            </Grid>
            <Grid item xs={6} sm={6}>
                                    <TextField
                                            name="druggallergy"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="druggallergy"
                                            label="แพ้ยา"
                                            
                                            inputRef={register()
                                          }
                                          
                                        />
            </Grid>
            <Button  type="submit" variant="contained" color="primary">
            บันทึกข้อมูล
          </Button>
          &nbsp;
            <Button onClick={handleClickOpendialog} variant="contained" color="secondary">
            Cancel
          </Button>
          
          </Grid>
          </form>
         
    </React.Fragment>
);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>   
    {form}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
     
    </div>
  );
}