import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuHeader from '../home/MenuHeader'
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import http from "../../http-common";
const drawerWidth = 240;
moment.locale("th");
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
}));

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function Report() {
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
const [countservicerecipient,setCountservicerecipient] =  React.useState('');
const [listsumprices,setListsumprices] =  React.useState('');
const [price,setPrice]=  React.useState('');
const [price_capital,setPrice_capital]=  React.useState('');
const [listdrugmax,setListdrugmax] = React.useState<any>([]);
const [listptbydays,setListptbydays]= React.useState<any>([]);
const [selectedDateend, setSelectedDateend] = React.useState<Date | null>(
  new Date(),
);
  const doSubmit = (data: any) => {
  }
  const handleDateChange = (date: Date | null) => {
    
    setSelectedDate(date);
    http.get("visit/gerreport?st_date="+moment(date).format('YYYY-MM-DD')+"&en_date="+moment(selectedDateend).format('YYYY-MM-DD'), header).then(json => {
      setCountservicerecipient(json.data.items[0][0].visit_pt_id)
      setListsumprices(json.data.listsumprices[0][0].order_tran_pay);
      setPrice(json.data.listsumprices[0][0].price)
      setPrice_capital(json.data.listsumprices[0][0].price_capital);
     setListdrugmax(json.data.orderminmax);
     console.log(json.data.orderminmax);
     setListptbydays(json.data.listptbydays);
  })
    
  };

  const handleDateChangeend=  (date: Date | null) => {
      setSelectedDateend(date);
     http.get("visit/gerreport?st_date="+moment(selectedDate).format('YYYY-MM-DD')+"&en_date="+moment(date).format('YYYY-MM-DD'), header).then(json => {
      setCountservicerecipient(json.data.items[0][0].visit_pt_id)
      setListsumprices(json.data.listsumprices[0][0].order_tran_pay)
      setPrice(json.data.listsumprices[0][0].price)
      setPrice_capital(json.data.listsumprices[0][0].price_capital);
      setListdrugmax(json.data.orderminmax);
      setListptbydays(json.data.listptbydays);
  })
  };
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
       
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
         <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <MenuHeader/>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid  container spacing={3}>
            <Grid  item xs={12}>
               {appContext.patient}
               <Card >
               <CardHeader
      
       
      titleTypographyProps={{variant:'subtitle1' }}
      title="Report"
    />
  
      <CardContent>
      <Grid   container spacing={1}>
      <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
{/* <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate> */}
     <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
      <DatePicker
        label="Report date Start"
        format="DD/MM/YYYY"
        InputLabelProps={{
          shrink: true,
      }}
      inputVariant="outlined"
        pickerHeaderFormat="ddd D MMM"
        yearOffset={543}
        onChange={(newDate:any) => {
          handleDateChange(newDate)
        }} 
        value={selectedDate}
      />
     
    </MuiPickersUtilsProvider>
    &nbsp;
    &nbsp;

    <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
      <DatePicker
        label="Report date End"
        format="DD/MM/YYYY"
        pickerHeaderFormat="ddd D MMM"
        yearOffset={543}
        onChange={(newDate:any) => {
          handleDateChangeend(newDate)
        }} 
        value={selectedDateend}
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
        {/* </form> */}
        </Grid>
        <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
        <Typography >
        สรุป รายรับ จำนวนผู้ป่วย
        </Typography>
         
          </Grid>
          <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
          <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title}  gutterBottom>
        ผู้มาใช้บริการ
        </Typography>
        <Typography variant="h5" component="h2">
          {countservicerecipient} คน
        </Typography>
        </CardContent>
        </Card>
          &nbsp; &nbsp;
          <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title}  gutterBottom>
        รายได้รวม
        </Typography>
        <Typography variant="h5" component="h2">
          {listsumprices} บาท
        </Typography>
        </CardContent>
        <CardContent>
        <Typography className={classes.title}  gutterBottom>
        กำไร
        </Typography>
        <Typography variant="h5" component="h2">
          {price} บาท
        </Typography>
        </CardContent>
        <CardContent>
        <Typography className={classes.title}  gutterBottom>
        ทุน
        </Typography>
        <Typography variant="h5" component="h2">
          {price_capital} บาท
        </Typography>
        </CardContent>
        </Card>
          </Grid>
          <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
          
            </Grid>
        </Grid>
    </CardContent>
    </Card>
    <Card>
    <CardContent>
    <Grid  container spacing={3}>
    <Grid item xs={6}>
    ผู้ที่มาใช้บริการ
    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ลำดับ</TableCell>
            <TableCell align="center">วันที่</TableCell>
            <TableCell align="center">จำนวนผู้รับบริการ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listptbydays.map((row:any,index:any) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell align="center">{moment(row.visit_date).format('DD/MM/YYYY')}</TableCell>
              <TableCell align="center">{row.cid}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
    </Grid>
    <Grid item xs={6}>
    จำนวน order

    <TableContainer component={Paper}>
      <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ลำดับ</TableCell>
            <TableCell align="center">ชื่อรายการ</TableCell>
            <TableCell align="center">จำนวนจ่าย</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listdrugmax.map((row:any,index:any) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell align="center">{row.order_name}</TableCell>
              <TableCell align="center">{row.order_qty}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid>
    </Grid>
    </CardContent>
    </Card>



            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}