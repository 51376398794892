import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'

import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import Unitlist from "./Unitlist";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";

import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import Autocomplete from '@material-ui/lab/Autocomplete';
import http from "../../http-common";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '780px',
},
layout: {
  width: 'auto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},

  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  MuiFormControlmarginNormal:{
    marginBottom:'8px'
},
  title: {
    flexGrow: 1,
    backgroundColor:"#cccccc",
    paddingTop:'15px',
    paddingRight:'40px',
    paddingBottom:'15px',
    paddingLeft:'15px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const initialState = {
  items: [],
  amphur:[],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_amphur": {
        const {amphur} = action.payload;
        return {
            ...state,
            amphur
        }
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function DrugForm(props:any) {
    
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  let  id  = props.match.params.id;
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [drugstrength, setDrugstrength] = React.useState<any>([]);
  const [dosage,setDosage]= React.useState<any>([{strength:''}]);
  const [dosagecountlist,setDosagecountlist] = React.useState<any>([]);
  const [dosagecount,setDosagecount] = React.useState<any>([{units:''}]);
  const [drughintlist,setDrughintlist]= React.useState<any>([]);
  const [stickerdetail,setStickerdetail]= React.useState<any>([{hinttext:''}]);
  const [dosageformlist,setDosageformlist]= React.useState<any>([]);
  const [dosageform,setDosageform]= React.useState<any>([{name:''}]);
  const [drugusagelist,setDrugusagelist]= React.useState<any>([]);
  const [drugusage,setDrugusage]= React.useState<any>([{drugusage:'',code:''}]);
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const [showdetail,setShowdetail] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
 
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    http.get("drug/detaildrug", header).then(json => {
                  setDrugstrength(json.data.items);
                  setDosagecountlist(json.data.drugunits);
                  setDrughintlist(json.data.drughints);
                  setDosageformlist(json.data.dosageforms);
                  setDrugusagelist(json.data.drugusages);
                })
    if (typeof id !== 'undefined') {
        http.get("drug/byid?id=" +id, header).then(json2 => {
                    setValuesave('type_item',json2.data.items[0].type_item)
                    setValuesave('name', json2.data.items[0].name);
                    setValuesave('tradname', json2.data.items[0].tradname);
                    setValuesave('itemcode', json2.data.items[0].itemcode);
                    setValuesave('dosage', json2.data.items[0].dosage);
                    setValuesave('price', json2.data.items[0].price);
                    setValuesave('price_capital', json2.data.items[0].price_capital);
                    setValuesave('qty', json2.data.items[0].qty);
                    setValuesave('strength', json2.data.items[0].strength);
                    setDosage([{strength: json2.data.items[0].dosage}])
                    setDosagecount([{units: json2.data.items[0].dosagecount}])
                    setValuesave('sitckerthai', json2.data.items[0].sitckerthai);
                    setValuesave('keyword', json2.data.items[0].keyword);
                    setSelectedDate(json2.data.items[0].expire_date);
                    setStickerdetail([{hinttext: json2.data.items[0].stickerdetail}]);
                    setDosageform([{name: json2.data.items[0].dosageform}]);
                    setDrugusage([{drugusage:json2.data.items[0].drugusage,code:json2.data.items[0].code}]);
                })
                setShowdetail(true)
    }

  }, [id])

const doSubmit = (data: any) => {
  if (Object.keys(drugusage).length !== 0) {
  data.drugusage = drugusage[0].drugusage;
  }
    http.post("drug?id="+id, data, header)
    .then(function (response: any) {
        if(response.data.ok){
            alert(response.data.message)
            history.replace({
                pathname: `/drug`,
              })
        }else{
            alert(response.data.message)
        }
        
    })
};
const handleClickOpendialog = ()=>{
    history.replace({
      pathname: `/drug`,
    })
  }
const form = (
  <React.Fragment>
     <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <Typography className={classes.title}  gutterBottom>
          ข้อมูล Item
        </Typography>
          </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="type_itemlabel">ประเภท</InputLabel>
                                                <Controller
                                                    name="type_item"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="type_itemlabel"
                                                            id="type_item"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="ประเภท"
                                                        >
                                                            
                                                                <MenuItem value="DRUG">DRUG</MenuItem>
                                                                <MenuItem value="ORDER">ORDER</MenuItem>
                                                                <MenuItem value="SERVICE">SERVICE</MenuItem>
                                                           
                                                        </Select>)
                                                    }
                                                />
                  </FormControl>    
            </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="name"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            error={errorspt.name ? true :false}
                                            helperText={errorspt.name ? ('กรุณากรอกName'):('')}
                                            id="name"
                                            label="Name"
                                            inputRef={register({
                                                required: true
                                            })
                                            }
                                          
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="tradname"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="tradname"
                                            label="Tradname"
                                            inputRef={register()
                                            }
                                          
                                          
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="keyword"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="keyword"
                                            label="คำค้นหา"
                                            inputRef={register()
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="strength"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="strength"
                                            label="ความแรง"
                                            error={errorspt?.strength?.type === "required" && (true)}
                                            helperText={errorspt?.strength?.type === "required" && ('กรุณากรอกความแรง')}
                                             inputRef={register()
                                          }
                                        />            
            </Grid>
            <Grid item xs={3} sm={3}>
            <Autocomplete
            value={dosage[0]}
                    id="dosage"
                    onChange={(event: any, newValue: any) => { 
                      if(newValue !== null){
                      setDosage([{strength:newValue.strength}]);
                      }else{
                        setDosage([{strength:''}]);
                      }
                        }}
                        getOptionSelected={(option, value) => option.strength == value.strength}
                      getOptionLabel={(option) => option.strength+ ""}
                      options={drugstrength}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="หน่วยความแรง"
                        variant="outlined"
                        name="dosage"
                        inputRef={register()
                    }
                      />
                    )}
                  />
                                    
            </Grid>
            <Grid item xs={3} sm={3}>
            <Autocomplete
            value={dosagecount[0]}
                    id="dosagecount"
                    onChange={(event: any, newValue: any) => { 
                      if(newValue !== null){
                      setDosagecount([{units:newValue.units}]);
                      }else{
                        setDosagecount([{units:''}]);
                      }
                        }}
                        getOptionSelected={(option, value) => option.units == value.units}
                      getOptionLabel={(option) => option.units+ ""}
                      options={dosagecountlist}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="หน่วยนับ"
                        variant="outlined"
                        name="dosagecount"
                        inputRef={register({
                          required: true
                      })
                    }
                      />
                    )}
                  />
            
            </Grid>
            <Grid item xs={3} sm={3}>
            <Autocomplete
            value={dosageform[0]}
                    id="dosageform"
                    onChange={(event: any, newValue: any) => { 
                      if(newValue !== null){
                      setDosageform([{name:newValue.name}]);
                      }else{
                        setDosageform([{name:''}]);
                      }
                        }}
                        getOptionSelected={(option, value) => option.name == value.name}
                      getOptionLabel={(option) => option.name+ ""}
                      options={dosageformlist}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dosage Form"
                        variant="outlined"
                        name="dosageform"
                        inputRef={register()
                    }
                      />
                    )}
                  />
            
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="sitckerthai"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="sitckerthai"
                                            label="ฉลาก"
                                            inputRef={register()
                                        }
                                          
                                        />
            </Grid>
            <Grid item xs={6} sm={6}>
            <Autocomplete
            value={stickerdetail[0]}
                    id="stickerdetail"
                    onChange={(event: any, newValue: any) => { 
                      if(newValue !== null){
                        setStickerdetail([{hinttext:newValue.hinttext}]);
                      }else{
                        setStickerdetail([{hinttext:''}]);
                      }
                      
                        }}
                        getOptionSelected={(option, value) => option.hinttext == value.hinttext}
                      getOptionLabel={(option) => option.hinttext+ ""}
                      options={drughintlist}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ฉลากช่วย"
                        variant="outlined"
                        name="stickerdetail"
                        inputRef={register()
                    }
                      />
                    )}
                  />
       
            </Grid>
            
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="qty"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                           
                                            error={errorspt?.qty?.type === "required" && (true)}
                                            helperText={errorspt?.qty?.type === "required" && ('กรุณากรอกจำนวน')}
                                            id="qty"
                                            label="จำนวน"  
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                        />
                                    
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="price_capital"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                           
                                            error={errorspt?.price_capital?.type === "required" && (true)}
                                            helperText={errorspt?.price_capital?.type === "required" && ('กรุณากรอกราคาต้นทุน')}
                                            id="price_sale"
                                            label="ราคาต้นทุน"  
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                        />         
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="price"
                                            variant="outlined"
                                            
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                           
                                            error={errorspt?.price?.type === "required" && (true)}
                                            helperText={errorspt?.price?.type === "required" && ('กรุณากรอกราคาขาย')}
                                            id="price"
                                            label="ราคาขาย"  
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                                        />         
            </Grid>
            
            <Grid item xs={6} sm={6}>
            <Autocomplete
            value={drugusage[0]}
                    id="drugusage"
                    onChange={(event: any, newValue: any) => { 
                      if(newValue !== null){
                        setDrugusage([{drugusage:newValue.drugusage,code:newValue.code}]);
                      }else{
                        setDrugusage([{drugusage:'',code:''}]);
                      }
                        }}
                      getOptionSelected={(option, value) => option.drugusage == value.drugusage}
                      getOptionLabel={(option) => option.code}
                      options={drugusagelist}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="วิธีการใช้ยา"
                        variant="outlined"
                        name="drugusage"
                        inputRef={register()
                    }
                      />
                    )}
                  />
              </Grid>
              <Grid item xs={4} sm={4}>
       <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                                                <DatePicker
                                                
                                                    id="expire_date"
                                                    label="วันหมดอายุ"
                                                    name="expire_date"
                                                    format="YYYY-MM-DD"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                  }}
                                                  value={selectedDate}
                                                onChange={(newDate:any) => {
                                                  setSelectedDate(newDate)
                          
                                                }} 
                                                  inputVariant="outlined"
                                                
                                                />
        </MuiPickersUtilsProvider>
       </Grid>
              <Grid xs={12} sm={12}>
               {showdetail == true ? <Unitlist id={id} />
                :''}
                </Grid> 
            <Grid item xs={12} sm={12}>
            <Button  type="submit" variant="contained" color="primary">
            บันทึกข้อมูล
          </Button>
          &nbsp;
            <Button onClick={handleClickOpendialog} variant="contained" color="secondary">
            Cancel
          </Button>
          </Grid>
          </Grid>
          </form>
         
    </React.Fragment>
);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>   
    {form}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
     
    </div>
  );
}