import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import * as dayjs from 'dayjs'
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import Patient from "./Patient";
import Listwaitcheck from "./Listwaitcheck";
import Listcheck from "./Listcheck";
import Listdrugcheck from './Listdrugcheck';
import Pe from "./Pe";
import Certificatedoctor1 from './Certificatedoctor1';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Certificatedoctor2 from './Certificatedoctor2';
import Appointmentlist from './Appointmentlist'
import { useHistory } from 'react-router-dom';
import Formdrug from './Formdrug';
import http from "../../http-common";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/th';
import Hidden from '@material-ui/core/Hidden';
// import * as io from "socket.io-client";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}
const dd = moment.locale('th')
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
// Generate Order Data
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: "auto",
  },
  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},


MuiTabPanelroots:{
  padding:0
},
  root: {
    flexGrow: 1,
  },
  
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  avatar: {
    backgroundColor: red[500],
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    }
    ,expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },expandOpen: {
      transform: 'rotate(180deg)',
    }
}));
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}


export default function Emr() {
  const history = useHistory();
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const classes = useStyles();

  const [openmodal, setOpenmodal] = React.useState(false);

  const handleOpenmodal = () => {
    setOpenmodal(true);
  };

  const handleClosemodal = () => {
    setOpenmodal(false);
  };
  const hostreport="http://45.32.107.235";

  const [patient, setPatient]= React.useState<any>([]);
  const [visit, setVisit]= React.useState<any>([]);
  const [opdscreen, setOpdscreen]= React.useState<any>([]);
  const [appoint, setAppoint]= React.useState<any>([]);
  //const {patient} = React.useContext(ThemeContext);
  const [open, setOpen] = React.useState(false);
  const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
  const [options, setOptions] = React.useState([{id:"",pt_prefix_id:"",pt_firstname:"",pt_lastname:""}]);
  const [packgate, setPackgate] = React.useState([{id_pack:"",name_pack:""}]);
  const [packgatedetail,setPackgatedetail]= React.useState<any>([]);
  const [order,setOrder]= React.useState<any>([]);
  const [drug,setDrug]= React.useState<any>([]);
  const loading = open && options.length === 0;
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [summunny,setSummunny]  = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //dialog
  const [opendialog, setOpendialog] = React.useState(false);
  const [showwaitcheck,setShowwaitcheck]= React.useState(false);
  const [showcheck,setShowcheck]= React.useState(false);
  const [showdrugcheck,setShowdrugcheck]= React.useState(false);
  const [showcer1,setShowcer1]= React.useState(false);
  const [summony,setSummony]= React.useState(0);
  const [tron,setTron]= React.useState(0);
  const [opendialogeditdrug,setOpendialogeditdrug]= React.useState(false);
  const [remed,setRemed] = React.useState([{id:"",visit_date:""}]);
  const [remedorder,setRemedorder] = React.useState([]);
  const [itemlist,setItemlist]= React.useState([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
  const [statusbill,setStatusbill] = React.useState([]);
  const [bill_type,setBill_type] = React.useState([{title:"เงินสด"}]);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
// const socket = io.connect("http://localhost:9000");
const [waitcheck,Setwaitcheck] = React.useState('');
const [passcheck,Setpasscheck]= React.useState('');
const [waitdrug,Setwaitdrug]= React.useState('');
const topic = `bp/savebp`;
const [showcer2,setShowcer2]= React.useState(false);
const [loaddatanew,setLoaddatanew] = React.useState(false);
const [opendialogdrug,setOpendialogdrug]  = React.useState(false);
const [editdata,setEditdata] =  React.useState([{id:"",order_tran_code:"",order_qty:"",order_price:"",id_unit:"",order_tran_pay:"",drugusage:"",name_unit:""}])
const [id_app,setId_app] = React.useState("");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleSave = (e:any) => {
    if(summony != null && summony !== 0){
      if(summony >= summunny){
    const data = {
      vn:visit[0].id,
      bil_sumprice:summunny,
      bill_recive_price:summony,
      bill_tron:tron,
      bill_type:bill_type,
      bill_date:moment(selectedDate).format('YYYY-MM-DD'),
      bil_status:'A'
    }
    http.post("bill/",data, header).then(json => {
      setOpendialogeditdrug(false);
      setVisit(json.data.items)
      Swal.fire(
        json.data.message,
        '',
        'success'
      );
      http.get('finance/listvisitrxorderbyid?id='+visit[0].id,header).then(jsonfin=>{
        setStatusbill(jsonfin.data.items)
      })
    })
    
  }else{
    alert('กรุณาใส่ยอดเงินในช่องจำนวนเงินจ่ายให้ครบ')
  }
  }else{
    alert('กรุณาใส่ยอดเงินในช่องจำนวนเงินจ่าย')
  }
  }
  const top100Films = [
    { title: 'เงินสด' },
    { title: 'เงินโอน'},
    { title: 'บัตรเคดิส/เดบิต' }
  ]
  const handleClosedialog = () => {
  }
  const handlePatient = (event: any) =>{
    console.log(event)
    if(event !== null){
      http.get('visit/byid?visit_date='+moment().format('YYYY-MM-DD')+'&visit_pt_id='+event.id, header).then(json => {
        if(typeof json.data.items !== 'undefined' && json.data.items.length > 0){
          setPatient(event);
          setOpdscreen(json.data.opdscreen);
          setVisit(json.data.items);
          setRemed(json.data.rsbypt);
          setLoaddatanew(true);
          SetCurrentdate("Current Visit[ "+thDate(moment(json.data.items[0].visit_date).add(543, 'year').format('YYYY-MM-DD'))+" "+json.data.items[0].visit_time+" น. ]");
          http.get("order/byid?id="+json.data.items[0].id, header).then(json => {
            setDrug(json.data.items)
            setOrder(json.data.order)
            setSummunny(json.data.sumall)
            localStorage.setItem('pt_id',JSON.stringify(event));
           
        })
        http.get('finance/listvisitrxorderbyid?id='+json.data.items[0].id,header).then(jsonfin=>{
          setStatusbill(jsonfin.data.items)
        })
        }else{
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-danger'
            },
            buttonsStyling: true
          })
          
          swalWithBootstrapButtons.fire({
            title: '',
            text: "คุณต้องการเปิด visit คนไข้คนนี้หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              const data = {};
              http.post('visit/byid?visit_date='+moment().format('YYYY-MM-DD')+'&visit_pt_id='+event.id,data, header).then(json => {
              setOpdscreen(json.data.opdscreen);
              setVisit(json.data.items);    
              setPatient(event);
              setRemed(json.data.rsbypt);
              setLoaddatanew(true);
              SetCurrentdate("Current Visit[ "+thDate(moment(json.data.items[0].visit_date).add(543, 'year').format('YYYY-MM-DD'))+" "+json.data.items[0].visit_time+" น. ]");
              http.get("order/byid?id="+json.data.items[0].id, header).then(json => {
                setDrug(json.data.items)
                setOrder(json.data.order)
                setSummunny(json.data.sumall)
                localStorage.setItem('pt_id',JSON.stringify(event));
            })
            http.get('finance/listvisitrxorderbyid?id='+json.data.items[0].id,header).then(jsonfin=>{
              setStatusbill(jsonfin.data.items)
            })
            }).catch(function (error) {
              history.replace({
                pathname: `/login`,
              })
            })
            }else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              http.get('visit/listlastvisit?pt_id='+event.id,header).then(jsonlast=>{
                setPatient(event);
                setOpdscreen(jsonlast.data.opdscreen);
                setVisit(jsonlast.data.items);
                setRemed(jsonlast.data.rsbypt);
              
                SetCurrentdate("Current Visit[ "+thDate(moment(jsonlast.data.items[0].visit_date).add(543, 'year').format('YYYY-MM-DD'))+" "+jsonlast.data.items[0].visit_time+" น. ]");
                http.get("order/byid?id="+jsonlast.data.items[0].id, header).then(json => {
                  setDrug(json.data.items)
                  setOrder(json.data.order)
                  setSummunny(json.data.sumall)
                  localStorage.setItem('pt_id',JSON.stringify(event));
                 
              })
              http.get('finance/listvisitrxorderbyid?id='+jsonlast.data.items[0].id,header).then(jsonfin=>{
                setStatusbill(jsonfin.data.items)
              })
              })
             // console.log(event.id)
            }
          })
        }
        
      })
      
    }
   
  //  
  }
const onChangeHandle = async (value:any) => {
  console.log(value);
       const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        await http.get("patient?query="+value, header).then(json => {
          setOptions(json.data.items);
         })
      };
const onChangeHandlelistitem = async (value:any) => {
        
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
   await http.get("drug/bysearch?limit=5&offset=0&query=&qname=", header).then(json => {
    setItemlist(json.data.items);
    })
};

     const onChangeHandlegetpackgate = async (value:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
      http.get("packgate?limit=5&offset=0&query=&qname=", header).then(json => {
          setPackgate(json.data.items);
         })
        }
        const onChangeHandlegetpackgatedetail = async (value:any) => {
          if(value != null){

         
         const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
          http.get("packgatedetail?id_pack="+value.id_pack, header).then(json => {
            setPackgatedetail(json.data.items);
          })
        }
         }
         const addorderbysearch = async(value:any)=>{
          if(visit.length  > 0){
            console.log(visit)
          if(visit[0].visit_status == 1){
            const data = {
             ptid:patient.id,
             order_tran_pay:value.qty1 * value.price1,
             order_tran_visit_id:visit[0].id,
             order_name:value.tradname +' '+ value.strength +' '+value.dosage,
             order_qty:value.qty1,
             order_price:value.price1,
             order_tran_status:1,
             order_type:value.type_item,
             order_tran_code:value.id,
             order_tran_drug_use:value.drugusage,
             order_unit:value.id_unit
            }
           http.post( "order/",data, header).then(json => {
             http.get("order/byid?id="+visit[0].id, header).then(json => {
               setDrug(json.data.items)
               setOrder(json.data.order)
               setSummunny(json.data.sumall)
           })
           })
         }
        }
         }
         const addorder = async(value:any)=>{
           if(visit.length  > 0){

           console.log(value)
           const data = {
            ptid:patient.id,
            order_tran_pay:value.packdetail_qty * value.packdetail_price,
            order_tran_visit_id:visit[0].id,
            order_name:value.tradname +' '+ value.strength +' '+value.dosage,
            order_qty:value.packdetail_qty,
            order_price:value.packdetail_price,
            order_tran_status:1,
            order_type:value.type_item,
            order_tran_code:value.item_id,
            order_tran_drug_use:value.drugusage
           }
        await  http.post("order/",data, header).then(async(json) => {
        await  http.get("order/byid?id="+visit[0].id, header).then(json => {
              setDrug(json.data.items)
              setOrder(json.data.order)
              setSummunny(json.data.sumall)
          })
          })
        }
         }
         const addorderbyremed = async(value:any)=>{
          if(visit.length  > 0){
          console.log(value)
          const data = {
           ptid:patient.id,
           order_tran_visit_id:visit[0].id,
           order_name:value.order_name,
           order_tran_pay:value.order_qty * value.order_price,
           order_qty:value.order_qty,
           order_price:value.order_price,
           order_tran_status:1,
           order_type:value.order_type,
           order_tran_code:value.order_tran_code,
           order_tran_drug_use:value.order_tran_drug_use
          }
        await http.post("order/",data, header).then(async(json) => {
          await http.get("order/byid?id="+visit[0].id, header).then(json => {
            setDrug(json.data.items)
            setOrder(json.data.order)
            setSummunny(json.data.sumall)
           })
          })
        
          }
        }
         const onChangeHandlegetbyvisit = async(value:any)=>{
          if(value !=  null){
            http.get("order/byidorderall?id="+value.id, header).then(json => {
            setRemedorder(json.data.items)
          })
        }
         }
         
         const addorderAll = async(value:any)=>{
          if(visit.length  > 0){
            if(value === 'packgate'){
             await packgatedetail.map (async(row: any, index: any) => {
                const data = {
                  ptid:patient.id,
                  order_tran_visit_id:visit[0].id,
                  order_name:row.tradname+' '+ row.strength +' '+row.dosage,
                  order_qty:row.packdetail_qty,
                  order_tran_pay:row.packdetail_qty * row.packdetail_price,
                  order_price:row.packdetail_price,
                  order_type:row.type_item,
                  order_tran_status:1,
                  order_tran_code:row.item_id,
                  order_tran_drug_use:row.drugusage
                 }
                    http.post("order/",data, header).then(async(json) => {
                  await http.get("order/byid?id="+visit[0].id, header).then(json => {
                    setDrug(json.data.items)
                    setOrder(json.data.order)
                    setSummunny(json.data.sumall)
                })
                })

              })
            
            }else if(value === 'remed'){
              console.log(remedorder)
              const datas ={
                data:remedorder,
                ptid:patient.id,
                order_tran_visit_id:visit[0].id,
              };
             await http.post ("order/remed",datas, header).then(async(json) => {
                await http.get("order/byid?id="+visit[0].id, header).then(json => {
                setDrug(json.data.items)
                setOrder(json.data.order)
                setSummunny(json.data.sumall)
                })
              })
            }
          }
         }
         const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
          }
        })
        const deleteOrder = (id:any)=>{
          swalWithBootstrapButtons.fire({
            title: '',
            text: "คุณต้องการลบรายการนี้หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              http.delete("order/" + id.id,header)
              .then(function (response: any) {
                http.get("order/byid?id="+visit[0].id, header).then(json => {
              setDrug(json.data.items)
              setOrder(json.data.order)
              setSummunny(json.data.sumall)
          })
                swalWithBootstrapButtons.fire(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
              })
              
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              
            }
          })
        }
        
      React.useEffect(() => {
        const datapt = localStorage.getItem('pt_id')
        if(datapt){
         // console.log(.id)
        // handlePatient(JSON.parse(datapt))
        }
       
        getcountdatavisit();
        if (!open) {
          setOptions([]);
        }
      }, [open]);

    const getcountdatavisit=()=>{
      http.get("visit/countwaitcheck?visit_date="+moment().format('YYYY-MM-DD'), header).then(json => {
        {json.data.items.map((row: any, index: any) => {
          Setwaitcheck(row.total);
        })}
        {json.data.items2.map((row: any, index: any) => {
          Setpasscheck(row.total);
        })}
        {json.data.items3.map((row: any, index: any) => {
          Setwaitdrug(row.total);
        })}

    })
    }
    const orderDrugupdate= (row:any,newvalue:any) => {
      const order_tran_pay = newvalue * row.order_price ;
const data = {
  order_qty:newvalue,
  order_tran_pay:order_tran_pay
 }
  http.put("order/"+row.id,data, header).then(json => {
    http.get("order/byid?id="+visit[0].id, header).then(json => {
      setDrug(json.data.items)
      setOrder(json.data.order)
      setSummunny(json.data.sumall)
  })
})
    }
   
      const handleClickOpendialog = () => {
        setOpendialog(true);
      };
      const handleClickShowwaitcheck =()=>{
        setShowwaitcheck(true);
      }
      const handleClickShowcheck =()=>{
        setShowcheck(true);
      }
      const handleClickShowdrugcheck =()=>{
        setShowdrugcheck(true);
      }
      const handleOpendialogeditdrug = ()=>{
        setOpendialogeditdrug(true);
      }
      const handleClosedialogdrug = ()=>{
        setOpendialogeditdrug(false)
      }
      const handleClickShowcer1 = ()=>{
        setShowcer1(true);
      }
      const handleClickShowcer2 = ()=>{
        setShowcer2(true);
      }

      const handleClickClostvisit = ()=>{
       
        setPatient([])
        setVisit([])
        setOpdscreen([])
        SetCurrentdate("")
        localStorage.removeItem('pt_id');
      }
      const confirmdrug =()=>{
        const data = {
    
        }
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องยืนยันการจ่ายยาหรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            http.put( "order/updatestatus/"+visit[0].id,data, header).then(json => {
              setVisit(json.data.items)
              http.get("order/byid?id="+visit[0].id, header).then(json => {
                setDrug(json.data.items)
                setOrder(json.data.order)
                setSummunny(json.data.sumall)

            })
            http.get('finance/listvisitrxorderbyid?id='+visit[0].id,header).then(jsonfin=>{
              setStatusbill(jsonfin.data.items)
            })
             });
          }
        });
      }
      const printsticker=async()=>{
        window.open(hostreport+"/report/sticker.php?vn="+opdscreen[0].vn+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token"));
      }
      const billpayment = async()=>{
        window.open(hostreport+"/report/billpayment.php?vn="+opdscreen[0].vn+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
      }
      const printEMR = async()=>{
        window.open(hostreport+"/report/emr.php?vn="+visit[0].id+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
      }
     const printPDF=async()=> {
      window.open(hostreport+"/report/refer.php?vn="+visit[0].id+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
      }
      const printdoctor1 =async()=> {
        window.open(hostreport+"/report/doctor1.php?vn="+visit[0].id+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
        }
        const printdoctor2 =async()=> {
          window.open(hostreport+"/report/doctor2.php?vn="+visit[0].id+"&pt_id="+patient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
          }
      const editOrder = (row:any)=>{
        setEditdata(row)
        setOpendialogdrug(true);
      }
      const handleOpendialogdrug = ()=>{
        setOpendialogdrug(true)
        setEditdata([{id:"",order_tran_code:"",order_qty:"",order_price:"",id_unit:"",order_tran_pay:"",drugusage:"",name_unit:""}])
      }
      const saveappoint =  ( data: any) => {
        
        setAppoint(data);
       }
      const sumdatamony = (data: any)=>{
        setSummony(data);
       const sumall =   data - summunny;
       setTron(sumall)
      }
      const savepe = ( data: any) => {
        
       setOpdscreen(data);
      }
      const savecer1 = (data:any)=>{
        console.log(data);
      }
      const savecer2=(data:any)=>{
        console.log(data);
      }
      const selectpatient=(event:any)=>{
        setOptions(event);
        http.get('visit/byid?visit_date='+moment().format('YYYY-MM-DD')+'&visit_pt_id='+event.id, header).then(json => {
          if(typeof json.data.items !== 'undefined' && json.data.items.length > 0){
            setPatient(event);
            setOpdscreen(json.data.opdscreen);
            setVisit(json.data.items);
            setRemed(json.data.rsbypt);
            setLoaddatanew(true);
            SetCurrentdate("Current Visit[ "+thDate(moment(json.data.items[0].visit_date).add(543, 'year').format('YYYY-MM-DD'))+" "+json.data.items[0].visit_time+" น. ]");
            http.get("order/byid?id="+json.data.items[0].id, header).then(json => {
              setDrug(json.data.items)
              setOrder(json.data.order)
              setSummunny(json.data.sumall)
              localStorage.setItem('pt_id',JSON.stringify(event));
             
          })
          http.get('finance/listvisitrxorderbyid?id='+json.data.items[0].id,header).then(jsonfin=>{
            setStatusbill(jsonfin.data.items)
          })
      }
    })
  }
      const cancelapproveOrder = ()=>{
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องการยกเลิกรายการนี้หรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
           
            const data = {
              ptid:visit[0].ptid,
              id:visit[0].id
            }
            http.put("order/byidcancel",data, header).then(json => {
              setVisit(json.data.items)
              http.get("order/byid?id="+visit[0].id, header).then(json => {
                setDrug(json.data.items)
                setOrder(json.data.order)
                setSummunny(json.data.sumall)
            })
          })
          }
        })
        
      }
      const approveOrder = ()=>{
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องการอนุมัติรายการนี้หรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {

          
            const data = {
              ptid:visit[0].ptid,
              id:visit[0].id
            }
            http.put("order/byid",data, header).then(json => {
              setVisit(json.data.items)
              http.get("order/byid?id="+visit[0].id, header).then(json => {
                setDrug(json.data.items)
                setOrder(json.data.order)
                setSummunny(json.data.sumall)
            })
          })
          }
        })
       
      }
   
      const cancelconfirmbill=(bil_id:any)=>{
        const data = {
    
        }
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องยืนยันยกเลิกใบเสร็จหรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            http.delete( "finance/deletefinance/"+bil_id, header).then(json => {
              http.get('finance/listvisitrxorderbyid?id='+visit[0].id,header).then(jsonfin=>{
                setStatusbill(jsonfin.data.items)
              })
             });
          }
        });
      }
      const cancelconfirmdrug=()=>{
        const data = {
    
        }
       
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องยืนยันยกเลิกการจ่ายยาหรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            http.put( "order/updatestatuscancel/"+visit[0].id,data, header).then(json => {
              setVisit(json.data.items)
              http.get('finance/listvisitrxorderbyid?id='+visit[0].id,header).then(jsonfin=>{
                setStatusbill(jsonfin.data.items)
              })
             });
          }
        });
      }
  return (
    <React.Fragment>
      <Title>EMR</Title>
      <Grid container spacing={2}>
        <Grid item xs={10}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
        
        <Autocomplete
      id="asynchronous-demo"
      onChange={(event: any, newValue: any) => {
        console.log(newValue)
        if(newValue == null){
          handleClickClostvisit()
        }else{
        handlePatient(newValue)
        }
         }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.pt_prefix_id === value.pt_prefix_id}
      getOptionLabel={(option) => " CN:"+option.id +" ชื่อสกุล:"+option.pt_prefix_id + option.pt_firstname + " "+option.pt_lastname}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          label="ค้นหาผู้รับบริการ"
          variant="outlined"
          placeholder="search For"
          onChange={ev => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
          </Grid>
          <Grid item xs={4}>
       
          <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={ <AddIcon />} 
        onClick={handleClickOpendialog}
      >
        เพิ่มข้อมูลคนไข้
      </Button> &nbsp;
          <Button  size="small"  variant="outlined"  color="primary"><AutorenewIcon/></Button>
          &nbsp;
          <Button  size="small" variant="outlined" color="secondary"><ClearIcon/></Button>
          
          </Grid>
          <Grid item xs ={6}>
          <Patient
visitpt={remed}
handleCloseOpendia={() => {
  setOpendialog(false);
}}
        saveCallback={""}
        initData={patient}
        open={opendialog}
        /> 
     <Grid container spacing={1}>
        <Grid item xs={6}>
          <br/>
          {opdscreen.length > 0  ?  <Button onClick={handleClickOpendialog} fullWidth variant="outlined" color="primary">Edit <EditIcon/></Button>
       :""} </Grid>
        <Grid item xs={6}>
          <br/>
          {opdscreen.length > 0 ?  <Button onClick={handleClickClostvisit} fullWidth variant="contained" color="secondary">Close Visit</Button> :""}
        </Grid>
        <Grid item xs={6}>
          
      {opdscreen.length > 0 ? <Appointmentlist initData={appoint} initPatient={patient}  saveCallback={saveappoint} initOpdscreen={opdscreen} /> :""}
        </Grid>
        <Grid item xs={6}>
       
       {opdscreen.length > 0 ?  <Button onClick={printPDF} fullWidth variant="contained" color="primary">พิมพ์ใบส่งตัว <PrintIcon/></Button>:""}
        </Grid>
    </Grid>
          </Grid>
          <Grid item xs ={6}>
          { opdscreen.length > 0 ? <Pe
          loaddatanew={loaddatanew}
        saveCallback={savepe}
        initData={opdscreen}
        cerrentdatetime={cerrentdatetime}
        /> :""}
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={2}>
           <Card className={classes.root}>
      <CardContent>
        <Typography align="left" gutterBottom>
        Visit Open
          </Typography>
          <Button size="small" onClick={handleClickShowwaitcheck}  style={{margin:5}} variant="outlined" fullWidth  color="primary">รอตรวจ {waitcheck}</Button>
         <Listwaitcheck  saveCallback={selectpatient} open={showwaitcheck} handleCloseListwait={() => {
  setShowwaitcheck(false);
}} />
          <Button size="small" onClick={handleClickShowcheck} style={{margin:5}}  variant="outlined"  fullWidth color="primary">ตรวจแล้ว {passcheck}</Button>
          <Listcheck  saveCallback={selectpatient} open={showcheck} handleCloseListwait={() => {
  setShowcheck(false);
}} />
          <Button size="small" onClick={handleClickShowdrugcheck}  style={{margin:5}}  variant="outlined" fullWidth  color="primary">รอจ่ายยา {waitdrug}</Button>
          <Listdrugcheck saveCallback={selectpatient} open={showdrugcheck} handleCloseListwait={() => {
  setShowdrugcheck(false);
}} />
          </CardContent>
          </Card>

          <br/>
          { opdscreen.length > 0 ? <Card className={classes.root}>
      <CardContent>
        <Typography align="left" gutterBottom>
        รายการพิมพ์
          </Typography>
          <Button variant="outlined" style={{margin:5}}  onClick={printEMR}   size="small" color="primary">เวชระเบียน</Button>

          <Button variant="outlined" style={{margin:5}}  onClick={handleClickShowcer1}   size="small" color="primary">ใบรับรองแพทย์</Button>
          <Certificatedoctor1  initPatient={patient} open={showcer1} saveCallback={savecer1} initOpdscreen={opdscreen} handleCloseCer1={() => {
  setShowcer1(false);
}} />
          <Button variant="outlined" style={{margin:5}}  onClick={handleClickShowcer2}   size="small" color="primary">ใบรับรองแพทย์ใบขับขี่</Button>
          <Certificatedoctor2  initPatient={patient} open={showcer2} saveCallback={savecer2} initOpdscreen={opdscreen} handleCloseCer1={() => {
  setShowcer2(false);
}} />
         
          </CardContent>
          </Card>
:""}
          </Grid>
<Grid item xs={5}>
{ opdscreen.length > 0 ? <Card className={classes.root}>
          <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
            <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          // scrollButtons="on"
          indicatorColor="primary"
          // textColor="primary"
          // aria-label="scrollable force tabs example"
        >
          <Tab label="Packgate"  {...a11yProps(0)} />
          <Tab label="Re med" {...a11yProps(1)} />
        </Tabs>
            <TabPanel  value={value} index={0}>
            <Autocomplete
      id="pt_remed"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
        onChangeHandlegetpackgatedetail(newValue)
        }
      }}
         getOptionLabel={(option) => option.name_pack + ""}
         options={packgate}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={ev => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandlegetpackgate(ev.target.value);
            }
          }}
          label="packgate"
          variant="outlined"
        />
      )}
    />
    <br/>
    <Box alignItems="flex-start">
    {visit.map((row: any, index: any) => {
  return(
  row.visit_status == 1 ?<Button size="small" variant="contained" onClick={() => {
                          {
                  addorderAll('packgate')
                          }}} color="primary">
  เพิ่มทั้งหมด <ArrowForwardIcon/>
</Button> :"" 
  )
    })}
    </Box>
    <TableContainer >
   
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell width="5%" align="center">Type</TableCell>
            <TableCell width="30%" align="center">Order</TableCell>
            <TableCell width="5%" align="center">Amount</TableCell>
            <TableCell width="5%" align="center">Price</TableCell>
            <TableCell width="5%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {packgatedetail.map((row: any, index: any) => {
             return (
            <TableRow >
            <TableCell align="center">{index+1}</TableCell>
             <TableCell >{row.type_item}</TableCell>
             <TableCell align="center">{row.tradname} {row.strength} {row.dosage}</TableCell>
             <TableCell align="center">{row.packdetail_qty}</TableCell>
             <TableCell align="center">{row.packdetail_price}</TableCell>
              <TableCell align="center">
              {visit.map((visitrow: any, visitindex: any) => {
  return(
    visitrow.visit_status == 1 ? <Button variant="outlined" onClick={() => {
                          {
                  addorder(row)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
                
          <ArrowForwardIcon/>
        </Button>:""
  )
                        })}

        </TableCell>
        </TableRow>
)
})}
        </TableBody>
      </Table>
    </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Autocomplete
      id="remed"
      onChange={(event: any, newValue: any) => {
        if(newValue != null){
          onChangeHandlegetbyvisit(newValue)
        }
        
      }}
         getOptionLabel={(option) => option.visit_date + ""}
         options={remed}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={ev => {

          }}
          label="Re med"
          variant="outlined"
        />
      )}
    />
     {visit.map((row: any, index: any) => {
  return(
    row.visit_status == 1 ? <Button size="small" variant="contained" onClick={() => {
                          {
                  addorderAll('remed')
                          }}} color="primary">
  เพิ่มทั้งหมด <ArrowForwardIcon/>
</Button> :"" )
     })}
      <TableContainer component={Paper}>
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell width="5%" align="center">Type</TableCell>
            <TableCell width="40%" align="center">Order</TableCell>
            <TableCell width="5%" align="center">Amount</TableCell>
            <TableCell width="5%" align="center">Price</TableCell>
            <TableCell width="5%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {remedorder.map((row: any, index: any) => {
             return (
          <TableRow >
          <TableCell align="center">{index+1}</TableCell>
             <TableCell >{row.order_type}</TableCell>
             <TableCell align="center">{row.order_name}</TableCell>
             <TableCell align="center">{row.order_qty}</TableCell>
             <TableCell align="center">{row.order_price}</TableCell>
            <TableCell align="center">

            {visit.map((visitrow: any, visitindex: any) => {
  return(
    visitrow.visit_status == 1 ? <Button  variant="outlined" onClick={() => {
                          {
                            addorderbyremed(row)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
              
        <ArrowForwardIcon/>
      </Button> :""
  )
                        })}


      </TableCell>
          </TableRow>
             )})}
        </TableBody>
      </Table>
    </TableContainer>
      </TabPanel>
            </Grid>
          </Grid>
          </CardContent>
        </Card> :""}
            </Grid>
            <Grid item xs={7}>
            { opdscreen.length > 0 ? <Card className={classes.root}>
          <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>

            <Grid container spacing={2}>
            <Grid item xs={8} >
            <Hidden>
            <Autocomplete
              id="searchdrug"
              onChange={(event: any, newValue: any) => {
                if(newValue != null){
                  addorderbysearch(newValue)
                }
              }}
                getOptionLabel={(option) => option.tradname +" "+ option.strength+" "+ option.dosage + " ราคา " +option.price1+" บาท/"+option.dosagecount1+""}
                options={itemlist}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  variant="outlined"
                  onChange={ev => {
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== "" || ev.target.value !== null) {
                      onChangeHandlelistitem(ev.target.value);
                    }
                  }}
                />
              )}
            />
            </Hidden>
            </Grid>
            <Grid item xs={2}>
            {visit.map((row: any, index: any) => {
              return(
              <Formdrug opendialogdrug={opendialogdrug} editdata={editdata} visitdata={row} handleClosedialogdrug={() => {
                setOpendialogdrug(false);
                http.get("order/byid?id="+visit[0].id, header).then(json => {
                  setDrug(json.data.items)
                  setOrder(json.data.order)
                  setSummunny(json.data.sumall)
              })
              }}  />
            )})}
                        {visit.map((row: any, index: any) => {
return(
  row.visit_status == 1 ?  <Button  onClick={handleOpendialogdrug} variant="contained" color="primary">
  เพิ่มรายการ
</Button>:""
)})}


            {visit.map((row: any, index: any) => {
return(

             row.visit_status == 3 ? <Button onClick={printsticker} variant="contained" color="primary">
  sticker
</Button>:""
)})}
            </Grid>
            <Grid item xs={2}>
            {statusbill.map((row: any, index: any) => {
return(
  row.bil_id !== null ? <Button onClick={billpayment} variant="contained" color="secondary">
  ออกบิล
</Button> :""
)})}
            </Grid>
          </Grid>
       <Typography variant="body1" align="left">รายการยา</Typography>
       <TableContainer component={Paper}>
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell width="30%" align="center">รายการยา</TableCell>
            <TableCell width="5%" align="center">จำนวน</TableCell>
            <TableCell width="5%" align="center">หน่วย</TableCell>
            <TableCell width="5%" align="center">ราคา</TableCell>
            <TableCell width="5%" align="center">รวมราคา</TableCell>
            <TableCell width="20%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {drug.map((row: any, index: any) => {
             return (
            <TableRow >
              <TableCell >{index+1}</TableCell>
             <TableCell align="center">{row.order_name} <br/>{row.code}
            </TableCell>
             <TableCell align="center">{row.order_tran_status == 1 ?  <TextField
                    value={row.order_qty}
                    onChange={(e) => {
                      const value = e.target.value;
                      orderDrugupdate(row,value)
                      // let tempOrderList = currentOrderList;
                      // tempOrderList[i].qty = value;
                      // setCurrentOrderList([...tempOrderList]);
                    }}
                    id={'qty-' + index}
                    name={'qty-' + index}
                    label="จำนวน"
                    type="number"
                    fullWidth
                  /> : row.order_qty }</TableCell>
                  <TableCell align="center">{row.name_unit}</TableCell>
             <TableCell align="center">{row.order_price}</TableCell>
              <TableCell align="center">{row.order_qty * row.order_price}</TableCell>
              <TableCell align="center">
              
              {row.order_tran_status == 1 ?<Button variant="outlined" onClick={() => {
                          {
                            editOrder(row)
                          }}}  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
                <EditIcon/>
              </Button> :""}
              &nbsp;
        {row.order_tran_status == 1 ?<Button variant="outlined" onClick={() => {
                          {
                  deleteOrder(row)
                          }}}  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="secondary" >
                <DeleteIcon/>
              </Button>:""}
              
              </TableCell>
            </TableRow>
             )})}
        </TableBody>
      </Table>
    </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="left">รายการ Order และ Service</Typography>
              <TableContainer component={Paper}>
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell width="5%" align="center">ประเภท</TableCell>
            <TableCell width="30%" align="center">รายการ</TableCell>
            <TableCell width="5%" align="center">จำนวน</TableCell>
            <TableCell width="5%" align="center">ราคา</TableCell>
            <TableCell width="5%" align="center">รวมราคา</TableCell>
            <TableCell width="25%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {order.map((row: any, index: any) => {
           return( <TableRow >
              <TableCell >{index+1}</TableCell>
           <TableCell align="center">{row.order_type}</TableCell>
           <TableCell align="center">{row.order_name}</TableCell>
           <TableCell align="center">{row.order_qty}</TableCell>
              <TableCell align="center">{row.order_price}</TableCell>
              <TableCell align="center">{row.order_qty * row.order_price}</TableCell>
              <TableCell align="center">
                
              {row.order_tran_status == 1 ?<Button variant="outlined" onClick={() => {
                          {
                  deleteOrder(row)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px',minHeight: '30px'}} color="secondary">
                <DeleteIcon/>
              </Button>:""}</TableCell>
            </TableRow>)
        })}
        </TableBody>
      </Table>
    </TableContainer>

            </Grid>
            <Grid item xs={12}>
            <Typography variant="h6" align="right">
              Total {summunny} บาท
              </Typography>
              </Grid>
              <Grid item xs={12}>
            <Typography variant="h6" align="right">
            {visit.map((row: any, index: any) => {
return(
  row.visit_status == '2' ? <Button variant="contained"  color="secondary" onClick= {cancelapproveOrder}>ยกเลิก Approve</Button> : ""
)
            })} &nbsp;

{visit.map((row: any, index: any) => {
return(
  row.visit_status == '1' ?  <Button variant="contained"  color="primary" onClick= {approveOrder}>
  Approve
</Button> : "")
})}
{visit.map((row: any, index: any) => {
return(
  row.visit_status == '2' ?  <Button variant="contained" onClick={() => {
    {
      confirmdrug()
    }}} color="primary" >
ยืนยันการจ่ายยา
</Button> : "")
})}
{statusbill.map((row: any, index: any) => {
return(
  row.bil_id === null ?
  <Button variant="contained" color="secondary" onClick={() => {
    {
      cancelconfirmdrug()
    }}} >
ยกเลิกการจ่ายยา
</Button>  :"")
})}
&nbsp;

{statusbill.map((row: any, index: any) => {
return(
  row.bil_id !== null ?
  <Button variant="contained" color="secondary" onClick={() => {
    {
      cancelconfirmbill(row.bil_id)
    }}} >
ยกเลิกการออกบิล
</Button>  :"")
})}
&nbsp;

{statusbill.map((row: any, index: any) => {
return(
  row.bil_id === null ?  <Button variant="contained" onClick={() => {
    {
      handleOpendialogeditdrug()
    }}} color="primary" >
ยืนยันการออกบิล
</Button> : "")
})}




              </Typography>
              </Grid>
          </Grid>
          </CardContent>
      </Card> :""}
</Grid>
          </Grid>
          <form onSubmit={e => {
    handleSave(e)
}}>
<Dialog fullWidth={true} maxWidth="md" open={opendialogeditdrug} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">ยืนยันการจ่ายเงิน</DialogTitle>
<DialogContent>
<Grid container spacing={2}>
<Grid item xs={12} sm={12}>
           <TextField
                   name="summaryprice"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   value={summunny}
                   aria-readonly={true}
                   id="summaryprice"
                   label="ยอดเงินที่ต้องชำระ"
                   
                 
               />
</Grid>
<Grid item xs={12} sm={12}>
           <TextField
                   name="recive_price"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   onChange={(e) => {
                    const value = e.target.value;
                    sumdatamony(value)
                   }}
                   value={summony}
                   aria-readonly={true}
                   id="recive_price"
                   label="จำนวนเงินจ่าย"
                   autoFocus
                 
               />
</Grid>
<Grid item xs={12} sm={12}>
           <TextField
                   name="tron"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   aria-readonly={true}
                   id="tron"
                   label="ทอน"
                   value={tron}
                   
                 
               />
</Grid>
    <Grid item xs={4} sm={4}>
          <Autocomplete
          id="bill_type"
          value={top100Films[0]}
          options={top100Films}
          onChange={(event: any, newValue: any) => {
          setBill_type(newValue)
          }}
          getOptionLabel={(option) => option.title}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="ประเภทเงินจ่าย" variant="outlined" />}
          />
    </Grid>
<Grid item xs={4} sm={4}>
<MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                            <DatePicker
                            
                                id="bill_date"
                                label="วันที่ออกใบเสร็จ"
                                name="bill_date"
                                format="DD/MM/YYYY"
                                InputLabelProps={{
                                  shrink: true,
                              }}
                              value={selectedDate}
                              yearOffset={543}
                            onChange={(newDate:any) => {
                              setSelectedDate(newDate)
                            }} 
                              inputVariant="outlined"
                            
                            />
</MuiPickersUtilsProvider>
</Grid>
<Grid item xs={12} sm={12}>
  
</Grid>
</Grid>
</DialogContent>
<DialogActions>
<Button variant="contained" onClick={handleSave} color="primary">
บันทึกข้อมูล
</Button>
<Button variant="contained" onClick={handleClosedialogdrug} color="secondary">
Cancel
</Button>
</DialogActions>
</Dialog>
</form>
    </React.Fragment>
  );
}
