import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, lighten, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import  { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import EditIcon from '@material-ui/icons/Edit';
import { default as dayjs } from 'dayjs';
import { green } from '@material-ui/core/colors';
import http from "../../http-common";
const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },


    });
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    option: {
        fontSize: 15,
        '& > span': {
          marginRight: 10,
          fontSize: 18,
        },
      },

});
const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexContainer2: {
            display: 'flex',
            flexDirection: 'row',
            marginLeft: theme.spacing(3),
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        button: {
            margin: theme.spacing(1),
        }, fabGreen: {
            color: theme.palette.common.white,
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[600],
            },
        }
    })
)

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}
export interface State extends SnackbarOrigin {
    opensnack: boolean;
}
export default function PackgateDetail(IDCNO: any) {
    const classes = useStyles();
    const classes2 = useToolbarStyles();
    const [open, setOpen] = React.useState(false);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const urlrowsimage = 'packgatedetail';
    const [data2, setData] = useState<any[]>([])
    const headerrowsimage = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [rows, setRows] = useState<any[]>([]);
    const [opensnack, setOpensnack] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [opencf, setOpencf] = React.useState(false);
    const [values,setValues]= React.useState<any>([{ id: '', tradname: '',strength: '', price: ''}]);
    const [packdetail_qty,setPackdetail_qty] = React.useState('');
    const [packdetail_price,setPackdetail_price]= React.useState('');
    const [CustNO, setCustNO] = React.useState('');
    const [id,setId] = React.useState('');
    const [packdetail_id,setPackdetail_id]= React.useState('');
    const [countries,setCountries]= React.useState<any[]>([{ id: '', tradname: '',strength:'', price: ''}]);
    const [itemlist,setItemlist]= React.useState([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
    const getdata = () => {
        http.get(urlrowsimage+"?id_pack="+IDCNO.IDCNO, headerrowsimage).then(json => {
            console.log(json.data.items);
            setRows(json.data.items);
        });
        console.log(IDCNO.IDCNO)
        setCustNO(IDCNO.IDCNO);
    }
    const getdatadrug = () => {
        http.get("drug/getbynotlimit", headerrowsimage).then(json => {
            setCountries(json.data.items);
        });
    }
    useEffect(() => {
        getdata();
        getdatadrug();
    }, [])


    const handleGetpresent = (e: any) => {
            setOpen(true);
            setCustNO(IDCNO.IDCNO);
            setValues([{ id: '', tradname: '', price: ''}]);
            setPackdetail_price("");
            setPackdetail_qty("");
            setId("");
            setPackdetail_id("");
    }
    const handleClosedialog = ()=>{
        setOpen(false);
    }
  
  

    const handleClose = () => {
        setOpen(false);
    };

 
    const handleSave = (e:any) => {
        const data = { "id_pack":CustNO,"item_id": values.id, packdetail_price:packdetail_price, packdetail_qty: packdetail_qty }
        if (id === '') {
            http.post(urlrowsimage+"/", data, headerrowsimage).then(function (response: any) {
                getdata();
                setOpen(false);
                alert(response.data.message)
            })
                .catch(function (error) {
                    console.log(error);
                });

        } else {
            http.put(urlrowsimage + "/" + id, data, headerrowsimage)
                .then(function (response: any) {
                    getdata();
                    setOpen(false);
                    alert(response.data.message)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        
    };
    interface CountryType {
        id: string;
        tradname: string;
        price: string;
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        }
      })
    const handleRemoveItem = (ID: any) => {
        console.log(ID)
        swalWithBootstrapButtons.fire({
            title: '',
            text: "คุณต้องการลบรายการนี้หรือไม่",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
                setData(data2.filter(item => item.ID !== ID))
                http.delete(urlrowsimage+ "/" +  ID, headerrowsimage)
                    .then(function (response: any) {
                        setOpensnack(true);
                        getdata();
                        swalWithBootstrapButtons.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                          )
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
            ) {
              
            }
          })
    }
    const onChangeHandlelistitem = async (value:any) => {
        await http.get("drug/bysearchname?limit=5&offset=0&query=&qname="+value, header).then(json => {
         setItemlist(json.data.items);
         })
     };
    return (
        <React.Fragment>
          
                <List>
                    <Typography className={classes2.title} variant="h6" id="tableTitle" component="div">
                    </Typography>
                </List>
                <Grid container spacing={3}>
                    <Grid item xs={12} >

                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes2.flexContainer2}>
                            <Button variant="contained" color="primary" onClick={(e) => {
                                handleGetpresent(e)
                            }} >
                                <AddIcon /> เพิ่มข้อมูล</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} >
                    <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="center">Order</TableCell>
                            <TableCell align="center">Amount</TableCell>
                            <TableCell align="center">Price</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: any, index: any) => (
                            <TableRow key={row.packdetail_id}>
                                <TableCell align="center"><div>{index+1}</div></TableCell>
                                <TableCell align="center"><div>{row.type_item}</div></TableCell>
                        <TableCell align="center"><div>{row.tradname} {row.strength} {row.dosage}</div></TableCell>
                                <TableCell align="center"><div>{row.packdetail_qty}</div></TableCell>
                                <TableCell align="center"><div>{row.packdetail_price}</div></TableCell>
                                <TableCell align="center">
                                    <Fab color="secondary" className={classes2.fabGreen} onClick={() => {
                                        setOpen(true);
                                        
                                            if (Object.keys(countries).length > 0 && countries.length > 0) {
                                                let i = 0;
                                                for (const item of countries) {
                                                    
                                                  if (item.id === row.item_id) {
                                                  
                                                   setValues(countries[i]);
                                                  
                                                  }
                                                  i++;
                                                }
                                            }
                                            setCustNO(IDCNO.IDCNO)
                                            setPackdetail_price(row.packdetail_price);
                                            setPackdetail_qty(row.packdetail_qty);
                                            setId(row.packdetail_id);
                                            setPackdetail_id(row.packdetail_id);
                                      

                                    }} size="small" aria-label="edit">  <EditIcon /></Fab>
                                    <Fab color="secondary" size="small" aria-label="edit">
                                        <DeleteIcon onClick={() => handleRemoveItem(row.packdetail_id)} />
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            </Grid>
            <Dialog onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="max-width-dialog-title" open={open} >
                <DialogTitle id="customized-dialog-title" >
                    เพิ่มข้อมูล
                    </DialogTitle>
                <DialogContent >
                    <Grid container spacing={1}>
                    <input
                                type="hidden"
                                name="id"
                                id="id"
                                value={id}
                            />
                            <input
                                type="hidden"
                                id="packdetail_id"
                                name="packdetail_id"
                                value={packdetail_id}
                            />
                            <input
                                type="hidden"
                                name="CustNO"
                                id="CustNO"
                                value={CustNO}
                              
                            />
                            <input
                                type="hidden"
                                name="indexid"
                                id="indexid"
                            />
                            
                        <Grid item xs={4}>
                       <Autocomplete
                         onChange={(event: any, newValue: any) => {
                             console.log(newValue)
                             if(newValue !== null){
                                setValues(newValue);
                                setPackdetail_qty(newValue.qty);
                                setPackdetail_price(newValue.price);
                             }
                          }}
      id="item_id"
      value={values}
      style={{ width: 300 }}
      options={countries as CountryType[]}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      getOptionLabel={(option) => option.displayName}
      getOptionSelected={(option, value) => option.tradname === value.tradname}
     
      renderInput={(params) => (
        <TextField
          {...params}
          label="Order"
          variant="outlined"
          onChange={ev => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandlelistitem(ev.target.value);
            }
          }}
        />
      )}
    /> 
                        </Grid>
                        <Grid item xs={4}>
                        <TextField
                                variant="outlined"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    setPackdetail_qty(e.target.value)
                                }}
                                value={packdetail_qty}
                                label="จำนวน"
                                name="packdetail_qty"
                                autoFocus
                            />
                            </Grid>
                            <Grid item xs={4}>
                        
                             <TextField
                                       name="packdetail_price"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value);
                                        setPackdetail_price(e.target.value)
                                    }}
                                       
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={packdetail_price}
                                       id="packdetail_price"
                                       label="ราคา"
                                       
                                   />
                            </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}