import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import {useHistory, useRouteMatch} from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
      },
}))
export default function MenuHeader() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const history = useHistory();

    const changeMenu = (viewPath:any) => {
        history.push(viewPath);
       // props.onChange();
    }
    const Logout = ()=>{
        localStorage.removeItem("token");
        localStorage.removeItem("fullname");
        localStorage.removeItem("bran_id");
        history.push({
          pathname: `/login`,
         // state: { userAnswers },
        });
    }
    return (
        <React.Fragment> 
            <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <MoreVertIcon  />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeMenu('/druguse')}>วิธีการใช้ยา</MenuItem>
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={Logout}>Logout</MenuItem>
      </Menu>
      </React.Fragment>
    )
}