import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from './event-utils';
import Header from '../home/Header'
import { useHistory } from 'react-router-dom';
import useSafeFunction from "../usermanage/useSafeFunction";
import axios from 'axios';
import moment from "moment";
import {calAge,thDate,calMonth,calDay} from '../../Function';
import http from "../../http-common";
import  MenuHeader from "../home/MenuHeader"
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
const initialState = {
  items: [],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function Listappoint() {
  const history = useHistory();
  
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [weekendsVisible,setWeekendsVisible] = React.useState(true);
  const [events,setEvents] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleEventClick = (clickInfo: EventClickArg) => {
    console.log(clickInfo)
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }
  }
  const handleEvents = (events: EventApi[]) => {
    // setEvents(events);
  }
  const handleDateclick = (e:any)=>{
    setSelectedDate(e.dateStr);
    
dispatch({ type: "fetch_start" });
http.get('appoint/bydate?visit_date='+e.dateStr+'&limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=', header).then(json => {
  const { items, totalRecords } = json.data
  dispatch({ type: "fetch_success", payload: { items, totalRecords } });
  console.log(items)
}).catch(function (error) {
  history.replace({
    pathname: `/login`,
  })
})
  }
  const handleWeekendsToggle = () => {
  
  }
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  const dispatch = useSafeFunction($dispatch);
  
  useEffect(() => {
    dispatch({ type: "fetch_start" });
    http.get('appoint/bydate?visit_date='+moment().format('YYYY-MM-DD')+'&limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=', header).then(json => {
      const { items, totalRecords } = json.data
      dispatch({ type: "fetch_success", payload: { items, totalRecords } });
     setEvents(json.data.rsalls);
      console.log(json.data.rsalls)
    }).catch(function (error) {
      history.replace({
        pathname: `/login`,
      })
    })
  }, [])
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    console.log(selectInfo)
    // let title = prompt('Please enter a new title for your event')
    // let calendarApi = selectInfo.view.calendar

    // calendarApi.unselect() // clear date selection

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   })
    // }
  }

  
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const printAppoint = async(vn:any,ptid:any,idapp:any)=>{
    window.open("http://45.32.107.235/report/appoint.php?vn="+vn+"&pt_id="+ptid+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id")+"&id_app="+idapp);
  }
  return (
    <div className={classes.root}>
       
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
        <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
         <MenuHeader/>
        </Toolbar>
       
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={9}>
               {appContext.patient}
               <Card >
               <CardHeader
      
       
      titleTypographyProps={{variant:'subtitle1' }}
      title="Appointment"
    />
  
      <CardContent>
        
      <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={weekendsVisible}
            initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
            select={handleDateSelect}
            events={events}
            dateClick={handleDateclick}
            //eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
     
    </CardContent>
    </Card>
            </Grid>
            <Grid item xs={3}>
            <Card className={classes.root}>
      <CardContent>
        <Typography align="left" gutterBottom>
        รายการนัดวันที่ {thDate(moment(selectedDate).add(543, 'year').format('YYYY-MM-DD'))}
          </Typography>
          </CardContent>
          </Card>
          <br/>

          {state.items.map((row: any, index: any) => {
             
             return (
              <div key={row.id_app}>
          <Card  className={classes.root}>
      <CardContent>
        <Typography align="left" gutterBottom>
             {row.pt_prefix_id} {row.pt_firstname} {row.pt_lastname} <br/> เวลานัด {row.nexttime}  : <br/> เบอร์โทร : {row.pt_phone} <br/>นัดมา :{row.note}
             <br/>
             <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          {
            printAppoint(row.vn,row.visit_pt_id,row.id_app)
          }}}
      >
        พิมพ์รายการนัด
      </Button>

          </Typography>
          </CardContent>
          </Card>
          <br></br>
          </div>
             )
          }
          )}
              </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}








function renderEventContent(eventInfo:any) {
return (
  <>
    {/* <b>{eventInfo.timeText}</b> */}
    <i>{eventInfo.event.title}</i>
  </>
)
}

function renderSidebarEvent(event:any) {
return (
  <li key={event.id}>
    <b>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</b>
    <i>{event.title}</i>
  </li>
)
}