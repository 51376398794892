import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import http from "../../http-common";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
const drawerWidth = 240;
moment.locale("th");
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
}));

export default function FormR(props:any) {
    const classes = useStyles()
    
    const {opendialogeditdrug,rowsdata,editdata} = props;
    const [itemlist,setItemlist]= React.useState([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
    const [drug,setDrug] = React.useState([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
    const [iddata,setIddata] = React.useState("");
    const [druguselist,setDruguselist] = React.useState([{drugusage: "",code:""}]);
    const [drugunitlist,setDrugunitlist] = React.useState([{id_unit: "",name_unit:""}]);
    const [drugunit,setDrugunit] = React.useState([{id_unit: "",name_unit:""}]);
    const [drugusage,setDrugusage] = React.useState([{drugusage: "",code:""}]);
    const [order_qty,setOrder_qty] = React.useState("");
    const [order_price,setOrder_price] =  React.useState("");
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [drugselect,setDrugselect]= React.useState([{tradname: "",strength:"",dosage:"",type_item:"",id:""}]);
    const [order_tran_pay,setOrder_tran_pay]= React.useState("");
    const handleClosedialog = ()=>{
        props.handleClosedialog();
    }
    useEffect(() =>{
      setIddata("")
        if(opendialogeditdrug !== true){
          
            setOrder_qty("")
            setDrugusage([{drugusage: "",code:""}])
            setOrder_price("")
            setDrugunitlist([{id_unit: "",name_unit: ""}])
            setDrugunit([{id_unit: "",name_unit: ""}])
            setDruguselist([{drugusage: "",code: ""}]);
            setDrugusage([{drugusage: "",code: ""}]);
            setDrug([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
        }
        if(editdata.order_qty){
          setOrder_qty(editdata.order_qty)
          setOrder_price(editdata.order_price)
          const sumprice2 = Number(editdata.order_qty) * Number(editdata.order_price);
          setOrder_tran_pay(String(sumprice2));
           http.get("drug/byid?id="+editdata.order_tran_code, header).then(json => {
            setItemlist(json.data.items);
            setDrug(json.data.items)
            })
            setIddata(editdata.id)
          if(editdata.order_type == 'DRUG'){
            setDrugunitlist([{id_unit: editdata.order_unit,name_unit:editdata.name_unit}])
            setDrugunit([{id_unit: editdata.order_unit,name_unit:editdata.name_unit}])
            setDruguselist([{drugusage: editdata.order_tran_drug_use,code:editdata.code}]);
            setDrugusage([{drugusage: editdata.order_tran_drug_use,code:editdata.code}]);
          }else{
            setDrugunitlist([{id_unit: "",name_unit: ""}])
            setDrugunit([{id_unit: "",name_unit: ""}])
            setDruguselist([{drugusage: "",code: ""}]);
            setDrugusage([{drugusage: "",code: ""}]);
          }
          
        }else{
            setOrder_qty("")
            setDrugusage([{drugusage: "",code:""}])
            setOrder_tran_pay("");
            setOrder_price("")
            setDrugunitlist([{id_unit: "",name_unit: ""}])
            setDrugunit([{id_unit: "",name_unit: ""}])
            setDruguselist([{drugusage: "",code: ""}]);
            setDrugusage([{drugusage: "",code: ""}]);
            setDrug([{id:"",tradname:"",strength:"",dosage:"",drugusage:"",price:"" ,price1:"",dosagecount1:""}]);
        }
     
    },[opendialogeditdrug,editdata])
    const handleSave = (e:any) => {
        const data = {
            ptid:rowsdata.ptid,
            order_tran_pay:Number(order_qty) * Number(order_price),
            order_tran_visit_id:rowsdata.id,
            order_name:drugselect[0].tradname +' '+ drugselect[0].strength +' '+drugselect[0].dosage,
            order_qty:order_qty,
            order_price:order_price,
            order_tran_status:1,
            order_type:drugselect[0].type_item,
            order_tran_code:drugselect[0].id,
            order_tran_drug_use:drugusage[0].drugusage,
            order_unit:drugunit[0].id_unit
           }
           if(iddata){
            http.put( "order/bydatanew/"+iddata,data, header).then(json => {
              props.handleClosedialog();
             });
           }else{
            http.post( "order/",data, header).then(json => {
              props.handleClosedialog();
             });
           }
          
            }
    const onChangeHandlelistitem = async (value:any) => {
         await http.get("drug/bysearchname?limit=5&offset=0&query=&qname="+value, header).then(json => {
          setItemlist(json.data.items);
          })
      };
    const onSelectdrug = async (value:any)=>{
        setDrugselect([{tradname: value.tradname,strength:value.strength,dosage:value.dosage,type_item:value.type_item,id:value.id}])
        setOrder_qty(value.qty1);
        setOrder_price(value.price1);
        setOrder_tran_pay(value.price1)
        if(value.drugusage){
            await http.get("drug/byiddruguse?id="+value.drugusage, header).then(json => {
                setDrugusage(json.data.items);
                setDruguselist(json.data.items)
                })
            }else{
                setDrugusage([{drugusage: "",code:""}])
            }

        if(value.id_unit){
            await http.get("drug/unitbyid?id="+value.id_unit, header).then(json => {
                setDrugunit(json.data.items);
                setDrugunitlist(json.data.items)
                })
        }else{
            setDrugunit([{id_unit: "",name_unit:""}]);
        }
    }

    const onChangeHandlelistitemdruguse = async (value:any) => {  
         await http.get("drug/druguselist?limit=5&offset=0&query=&qname=", header).then(json => {
          setDruguselist(json.data.items);
          })
      };
    const onChangeHandlelistitemdrugunit  = async (value:any) => {  
        await http.get("drug/unitlist?limit=5&offset=0&query=&qname=", header).then(json => {
            setDrugunitlist(json.data.items);
         })
     };
     const onCalprice = async (value:any)=>{
         const sumprice = Number(value) * Number(order_price);
        setOrder_tran_pay(String(sumprice));
     };
    const form = (
        <React.Fragment>
             <form onSubmit={e => {
    handleSave(e)
}}>
<Dialog fullWidth={true} maxWidth="md" open={opendialogeditdrug} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">เพิ่มรายการ/แก้ไขรายการ</DialogTitle>
<DialogContent>
<Grid container spacing={2}>
    <Grid item xs={12} sm={12}>
    <Autocomplete
    value={drug[0]}
              id="searchdrug"
              onChange={(event: any, newValue: any) => {
                if(newValue != null){
                  onSelectdrug(newValue)
                }
              }}
                getOptionSelected={(option, value) => option.id == value.id}
                getOptionLabel={(option) => option.tradname +" "+ option.strength+" "+ option.dosage + " ราคา " +option.price1+" บาท/"+option.dosagecount1+""}
                options={itemlist}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ค้นหารายการ"
                  variant="outlined"
                  onChange={ev => {
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== "" || ev.target.value !== null) {
                      onChangeHandlelistitem(ev.target.value);
                    }
                  }}
                />
              )}
            />
    </Grid>

<Grid item xs={3} sm={3}>
           <TextField
           value={order_qty}
                   name="order_qty"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   onChange={(e) => {
                    const value = e.target.value;
                    setOrder_qty(value);
                    onCalprice(value);
                   }}
                 
                   aria-readonly={true}
                   id="order_qty"
                   label="จำนวน"
                   autoFocus
                 
               />
</Grid>
<Grid item xs={3} sm={3}>
           <TextField
           value={order_price}
                   name="order_price"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   onChange={(e) => {
                    const value = e.target.value;
                    setOrder_price(value)
                   }}
                   aria-readonly={true}
                   id="order_price"
                   label="ราคาต่อหน่วย"
               />
</Grid>
<Grid item xs={3} sm={3}>
<Autocomplete
value={drugunit[0]}
              id="order_unit"
              onChange={(event: any, newValue: any) => {
                if(newValue != null){
                    setDrugunit([{id_unit: newValue.id_unit+"",name_unit:newValue.name_unit+""}]);
                }
              }}
             getOptionSelected={(option, value) => option.id_unit == value.id_unit}
                getOptionLabel={(option) => option.name_unit +" "}
                options={drugunitlist}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="หน่วย"
                  variant="outlined"
                  onChange={ev => {
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== "" || ev.target.value !== null) {
                     onChangeHandlelistitemdrugunit(ev.target.value);
                    }
                  }}
                />
              )}
            />
      
</Grid>

<Grid item xs={3} sm={3}>
           <TextField
           value={order_tran_pay}
                   name="order_tran_pay"
                   variant="outlined"
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   onChange={(e) => {
                    const value = e.target.value;
                    setOrder_tran_pay(value)
                   }}
                   aria-readonly={true}
                   id="order_tran_pay"
                   label="ราคารวม"
                 
                   
                 
               />
</Grid>

<Grid item xs={12} sm={12}>
<Autocomplete
value={drugusage[0]}
              id="searchdruguse"
              onChange={(event: any, newValue: any) => {
                if(newValue != null){
                    setDrugusage([{drugusage: newValue.drugusage+"",code:newValue.code+""}]);
                }
              }}
             getOptionSelected={(option, value) => option.code == value.code}
                getOptionLabel={(option) => option.code +" "}
                options={druguselist}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="วิธีกิน"
                  variant="outlined"
                  onChange={ev => {
                    // dont fire API if the user delete or not entered anything
                    if (ev.target.value !== "" || ev.target.value !== null) {
                     onChangeHandlelistitemdruguse(ev.target.value);
                    }
                  }}
                />
              )}
            />
</Grid>
<Grid item xs={12} sm={12}>
  
</Grid>
</Grid>
</DialogContent>
<DialogActions>
<Button variant="contained" onClick={handleSave} color="primary">
บันทึกข้อมูล
</Button>
<Button variant="contained" onClick={handleClosedialog} color="secondary">
Cancel
</Button>
</DialogActions>
</Dialog>
</form>
        </React.Fragment>
    );
    return (
        <div className={classes.root}>
        {form}
        </div>
      );
}