import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
export default function CertificationForm(props:any) {
    const classes = useStyles();
    const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
    const [opdscreen, setOpdscreen]= React.useState<any>([]);
    const {initData, saveCallback,initOpdscreen,initPatient,opendialogappointform,id} = props;
    const [visitdate, setVisitdate] = React.useState('');
    const [visit_time, setVisit_time] = React.useState('');
    const [vn, setVn] = React.useState('');
    const [opendialog, setOpendialog] = React.useState(false);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [note,setNote] = React.useState('');
    const [pt_id,setPt_id] = React.useState('');
    const [cer1_bookrun,setCer1_bookrun]= React.useState('');
    const [cer1_numberrun,setCer1_numberrun]= React.useState('');
    const [cer1_locationcheck,setCer1_locationcheck]= React.useState('');
    const [cer1_doctorname,setCer1_doctorname]= React.useState('นายแพทย์ณัฐวัฒน์ หลาบสีดา');
    const [cer1_patition,setCer1_patition]= React.useState('อายุรแพทย์ ประจำคลีนิค');
    const [cer1_license,setCer1_license]= React.useState('53602');
    const [cer1_locationname,setCer1_locationname]= React.useState('');
    const [cer1_addr,setCer1_addr]= React.useState('');
    const [cer1_fullname,setCer1_fullname]= React.useState('');
    const [cer1_width,setCer1_width]= React.useState('');
    const [cer1_height,setCer1_height]= React.useState('');
    const [cer1_bp,setCer1_bp] = React.useState('');
    const [cer1_pulse,setCer1_pulse] = React.useState('');
    const [cer1_body,setCer1_body]= React.useState("1");
    const [cer1_bodydetail,setCer1_bodydetail]=React.useState('');
    const [cer1_approve,setCer1_approve]=React.useState('');
    const [cer1_approve2,setCer1_approve2]=React.useState('');
    const [cer1_date, setCer1_date] = React.useState<Date | null>(
      new Date(),
  );
  const [cer1_date2,setCer1_date2]= React.useState<Date | null>(
    new Date(),
);
    const handleClosedialog = () => {
      props.handleClosedialogapppint();
    };
      React.useEffect(() => {
        if(opendialogappointform){

       
        http.get("bran/byid?id=1", header).then(json => {
            json.data.items.map((rowbran: any, indexbran: any) => {
                setCer1_locationcheck(rowbran.bran_fullname);
                setCer1_locationname(rowbran.bran_fullname);
                setCer1_addr(rowbran.bran_addr);
                setCer1_bookrun(rowbran.bran_cer1_booknumber);
                const runnum = parseInt(rowbran.bran_cer1_runnumber) + 1;
                setCer1_numberrun(String(runnum));
            })
          })
        
        setPt_id(initPatient.id);
        setCer1_fullname(initPatient.pt_prefix_id+initPatient.pt_firstname+" "+initPatient.pt_lastname);
        if(id !== ""){
            http.get("cer1/byid?id="+id, header).then(jsoncer => {
            console.log(jsoncer)
                jsoncer.data.items.map((rowcer: any, indexcer: any) => {
                    setCer1_bookrun(rowcer.cer1_bookrun);
                    setCer1_numberrun(rowcer.cer1_numberrun);
                    setPt_id(rowcer.cer1_ptid);
                    setVn(rowcer.cer1_vn);
                    setVisitdate(rowcer.visit_date);
                    setCer1_locationcheck(rowcer.cer1_locationcheck);
                    setCer1_doctorname(rowcer.cer1_doctorname);
                    setCer1_patition(rowcer.cer1_patition);
                    setCer1_license(rowcer.cer1_license);
                    setCer1_locationname(rowcer.cer1_locationname);
                    setCer1_addr(rowcer.cer1_addr);
                    setCer1_fullname(rowcer.cer1_fullname);
                    setVisit_time(rowcer.cer1_time);
                    setCer1_width(rowcer.cer1_width);
                    setCer1_height(rowcer.cer1_height);
                    setCer1_bp(rowcer.cer1_bp);
                    setCer1_pulse(rowcer.cer1_pulse);
                    setCer1_body(rowcer.cer1_body);
                    setCer1_bodydetail(rowcer.cer1_bodydetail);
                    setCer1_approve(rowcer.cer1_approve);
                    setCer1_approve2(rowcer.cer1_approve2);
                      const cer1_dates = new Date(rowcer.cer1_date);
                      setCer1_date(cer1_dates);
                      const cer1_date2s = new Date(rowcer.cer1_date2);
                      setCer1_date2(cer1_date2s);
                })
            })
         // })
        }else{
          initOpdscreen.map((row: any, index: any) => {
            setVisitdate(row.visit_date);
            setVisit_time(row.visit_time);
            setVn(row.vn);
            setCer1_width(row.width);
            setCer1_height(row.height);
            setCer1_bp(row.bps+"/"+row.bpd);
            setCer1_pulse(row.pulse);
            setCer1_body("1");
            setCer1_bodydetail("");
            setCer1_approve("");
            setCer1_approve2("");
          })
        }
      }
       
      }, [initData,initOpdscreen,initPatient,id,opendialogappointform]);
      async function onGenderChange(event:any){
     if(event !== null){
        setCer1_body(event.target.value);
     }
    }

   
    const { register: registersave, getValues, handleSubmit: handleSubmitsave, setValue: setValuesave, control, setError } = useForm();
    const handleSave = (e:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        const cer1_date_save = moment(cer1_date, "DD-MM-YYYY");
        const cer1_date2_save = moment(cer1_date2, "DD-MM-YYYY");
        const tempData = initData || [{}];
        const data = {
            cer1_vn:vn,
            visit_date:visitdate,
            cer1_ptid:pt_id,
            cer1_bookrun:cer1_bookrun,
            cer1_numberrun:cer1_numberrun,
            cer1_locationcheck:cer1_locationcheck,
            cer1_doctorname:cer1_doctorname,
            cer1_patition:cer1_patition,
            cer1_license:cer1_license,
            cer1_locationname:cer1_locationname,
            cer1_addr:cer1_addr,
            cer1_date:cer1_date_save,
            cer1_fullname:cer1_fullname,
            cer1_date2:cer1_date2_save,
            cer1_width:cer1_width,
            cer1_height:cer1_height,
            cer1_bp:cer1_bp,
            cer1_pulse:cer1_pulse,
            cer1_body:cer1_body,
            cer1_bodydetail:cer1_bodydetail,
            cer1_approve:cer1_approve,
            cer1_approve2:cer1_approve2,
            cer1_time:visit_time
        }
        http.post('cer1?cer1_id='+id,data, header).then(json => {
        
            props.handleClosedialogapppint();
              Swal.fire(
                  json.data.message,
                  '',
                  'success'
                );
          });
    };
    const handleDateChange = (date: Date | null) => {
        setCer1_date(date);
    };
    const handleDateChange2 = (date: Date | null) => {
        setCer1_date2(date);
    };
   return ( 
    <React.Fragment>
     
     <form onSubmit={e => {
                        handleSave(e)
                    }}>
     <Dialog fullWidth={true} maxWidth="md" open={opendialogappointform} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">ใบรับรองแพทย์</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={2} sm={2}>
                               <TextField
                                       name="cer1_bookrun"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setCer1_bookrun(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={cer1_bookrun}
                                       id="cer1_bookrun"
                                       label="เล่มที่"
                                       
                                   />
       </Grid>
       <Grid item xs={2} sm={2}>
                               <TextField
                                       name="cer1_numberrun"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_numberrun(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_numberrun}
                                       id="cer1_numberrun"
                                       label="เลขที่"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                                       name="cer1_locationcheck"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_locationcheck(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_locationcheck}
                                       id="cer1_locationcheck"
                                       label="สถานที่ตรวจ"
                                       
                                     
                                   />
                    </Grid>
              
     <Grid item xs={4} sm={4}>
             <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                         <DatePicker
                             id="cer1_date"
                             label="วันที่"
                             name="cer1_date"
                             format="DD/MM/YYYY"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             value={cer1_date}
                             yearOffset={543}
                             onChange={(e:any) => {
                               if(e !== null){
                                 console.log(e);
                                handleDateChange(e)
                               }
                             }
                             }
                             inputVariant="outlined"
                         
                         />
</MuiPickersUtilsProvider>
</Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                                       name="cer1_doctorname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_doctorname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_doctorname}
                                       id="cer1_doctorname"
                                       label="ข้าพเจ้า"
                                       autoFocus
                                     
                                   />
                              
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer1_patition"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_patition(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_patition}
                                       id="cer1_patition"
                                       label="ตำแหน่ง"
                                       autoFocus
                                     
                                   />
       </Grid>
      
       <Grid item xs={4} sm={4}>
       
       <TextField
                                       name="cer1_license"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_license(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_license}
                                       id="cer1_license"
                                       label="ใบอนุญาตประกอบเลขที่"
                                       autoFocus
                                     
                                   />
                                
       </Grid>
       <Grid item xs={4} sm={4}>
       <TextField
                                       name="cer1_locationname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_locationname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_locationname}
                                       id="cer1_locationname"
                                       label="สถานพยาบาลชื่อ"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={7} sm={7}>
       <TextField
                                       name="cer1_addr"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_addr(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_addr}
                                       id="cer1_addr"
                                       label="ที่อยู่"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={6} sm={6}>
       <TextField
                                       name="cer1_fullname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_fullname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_fullname}
                                       id="cer1_fullname"
                                       label="ได้ตรวจร่างกาย"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
             <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                         <DatePicker
                             id="cer1_date2"
                             label="เมื่อวันที่"
                             name="cer1_date2"
                             format="DD/MM/YYYY"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             value={cer1_date2}
                             yearOffset={543}
                             onChange={(e:any) => {
                               if(e !== null){
                                 console.log(e);
                                 handleDateChange2(e)
                               }
                             }
                             }
                             inputVariant="outlined"
                         
                         />
</MuiPickersUtilsProvider>
</Grid>
<Grid item xs={3} sm={3}>
       <TextField
                                       name="cer1_width"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_width(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_width}
                                       id="cer1_width"
                                       label="น้ำหนักตัว"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer1_height"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_height(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_height}
                                       id="cer1_height"
                                       label="ความสูง"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer1_bp"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_bp(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_bp}
                                       id="cer1_bp"
                                       label="ความดันโลหิต"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer1_pulse"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_pulse(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer1_pulse}
                                       id="cer1_pulse"
                                       label="ชีพจร"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <FormLabel component="legend">สภาพร่างกายทั่วไป</FormLabel>
                            <RadioGroup row={true}  aria-label="cer1_body" value={cer1_body} name="cer1_body"  
                              onChange={(e:any) => {
                                setCer1_body(e.target.value);
                                console.log(cer1_body)
                            }}
                            >
                               <FormControlLabel value="1" control={<Radio />} label="ปกติ" />
                                <FormControlLabel value="2" control={<Radio />} label="ผิดปกติ" />
                            </RadioGroup>
                            </Grid>
                            <Grid item xs={8} sm={8}>
       <TextField
                                       name="cer1_bodydetail"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_bodydetail(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer1_bodydetail"
                                       label="ผิดปกติระบุ"
                                       value={cer1_bodydetail}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="cer1_approve"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_approve(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer1_approve"
                                       label="ขอรับรองว่า"
                                       multiline
                                       rows={3}
                                       value={cer1_approve}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="cer1_approve2"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer1_approve2(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer1_approve2"
                                       label="สรุปความเห็นและข้อแนะนำของแพทย์"
                                       multiline
                                       rows={3}
                                       value={cer1_approve2}
                                   />
       </Grid>
     </Grid>
   </DialogContent>
   <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>
 </form>
 </React.Fragment>
   );
}