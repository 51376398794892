import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header';
import MenuHeader from '../home/MenuHeader'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import moment from "moment";
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import http from "../../http-common";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const initialState = {
  items: [],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function Packgate() {
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
 
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [deleteid, setDeleteid] = useState(null);
  const [message, setMessage] = React.useState("");
  const [opensnack, setOpensnack] = React.useState(false);
  const [opencf, setOpencf] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const url =  'http://127.0.0.1:4000/v1/packgate?limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=';
  const urlassettbs = 'http://127.0.0.1:4000/v1/packgate/'
  useEffect(() => {
    dispatch({ type: "fetch_start" });
    http.get('packgate?limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=', header).then(json => {
      const { items, totalRecords } = json.data
      dispatch({ type: "fetch_success", payload: { items, totalRecords } });
    //  console.log(items)
    }).catch(function (error) {
      history.replace({
        pathname: `/login`,
      })
    })
  }, [])
  const handleClickOpendialog = ()=>{
    history.replace({
      pathname: `/packgateform`,
    })
  }
  const handleClosesnack = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    setOpensnack(false);
  };
  const DialogContent = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme: Theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
 
  const handleClosecf = () => {
    setOpencf(false);
  };
   const handleDisagreecf = () => {
    setOpencf(false);
  };
   const handleAgreecf = () => {
    setOpencf(false);
    http.delete("packgate/"+ deleteid,header)
      .then(function (response: any) {
          dispatch({ type: "fetch_start" });
          http.get('packgate?limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=', header).then(json => {
            const { items, totalRecords } = json.data
            dispatch({ type: "fetch_success", payload: { items, totalRecords } });
          }).catch(function (error) {
            history.replace({
              pathname: `/login`,
            })
          })
        setMessage("ลบรายการสำเร็จ")
        setOpensnack(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleRemoveItem = (id: any) => {
    setOpencf(true);
    setDeleteid(id);
  }
  const handleClickeditOpen = (editid: any) => {
    history.push({
      pathname: `packgateformedit/` + editid
    });
  }
  return (
    <div className={classes.root}>
       
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
        <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <MenuHeader/>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
               {appContext.patient}
               <Card >
               <CardHeader
      
       
      titleTypographyProps={{variant:'subtitle1' }}
      title="packgate"
    />
  
      <CardContent>
        <div  style={{textAlign: 'left'}}>
      <Button variant="contained" onClick={handleClickOpendialog}   style={{textAlign: 'left'}} color="primary">เพิ่มข้อมูล <AddIcon/></Button>
      <br/>
      <br/>
      </div>
      <TableContainer component={Paper}>
      <Table stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">ชื่อ packgate</TableCell>
            <TableCell align="center">ประเภท</TableCell>
            <TableCell align="center">ผู้สร้าง</TableCell>
            <TableCell align="center">สร้างวันที่</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {state.items.map((row: any, index: any) => {
             
             return (
            <TableRow key={row.id}>
              <TableCell align="center">{index+1}</TableCell>
              <TableCell align="center">{row.name_pack}</TableCell>
              <TableCell align="center">{row.public}</TableCell>
              <TableCell align="center">{row.asing_to}</TableCell>
              <TableCell align="center"> {thDate(moment(row.create_date).add(543, 'year').format('YYYY-MM-DD'))}</TableCell>
              <TableCell align="center"><Button onClick={() => {
                          {
                            handleClickeditOpen(row.id_pack)
                          }
                        }} variant="outlined" style={{margin:'2px',maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
                
                <EditIcon/>
              </Button><Button variant="outlined" onClick={() => handleRemoveItem(row.id_pack)} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="secondary" >
                      <DeleteIcon/>
                    </Button></TableCell>
            </TableRow>
          )
             })}
        </TableBody>
      </Table>
    </TableContainer>
    </CardContent>
    </Card>
    <Dialog
        open={opencf}
        onClose={handleClosecf}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText >
            คุณต้องการลบรายการนี้หรือไม่
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagreecf} color="secondary" >
            ยกเลิก
            </Button>
          <Button onClick={handleAgreecf} startIcon={<DeleteIcon />} color="primary" autoFocus>
            ยืนยันการลบ
            </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={opensnack}
        onClose={handleClosesnack}
        message={message}
        autoHideDuration={3000}
      />
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}