import React, {useEffect, useRef, forwardRef, useImperativeHandle} from "react";
import CanvasDraw from "../lib/index";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BrushIcon from '@material-ui/icons/Brush';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import UndoIcon from '@material-ui/icons/Undo';
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'center',
        // maxHeight: '780px',
    },
    buttons: {
        margin: '5px',
    },
    buttonsRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));

function DrawCanvas(props, ref) {
    const classes = useStyles();
    // let saveableCanvas = null;
    const [saveableCanvas, setSaveableCanvas] = React.useState(null);
    const [enableErase, setEnableErase] = React.useState(false);
    const [enableText, setEnableText] = React.useState(false);
    const [openTextDialog, setOpenTextDialog] = React.useState(false);
    const [canvasText, setCanvasText] = React.useState('');
    const [brushSize, setBrushSize] = React.useState(3);
    const [modeshow, setModeShow] = React.useState('paint');



    useImperativeHandle(ref, () => ({
        getDrawingData() {
            return saveableCanvas.getSaveData();
        }
    }));


    useEffect(() => {

        setTimeout(() => {
         
           if (saveableCanvas != null && props.drawingData != null && props.drawingData !== '') {
                saveableCanvas.loadSaveData(
                    localStorage.getItem("savedDrawing")//props.drawingData
                );
           }

        }, 100)

    }, [saveableCanvas, props.drawingData]);
    function undoFunc () {
        return saveableCanvas.undo();
    }
    function handlesave(){
        console.log(saveableCanvas.getDrawingData())
    }
    function addTextFunc (t) {
        return saveableCanvas.addText(t);
    }
    function changeBrushSize(data) {
        if(data == '' || data == 0) {
            setBrushSize(1);
        }else {
            setBrushSize(Number(data));
        }
    }
    function valuetext(value) {
        return "BrushSize " + value;
    }

    function handleCloseDialog() {
        addTextFunc(canvasText);
        setOpenTextDialog(false);
        setCanvasText('');
    }

    function changeMode(mode){
        switch(mode) {
            case 'paint':
                setEnableErase(false);
                setEnableText(false);
                break;
            case 'eraser':
                setEnableErase(true);
                setEnableText(false);
                break;
            case 'text':
                setEnableText(true);
                setEnableErase(false);
                break;
            default:
                setEnableErase(false);
                setEnableText(false);
                break;
        }
        setModeShow(mode);
    }

    return (
            <React.Fragment>
                <CssBaseline/>
                <main className={classes.layout}>
                    <Dialog open={openTextDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
                        <form onSubmit={e => {handleCloseDialog(e);}}>
                            {/*<DialogTitle id="form-dialog-title">Text</DialogTitle>*/}
                            <DialogContent>
                                {/*<DialogContentText>*/}
                                {/*    To subscribe to this website, please enter your email address here. We will send updates*/}
                                {/*    occasionally.*/}
                                {/*</DialogContentText>*/}
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="canvas_text"
                                    label="Text"
                                    value={canvasText}
                                    onChange={(e) => {
                                        setCanvasText(e.target.value)
                                    }}
                                    type="text"
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    OK
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Paper className={classes.paper} style={{border: '2px' ,borderColor:"black"}}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <Box component='div'
                                     style={{
                                         justifyContent: 'flex-start',
                                         flexGrow: 1,
                                         display: 'flex',
                                         flexDirection: 'row',
                                         flexWrap: 'wrap',
                                         position: "relative"
                                     }}>
                                    <Box component='div'
                                         >

                                        <Button
                                            style={ modeshow == 'paint' ?  {border: 'blue 1px solid'} : {}}
                                            className={classes.buttons}
                                            size="small"
                                            disabled={modeshow == "paint"}
                                            variant="contained"
                                            color="default"
                                            onClick={() => {
                                                changeMode('paint');
                                            }}>
                                            <BrushIcon fontSize="small" />
                                            Paint
                                        </Button>
                                        <Button
                                            style={ modeshow == 'eraser' ?  {border: 'blue 1px solid'} : {}}
                                            className={classes.buttons}
                                            size="small"
                                            disabled={modeshow == "eraser"}
                                            variant="contained"
                                            color="default"
                                            onClick={() => {
                                                changeMode('eraser');
                                            }}
                                        >
                                            <Icon className="fa fa-eraser" style={{fontSize: '1.25em'}} />
                                            Eraser
                                        </Button>
                                    </Box>

                                    <Box component={'div'} style={{marginLeft: 8, marginRight: 8,display: "inline-block"}}>
                                        <Typography gutterBottom
                                                    style={{fontSize:'13px',marginBottom:'0px'}}>Brush Size</Typography>
                                        <Slider
                                            defaultValue={brushSize}
                                            getAriaValueText={valuetext}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="auto"
                                            step={1}
                                            marks
                                            min={1}
                                            max={20}
                                            style={{width:'150px'}}
                                            onChange={(e,value) => {
                                                changeBrushSize(value)
                                            }}
                                        />
                                    </Box>
                                    <Box  component='div'>
                                        <Button
                                            className={classes.buttons}
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                undoFunc()
                                            }}>
                                            <UndoIcon fontSize="small"/>
                                            Undo
                                        </Button>

                                    </Box>
                                    <Box  component='div'>
                                        <Button
                                            className={classes.buttons}
                                            size="small"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                changeMode('text');
                                            }}
                                            >
                                            {/*<UndoIcon fontSize="small"/>*/}
                                            Text
                                        </Button>

                                    </Box>
                                    <Box component='div'  style={{display: "inline-block",position:"absolute",top:10, right: 20}}
                                        // style={{
                                        //     justifyContent: 'flex-end',
                                        //     flexGrow: 1,
                                        //     display: 'flex',
                                        //     flexDirection: 'row',
                                        //     flexWrap: 'wrap'
                                    >
                                        <Button
                                            className={classes.buttonsRight}
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                if (saveableCanvas != null) {
                                                    saveableCanvas.clear()
                                                }
                                            }}>
                                            <ClearAllIcon fontSize="small"/>
                                            Clear
                                        </Button>
                                    </Box>
                                </Box>

                            </Grid>
                            <Grid item xs={12} style={{paddingLeft:'10%'}}>
                                <CanvasDraw
                                    imgSrc={props.imgSrc}
                                    immediateLoading={true}
                                    brushRadius={brushSize}
                                    brushColor={"#FE0000"}
                                    loadTimeOffset={1}
                                    canvasWidth={500}
                                    canvasHeight={500}
                                    erase={enableErase}
                                    textMode={enableText}
                                    onTextPoint={ e => {
                                        setOpenTextDialog(true);
                                    }}
                                    hideGrid={false}
                                    ref={canvasDraw => (setSaveableCanvas(canvasDraw))}
                                    onChange={() => localStorage.setItem(
                                        "savedDrawing",
                                        saveableCanvas.getSaveData()
                                      )}
                                />
                               
                             
                            </Grid>

                        </Grid>

                    </Paper>
                </main>
            </React.Fragment>
        // </Modal>
    );

}

DrawCanvas = forwardRef(DrawCanvas);
export default DrawCanvas;
