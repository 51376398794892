import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from '../home/Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';

import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaskedInput from 'react-text-mask';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from "@material-ui/core/InputAdornment";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from 'react-router-dom';

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import http from "../../http-common";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// Generate Order Data
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  

})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
function countryToFlag(isoCode:any) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char:any) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}
const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 650,
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  avatar: {
    backgroundColor: red[500],
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    }
    ,expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },expandOpen: {
      transform: 'rotate(180deg)',
    },
    title: {
      flexGrow: 1,
      backgroundColor:"#cccccc",
      paddingTop:'15px',
      paddingRight:'40px',
      paddingBottom:'15px',
      paddingLeft:'15px'
    },
    appBar: {
      position: 'relative',
    },
    title2: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
}));
export default function Patient2(props:any) {
  const classes = useStyles();
  const handleSave = (e:any) => {
    setPt_bdate(moment(selectedDate).format('YYYY-MM-DD'))
  
    const data = {
       pt_phone:pt_phone,
       pt_addr_tumbon:pt_addr_tumbon[0].DISTRICT_ID,
       pt_addr_amphur:pt_addr_amphur[0].AMPHUR_ID,
       pt_addr_province:pt_addr_province[0].PROVINCE_ID,
       pt_moi:pt_moi,
       pt_address:pt_address,
       pt_religion_id:pt_religion_id[0].religion_name,
       pt_origin_id:pt_origin_id[0].national_name,
       pt_national_id:pt_national_id[0].national_name,
       pt_sex:pt_sex,
       pt_age:pt_age,
       pt_lastname:pt_lastname,
       pt_firstname:pt_firstname,
       pt_prefix_id:pt_prefix_id[0].prefix_name,
       pt_cid:pt_cid,
       pt_occ:pt_occ[0].occ_name,
       disease:disease,
       druggallergy:druggallergy,
       pt_mstatus:pt_mstatus[0].title,
       pt_addr_zipcode:pt_addr_zipcode,
       pt_bdate:moment(selectedDate).format('YYYY-MM-DD')
 
     }
     if(pt_sex =="ชาย"){
       localStorage.setItem(
         "sex",
         "http://45.32.107.235/1074311.jpg"
       )
     }else{
       localStorage.setItem(
         "sex",
         "http://45.32.107.235/woman.png"
       )
     }
     http.post("patient/save2", data, header)
     .then(function (response: any) {
         if(response.data.ok){
           props.handleCloseOpendia();
             Swal.fire(
               response.data.message,
               '',
               'success'
             );
            if(response.data.message ==="เพิ่มข้อมูลสำเร็จ"){
             setPt_prefix_id([{prefix_name:""}]);
             setPt_firstname("");
             setPt_lastname("")
             setPt_bdate("");
             setPt_cid("")
             setPt_phone("")
             setDruggallergy("")
             setDisease("")
             setPrexfixshow("");
             setPt_age("")
             setPt_sex("");
             setPt_national_id([{national_name:""}]);
             setpPt_origin_id([{national_name:""}])
             setPt_religion_id([{religion_name:""}]);
             setPt_mstatus([{title:""}]);
             setPt_occ([{occ_name:""}])
             setPt_address("");
             setPt_moi("");
             setSelectedDate(new Date());
             setPt_addr_province([{PROVINCE_ID:"",PROVINCE_NAME:""}])
             setPt_phone("")
            }
         }else{
             alert(response.data.message)
         }
         
     })
  }
  const calageyear = (e:any)=>{
    const startDate = moment(selectedDate);
    const timeEnd = moment(new Date());
    const diff = timeEnd.diff(startDate);
    const diffDuration = moment.duration(diff);
    // console.log(diffDuration.minutes)
    setPt_age(diffDuration.years())
  }
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([{prefix_name:""}]);
  const loading = open && options.length === 0;
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const {initData, saveCallback,visitpt,id} = props;
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const [patient, setPatient]= React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
  const [pt_hn,setPt_hn] = React.useState("");
  const [pt_prefix_id,setPt_prefix_id]=  React.useState<any[]>([{prefix_name:""}]);
  const [pt_mstatus,setPt_mstatus] =  React.useState<any[]>([{title:""}]);
  const [pt_firstname,setPt_firstname]=  React.useState('');
  const [pt_lastname,setPt_lastname]=  React.useState('');
  const [pt_bdate,setPt_bdate]=  React.useState('');
  const [pt_cid,setPt_cid] =  React.useState('');
  const [pt_phone,setPt_phone]=  React.useState('');
  const [druggallergy,setDruggallergy] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date(),
);
const [prexfixshow,setPrexfixshow] = React.useState('');
const [pt_sex, setPt_sex] = React.useState('unknow');
const [pt_age,setPt_age] = React.useState<any>('');
const [pt_national_id,setPt_national_id] = React.useState([{national_name:""}]);
const [nationallist,setNationallist] = React.useState([{national_name:""}]);
const [pt_origin_id,setpPt_origin_id] = React.useState([{national_name:""}]);
const [pt_religion_id,setPt_religion_id] = React.useState([{religion_name:""}]);
const [religionlist,setReligionlist] = React.useState<any>([]);
const [pt_occ,setPt_occ] = React.useState([{occ_name:""}]);
const [occlist,setOcclist] = React.useState<any>([]);
const [pt_address,setPt_address]= React.useState('');
const [pt_moi,setPt_moi]= React.useState('');
const [provicelist, setProvicelist] = React.useState<any>([{ PROVINCE_ID :"",PROVINCE_NAME:""}]);
const [amphurlist, setAmphur] = React.useState<any>([{AMPHUR_ID:"",AMPHUR_NAME:""}]);
const [tumbollist, setTumbollist] = React.useState<any>([{DISTRICT_CODE:"",DISTRICT_NAME:""}]);
const [pt_addr_province,setPt_addr_province]= React.useState([{PROVINCE_ID:"",PROVINCE_NAME:""}]);
const [pt_addr_amphur,setPt_addr_amphur]= React.useState([{AMPHUR_ID:"",AMPHUR_NAME:""}]);
const [pt_addr_tumbon,setPt_addr_tumbon]= React.useState([{DISTRICT_ID:"",DISTRICT_NAME:""}]);
const [pt_addr_zipcode,setPt_addr_zipcode] = React.useState('');
const [disease,setDisease] = React.useState('');
const handleChangeprovince = (e:any) => {
  http.get('patient/amphur?province_id=' + e, header).then(json => {
      setAmphur(json.data.amphur)
      setTumbollist([]);
  })
}
const handleChangeampher = (e:any) => {
  http.get('patient/distric?province_id=' + pt_addr_province[0].PROVINCE_ID + '&aumphur_id=' + e, header).then(json => {
    setTumbollist(json.data.distric);
})
}
const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const [opendialog, setOpendialog] = React.useState(false);
  const handleClosedialog = () => {
      setOpendialog(false);
    };
    function onGenderChange(event:any) {
      setPt_sex(event.target.value);
  }
  const handleCloseOpendia = () => {
    props.handleCloseOpendia();
  };
  const mstatus = [
    { title: 'โสด'},
    { title: 'สมรส'},
    { title: 'อย่าร้าง' }
  ]
  React.useEffect(() => {
    console.log(props);
    http.get("patient/province", header).then(json => {
      setOptions(json.data.prefix);
      setNationallist(json.data.nation);
      setReligionlist(json.data.religion);
      setOcclist(json.data.occ);
      setProvicelist(json.data.province);
    }).catch(function(error){
      history.replace({
        pathname: `/login`,
      })
    })
    if (Object.keys(initData).length !== 0) {
      setPt_prefix_id([{prefix_name:initData.pt_prefix_id+""}]);
      setPt_firstname(initData.pt_firstname);
      setPt_lastname(initData.pt_lastname)
      setPt_bdate(initData.pt_bdate);
      setPt_cid(initData.pt_cid)
      setPt_phone(initData.pt_phone)
      setDruggallergy(initData.druggallergy)
      setDisease(initData.disease)
      setPrexfixshow(initData.pt_prefix_id);
      setPt_age(initData.pt_age)
      setPt_sex(initData.pt_sex);
      if(initData.pt_sex =="ชาย"){
        localStorage.setItem(
          "sex",
          "http://45.32.107.235/1074311.jpg"
        )
      }else{
        localStorage.setItem(
          "sex",
          "http://45.32.107.235/woman.png"
        )
      }
      setPt_national_id([{national_name:initData.pt_national_id+""}]);
      setpPt_origin_id([{national_name:initData.pt_origin_id+""}])
      setPt_religion_id([{religion_name:initData.pt_religion_id+""}]);
      setPt_mstatus([{title:initData.pt_mstatus+""}]);
      setPt_occ([{occ_name:initData.pt_occ+""}])
      setPt_address(initData.pt_address);
      setPt_moi(initData.pt_moi);
      setSelectedDate(new Date(initData.pt_bdate));
      setPt_addr_province([{PROVINCE_ID:initData.pt_addr_province+"",PROVINCE_NAME:initData.PROVINCE_NAME}])
      setPt_phone(initData.pt_phone)
      if(initData.pt_addr_amphur !== null){
       
        http.get('patient/amphur?province_id=' + initData.pt_addr_province, header).then(jsondistrictrs => {
            setAmphur(jsondistrictrs.data.amphur)
            setPt_addr_amphur([{AMPHUR_ID:initData.AMPHUR_ID+"",AMPHUR_NAME:initData.AMPHUR_NAME}])
        })
      }
      if(initData.pt_addr_tumbon !== null){
        http.get('patient/distric?province_id=' + initData.pt_addr_province + '&aumphur_id=' + initData.pt_addr_amphur, header).then(jsonsubdistrict => {
            setTumbollist(jsonsubdistrict.data.distric);
            setPt_addr_tumbon([{DISTRICT_ID:initData.DISTRICT_ID+"",DISTRICT_NAME:initData.DISTRICT_NAME}])
            
        })
      }
      setPt_addr_zipcode(initData.pt_addr_zipcode)
      
    }else{
      setSelectedDate(new Date());
      setPt_prefix_id([{prefix_name:""}]);
      setPt_firstname("");
      setPt_lastname("")
      setPt_bdate("");
      setPt_cid("")
      setPt_phone("")
      setDruggallergy("")
      setDisease("")
      setPrexfixshow("");
      setPt_age("")
      setPt_sex("");
      setPt_national_id([{national_name:""}]);
      setpPt_origin_id([{national_name:""}])
      setPt_religion_id([{religion_name:""}]);
      setPt_mstatus([{title:""}]);
      setPt_occ([{occ_name:""}])
      setPt_address("");
      setPt_moi("");
      setPt_addr_province([{PROVINCE_ID:"",PROVINCE_NAME:""}])
      setPt_phone("")
    }
  }, [initData]);

  return (<React.Fragment><form onSubmit={e => {
    handleSave(e)
}}>
<Dialog fullWidth={true} maxWidth="md" open={props.open} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
<DialogTitle id="form-dialog-title">Patient Infomation</DialogTitle>
<DialogContent>
<Grid container spacing={2}>
<Grid item xs={12}>

<Typography className={classes.title}  gutterBottom>
ข้อมูลผู้รับบริการ
</Typography>
</Grid>
<Grid item xs={4} sm={4}>
           <TextField
                   name="pt_hn"
                   variant="outlined"
                   onChange={(e) => {
                    console.log(e.target.value)
                    setPt_hn(e.target.value)
                   
                }}
                onKeyDown={(e) => {
                  if(e.code === 'Enter'){
                    
                  //  alert('Enter... (KeyDown, use keyCode)');
                  }
                }}
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   value={pt_hn}
                   aria-readonly={true}
                   id="pt_hn"
                   label="HN"
                 
               />
</Grid>
<Grid item xs={4} sm={4}>
           <TextField
                   name="pt_cid"
                   variant="outlined"
                   onChange={(e) => {
                    console.log(e.target.value);
                    setPt_cid(e.target.value);
                }}
                   
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   aria-readonly={true}
                   value={pt_cid}
                   id="pt_cid"
                   label="CID"
                   
                 
               />
</Grid>
<Grid item xs={4} sm={4}></Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_prefix_id[0]}
id="pt_prefix_id"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_prefix_id([{prefix_name:newValue.prefix_name}]);
setPrexfixshow(newValue.prefix_name);
console.log(newValue)
}
}}
getOptionLabel={(option) => option.prefix_name + ""}
getOptionSelected={(option, value) => option.prefix_name === value.prefix_name}
options={options}
renderInput={(params) => (
<TextField
{...params}
label="คำนำหน้า"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
           <TextField
                   name="pt_firstname"
                   variant="outlined"
                   onChange={(e) => {
                    console.log(e.target.value);
                    setPt_firstname(e.target.value);
                }}
                   
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   aria-readonly={true}
                   value={pt_firstname}
                   id="pt_firstname"
                   label="ชื่อ"
                   
               />
</Grid>
<Grid item xs={4} sm={4}>
           <TextField
                   name="pt_lastname"
                   variant="outlined"
                   onChange={(e) => {
                    console.log(e.target.value);
                    setPt_lastname(e.target.value);
                }}
                   
                   fullWidth
                   InputLabelProps={{
                           shrink: true
                   }}
                   aria-readonly={true}
                   value={pt_lastname}
                   id="pt_lastname"
                   label="สกุล"
                   
               />
</Grid>
<Grid item xs={4} sm={4}>
<MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                            <DatePicker
                            
                                id="pt_bdate"
                                label="วันเกิด"
                                name="pt_bdate"
                                format="DD/MM/YYYY"
                                InputLabelProps={{
                                  shrink: true,
                              }}
                              value={selectedDate}
                              yearOffset={543}
                            //  onChange={handleDateChange}
                            onChange={(newDate:any) => {
                              setSelectedDate(newDate)
      
                            }} 
                              inputVariant="outlined"
                            
                            />
</MuiPickersUtilsProvider>
<Button  color="primary" onClick={calageyear}>
คำนวนอายุ
</Button>         
</Grid>
<Grid item xs={4} sm={4}>
<TextField
                        name="pt_age"
                        variant="outlined"
                        onChange={(e) => {
                          console.log(e.target.value);
                          setPt_age(e.target.value);
                      }}
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                       value={pt_age}
                        id="pt_age"
                        label="อายุ"  
                      
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                               ปี
                            </InputAdornment>
                        ),
                    }}
                    />
     
</Grid>
<Grid item xs={4} sm={4}>
<FormLabel component="legend">เพศ</FormLabel>
        <RadioGroup row={true}  aria-label="pt_sex" name="pt_sex" value={pt_sex} onChange={onGenderChange}>
            <FormControlLabel value="ชาย" control={<Radio />} label="ชาย" />
            <FormControlLabel value="หญิง" control={<Radio />} label="หญิง" />
        </RadioGroup>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_national_id[0]}
id="pt_national_id"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_national_id([{national_name:newValue.national_name}]);
console.log(newValue)
}
}}
getOptionSelected={(option, value) => option.national_name === value.national_name}
getOptionLabel={(option) => option.national_name + ""}
options={nationallist}
renderInput={(params) => (
<TextField
{...params}
label="สัญชาติ"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_origin_id[0]}
id="pt_origin_id"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setpPt_origin_id([{national_name:newValue.national_name}]);
console.log(newValue)
}
}}
getOptionSelected={(option, value) => option.national_name === value.national_name}
getOptionLabel={(option) => option.national_name + ""}
options={nationallist}
renderInput={(params) => (
<TextField
{...params}
label="เชื้อชาติ"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_religion_id[0]}
id="pt_religion_id"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_religion_id([{religion_name:newValue.religion_name}]);
console.log(newValue)
}
}}
getOptionLabel={(option) => option.religion_name + ""}
getOptionSelected={(option, value) => option.religion_name === value.religion_name}
options={religionlist}
renderInput={(params) => (
<TextField
{...params}
label="ศาสนา"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_mstatus[0]}
id="pt_mstatus"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_mstatus([{title:newValue.title}]);
console.log(newValue)
}
}}
getOptionLabel={(option) => option.title + ""}
getOptionSelected={(option, value) => option.title === value.title}
options={mstatus}
renderInput={(params) => (
<TextField
{...params}
label="สถานะภาพ"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_occ[0]}
id="pt_occ"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_occ([{occ_name:newValue.occ_name}]);
console.log(newValue)
}
}}
getOptionSelected={(option, value) => option.occ_name === value.occ_name}
getOptionLabel={(option) => option.occ_name + ""}
options={occlist}
renderInput={(params) => (
<TextField
{...params}
label="อาชีพปัจจุบัน"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={12}>

<Typography className={classes.title}  gutterBottom>
ข้อมูลที่อยู่
</Typography>
</Grid>
<Grid item xs={4} sm={4}>
                <TextField
                        name="pt_address"
                        variant="outlined"
                        value={pt_address}
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setPt_address(e.target.value);
                      }}
                        id="pt_address"
                        label="บ้านเลขที่"
                  
                      
                    />
</Grid>
<Grid item xs={4} sm={4}>
                <TextField
                value={pt_moi}
                        name="pt_moi"
                        variant="outlined"
                        
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setPt_moi(e.target.value);
                      }}
                        id="pt_moi"
                        label="หมู่"
                     
                      
                    />
</Grid>
<Grid item xs={4} sm={4}></Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_addr_province[0]}
id="pt_addr_province"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
handleChangeprovince(newValue.PROVINCE_ID);
setPt_addr_province([{PROVINCE_ID:newValue.PROVINCE_ID,PROVINCE_NAME:newValue.PROVINCE_NAME}]);
console.log(newValue)
}

}}
getOptionLabel={(option) => option.PROVINCE_NAME + ""}
getOptionSelected={(option, value) => option.PROVINCE_ID === value.PROVINCE_ID}
options={provicelist}
renderInput={(params) => (
<TextField
{...params}
label="จังหวัด"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_addr_amphur[0]}
id="pt_addr_amphur"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
handleChangeampher(newValue.AMPHUR_ID)
setPt_addr_amphur([{AMPHUR_ID:newValue.AMPHUR_ID,AMPHUR_NAME:newValue.AMPHUR_NAME}]);
console.log(newValue)
}
}}
getOptionSelected={(option, value) => option.AMPHUR_ID === value.AMPHUR_ID}
getOptionLabel={(option) => option.AMPHUR_NAME + ""}
options={amphurlist}
renderInput={(params) => (
<TextField
{...params}
label="อำเภอ"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={4} sm={4}>
<Autocomplete
value={pt_addr_tumbon[0]}
id="pt_addr_tumbon"
onChange={(event: any, newValue: any) => {
if(newValue !== null){
setPt_addr_tumbon([{DISTRICT_ID:newValue.DISTRICT_ID,DISTRICT_NAME:newValue.DISTRICT_NAME}]);
console.log(newValue)
}
}}
getOptionSelected={(option, value) => option.DISTRICT_ID === value.DISTRICT_ID}
getOptionLabel={(option) => option.DISTRICT_NAME + ""}
options={tumbollist}
renderInput={(params) => (
<TextField
{...params}
label="ตำบล"
variant="outlined"
/>
)}
/>
</Grid>
<Grid item xs={3} sm={3}>
<TextField
value={pt_addr_zipcode}
onChange={(e) => {

console.log(e.target.value);
setPt_addr_zipcode(e.target.value);

}}
                        name="pt_addr_zipcode"
                        variant="outlined"
                        
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        id="pt_addr_zipcode"
                        label="รหัสไปรษณีย์"
                     
                      
                    />
</Grid>
<Grid item xs={4} sm={4}>
<TextField
value={pt_phone}
onChange={(e) => {
console.log(e.target.value);
setPt_phone(e.target.value);
}}
                        name="pt_phone"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        id="pt_phone"
                        label="เบอร์โทรศัพท์"
                    
                      
                    />
</Grid>
<Grid item xs={12} sm={12}>
<Typography className={classes.title}  gutterBottom>
ข้อมูลอื่นๆ
</Typography>
</Grid>
<Grid item xs={4} sm={4}>
                <TextField
                  onChange={(e) => {
                    console.log(e.target.value);
                    setDisease(e.target.value);
                }}
                value={disease}
                        name="disease"
                        variant="outlined"
                        
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        id="disease"
                        label="โรคประจำตัว"
                     
                      
                    />
</Grid>
<Grid item xs={4} sm={4}>
                <TextField
                  onChange={(e) => {
                    console.log(e.target.value);
                    setDruggallergy(e.target.value);
                }}
                        name="druggallergy"
                        variant="outlined"
                        value={druggallergy}
                        fullWidth
                        InputLabelProps={{
                                shrink: true
                        }}
                        id="druggallergy"
                        label="แพ้ยา"
                      
                      
                    />
</Grid>
</Grid>
</DialogContent>
<DialogActions>
<Button variant="contained" onClick={handleSave} color="primary">
บันทึกข้อมูล
</Button>
<Button variant="contained" onClick={handleCloseOpendia} color="secondary">
Cancel
</Button>
</DialogActions>
</Dialog>
</form></React.Fragment>);
}