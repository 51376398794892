import React, { useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from '../Copyright';
import {apiService} from "../../services/api-prod";
import axios from "axios";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  typographys:{
color:'red'
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const [uerror, setUerror] = useState(false);
  const [uerrortext, setUerrortext] = useState('');
  const [perror, setPerror] = useState(false);
  const [perrortext, setPerrortext] = useState('');
  const [errorshows,setErrorshows] = useState('');
  const history = useHistory();
  const doSubmit = (data: any) => {
    if (data.username === '' && data.password ==='') {
      setUerror(true);
      setUerrortext('กรุณากรอก username');
      setPerror(true);
      setPerrortext('กรุณากรอกรหัสผ่าน');
    } else if (data.username === '') {
      setUerror(true);
      setUerrortext('กรุณากรอก username');
    } else if (data.password === '') {
      setPerror(true);
      setPerrortext('กรุณากรอกรหัสผ่าน');
    } else {
      http.post("login", data)
        .then(function (response: any) {
          if (response.data.token) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("fullname", response.data.fullname);
            localStorage.setItem("bran_id", response.data.bran_id);
            history.push({
              pathname: `/`,
             // state: { userAnswers },
            });
          } else{
            setErrorshows(response.data.message);
          }
          console.log(response.data.token);
          // return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }


  };
  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit(doSubmit)}  className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username"
            name="username"
              error={uerror}
              onChange={(evant) => {
                setUerror(false)
                setUerrortext('')
              }}
              helperText={uerrortext}
              inputRef={register}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(evant) => {
                setPerror(false)
                setPerrortext('')
              }}
              error={perror}
              helperText={perrortext}
              inputRef={register}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
             <Typography className={classes.typographys} variant="h5" align="center">
        {errorshows}
        </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}