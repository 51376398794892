import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import Copyright from '../Copyright';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header';
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import "dayjs/locale/th";
import http from "../../http-common";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '780px',
},
layout: {
  width: 'auto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},

  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  MuiFormControlmarginNormal:{
    marginBottom:'8px'
},
  title: {
    flexGrow: 1,
    backgroundColor:"#cccccc",
    paddingTop:'15px',
    paddingRight:'40px',
    paddingBottom:'15px',
    paddingLeft:'15px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const initialState = {
  items: [],
  amphur:[],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_amphur": {
        const {amphur} = action.payload;
        return {
            ...state,
            amphur
        }
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function UserForm(props:any) {
    
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  let  id  = props.match.params.id;
  const { register, handleSubmit ,getValues,control,setValue: setValuesave, errors:errorspt,setError } = useForm({ shouldUnregister: false });
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const [provicelist, setProvicelist] = React.useState<any>([]); 
  const [titleautocom,setTitleautocom]= React.useState<any>([]);
  const [nationlist,setNationlist] = React.useState<any>([]);
  const [religionlist,setReligionlist] = React.useState<any>([]);

  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  

  useEffect(() => {
    http.get("patient/province", header).then(json => {
      setTitleautocom(json.data.prefix);
    })
    if (typeof id !== 'undefined') {
       
        http.get("userinbran?id=" +id, header).then(json2 => {
          
                    setValuesave('certificate',json2.data.items[0].certificate)
                    setValuesave('cid', json2.data.items[0].cid);
                    setValuesave('fname', json2.data.items[0].fname);
                    setValuesave('lname', json2.data.items[0].lname);
                    setValuesave('title', json2.data.items[0].title);
                    setValuesave('username', json2.data.items[0].username);
                    setValuesave('position', json2.data.items[0].position);
                })
    }

  }, [])


const doSubmit = (data: any) => {
  http.post("userinbran/", data, header)
    .then(function (response: any) {
        if(response.data.ok){
            alert(response.data.message)
            history.replace({
                pathname: `/listuser`,
              })
        }else{
            alert(response.data.message)
        }
        
    })
};
const handleClickOpendialog = ()=>{
    history.replace({
      pathname: `/listuser`,
    })
  }
const form = (
  <React.Fragment>
     <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          
          <Typography className={classes.title}  gutterBottom>
          ข้อมูลผู้ใช้งาน
        </Typography>
          </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="username"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="username"
                                            label="Username"
                                            error={errorspt.username ? true :false}
                                            helperText={errorspt.username ? ('รหัสผ่าน'):('')}
                                            inputRef={register({
                                              required: true
                                          })}
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="password"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            error={errorspt.password ? true :false}
                                            helperText={errorspt.password ? ('รหัสผ่าน'):('')}
                                            id="password"
                                            label="Password"
                                            inputRef={register({
                                                required: true
                                            })
                                            }
                                          
                                          
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            </Grid>
            <Grid item xs={3} sm={3}>
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">คำนำหน้า</InputLabel>
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="title"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="คำนำหน้า"
                                                        >
                                                            {titleautocom.map((row: any, i: number) => (
                                                                <MenuItem key={i} value={row.prefix_name}>{row.prefix_name}</MenuItem>
                                                            )
                                                            )}
                                                        </Select>)
                                                    }
                                                />
                                            </FormControl>    
            </Grid>
            <Grid item xs={3} sm={3}>
                                    <TextField
                                            name="fname"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="fname"
                                            label="ชื่อ"
                                            error={errorspt?.fname?.type === "required" && (true)}
                                            helperText={errorspt?.fname?.type === "required" && ('กรุณากรอกชื่อ')}
                                             inputRef={register({
                                                required: true
                                            })
                                          }
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
            <TextField
                                            name="lname"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="lname"
                                            error={errorspt?.lname?.type === "required" && (true)}
                                            helperText={errorspt?.lname?.type === "required" && ('กรุณากรอกสกุล')}
                                            label="สกุล"
                                            inputRef={register({
                                                required: true
                                            })
                                        } 
                                        />
            </Grid>
            <Grid item xs={3} sm={3}>
                                    <TextField
                                            name="cid"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            error={errorspt?.cid?.type === "required" && (true)}
                                            helperText={errorspt?.cid?.type === "required" && ('กรุณากรอกเลขบัตรประจำตัวประชาชน')}
                                            id="cid"
                                            label="เลขบัตรประจำตัวประชาชน"  
                                            inputRef={register({
                                                required: true
                                            })
                                        }
                               
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            
            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">สถานะ</InputLabel>
                                                <Controller
                                                    name="position"
                                                    control={control}
                                                    defaultValue=""
                                                    onChange={() => console.log("hellow")}
                                                    render={({ onChange, value, onBlur, name }) => (
                                                        <Select
                                                            labelId="position"
                                                            id="position"
                                                            value={value ? value : ""}
                                                            onChange={(e) => {
                                                                 onChange(e)
                                                            }
                                                            }
                                                            name={name}
                                                            label="สถานะ"
                                                        >
                                                            
                                                                <MenuItem value="แพทย์">แพทย์</MenuItem>
                                                                <MenuItem value="พยาบาล">พยาบาล</MenuItem>
                                                           
                                                        </Select>)
                                                    }
                                                />

                                            </FormControl>  
          
            </Grid>
            <Grid item xs={6} sm={6}>
                                    <TextField
                                            name="certificate"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="certificate"
                                            label="certificate"
                                            
                                            inputRef={register()
                                          }
                                          
                                        />
                                        
            </Grid>
            <Grid item xs={6} sm={6}>
                                    <TextField
                                            name="right"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            id="right"
                                            label="สิทธิ์เข้าใช้งานระบบ"
                                            
                                            inputRef={register()
                                          }
                                          
                                        />
                                        
            </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <br/>
            <Button  type="submit" variant="contained" color="primary">
            บันทึกข้อมูล
          </Button>
          &nbsp;
            <Button onClick={handleClickOpendialog} variant="contained" color="secondary">
            Cancel
          </Button>
          </Grid>
          </form>
    </React.Fragment>
);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>   
    {form}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
     
    </div>
  );
}