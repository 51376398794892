import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Appointment from './Appointment';
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      },
      title: {
        flexGrow: 1,
        backgroundColor:"#cccccc",
        paddingTop:'15px',
        paddingRight:'40px',
        paddingBottom:'15px',
        paddingLeft:'15px'
      },
  }));
export default function Appointmentlist(props:any) {
    const classes = useStyles();
    const [opendialog, setOpendialog] = React.useState(false);
    const [opendialogappointform, setOpendialogappointform] = React.useState(false);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const {initData, saveCallback,initOpdscreen,initPatient} = props;
    const [appoint,setAppoint] = React.useState<any>([]);
    const [id,setId] =  React.useState('');
    const handleClickOpendialog = () => {
        setOpendialog(true);
        getlistdata();
      
    }
    const handleClickOpendialogAppoint = ()=>{
        setOpendialogappointform(true);
        setId("");

    }
    const getlistdata = () =>{
        http.get("appoint/byptid?pt_id="+initPatient.id, header).then(json => {
            setAppoint(json.data.items)
    })
    }
    const handleClickOpendialogAppointEdit = (id:any)=>{
        setOpendialogappointform(true);
        setId(id);
    }
    const printAppoint = async(id_app:any)=>{
        window.open("http://45.32.107.235/report/appoint.php?vn="+initOpdscreen[0].vn+"&pt_id="+initPatient.id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id")+'&id_app='+id_app);
      }
    const handleClosedialog = () => {
     
        setOpendialog(false);
      };
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
          
        }
      })
    const deleteAppoint = (id:any)=>{
        
        swalWithBootstrapButtons.fire({
          title: '',
          text: "คุณต้องการลบรายการนี้หรือไม่",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            http.delete("appoint/" + id,header)
            .then(function (response: any) {
                getlistdata();
            })
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            
          }
        })
      }
    return ( 
        <React.Fragment>
            <Button fullWidth variant="contained" onClick={handleClickOpendialog} color="primary">นัดผู้ป่วย <DateRangeIcon/></Button>
            <Dialog fullWidth={true} maxWidth="md" open={opendialog} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">รายการนัด</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={12} sm={12}>
       <Button  variant="contained" size="small" onClick={handleClickOpendialogAppoint} color="primary"><AddIcon/>เพิ่มรายการนัด </Button>
       <Appointment id={id} initData={initData} saveCallback={saveCallback} initOpdscreen={initOpdscreen} initPatient={initPatient} opendialogappointform={opendialogappointform} handleClosedialogapppint={() => {
           setOpendialogappointform(false);
           getlistdata();
       }} />
       <br/>
       <Typography className={classes.title} variant="h6" align="left"> ประวัตินัด </Typography>  
          <TableContainer component={Paper}>
             
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell  align="center">วันที่มา</TableCell>
            <TableCell  align="center">เวลามา</TableCell>
            <TableCell  align="center">นัดมาวันที่</TableCell>
            <TableCell  align="center">นัดมาเวลา</TableCell>
            <TableCell  align="center">แพทย์ผู้นัด</TableCell>
            <TableCell  align="center">เหตุผลการนัดมา</TableCell> 
            <TableCell   align="center">การปฏิบัติตัว</TableCell>
            <TableCell width="15%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {appoint.map((row: any, index: any) => {
           return( <TableRow >
            <TableCell align="center">{index+1}</TableCell>
           <TableCell align="center">{moment(row.visit_date).add(543, 'year').format('DD/MM/YYYY')}</TableCell>
           <TableCell align="center">{row.visit_time} </TableCell>
           <TableCell align="center">{moment(row.nextdate).add(543, 'year').format('DD/MM/YYYY')}</TableCell>
              <TableCell align="center">{row.nexttime}</TableCell>
              <TableCell align="center">{row.title}{row.fname} {row.lname}</TableCell>
              <TableCell align="center">{row.clinic}</TableCell>
              <TableCell align="center">{row.note}</TableCell>
              <TableCell align="center"><Button variant="outlined" onClick={() => {
                          {
                            handleClickOpendialogAppointEdit(row.id_app)
                          }}}  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
                <EditIcon/>
              </Button>&nbsp;<Button variant="outlined" onClick={() => {
                          {
                  deleteAppoint(row.id_app)
                          }}}  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="secondary" >
                <DeleteIcon/>
              </Button>&nbsp;<Button variant="outlined" onClick={() => {
                          {
                            printAppoint(row.id_app)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="secondary" >
                <PrintIcon/>
              </Button></TableCell>
            </TableRow>)
        })}
        </TableBody>
      </Table>
    </TableContainer>  
           </Grid>
           </Grid>
   </DialogContent>
   <DialogActions>
   
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       ปิด
     </Button>
   </DialogActions>
   </Dialog>
            </React.Fragment>);
}