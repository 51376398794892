import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import PackgateDetail from './PackgateDetail';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Swal from 'sweetalert2';
import FormControlLabel from "@material-ui/core/FormControlLabel";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // maxHeight: '780px',
},
layout: {
  width: 'auto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
  },
},

  table: {
    minWidth: 650,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  MuiFormControlmarginNormal:{
    marginBottom:'8px'
},
  title: {
    flexGrow: 1,
    backgroundColor:"#cccccc",
    paddingTop:'15px',
    paddingRight:'40px',
    paddingBottom:'15px',
    paddingLeft:'15px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const initialState = {
  items: [],
  amphur:[],
  loading: false,
  error: null,
  filter: null
  , pagination: {
    limit: 5,
    offset: 0,
    totalRecords: 0
  }
};
function reducer(state: any, action: any) {
  switch (action.type) {
    case "fetch_start": {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case "fetch_amphur": {
        const {amphur} = action.payload;
        return {
            ...state,
            amphur
        }
    }
    case "fetch_success": {
      const { items, totalRecords } = action.payload;
      const { limit, offset } = state.pagination;
      return {
        ...state,
        items,
        loading: false,
        pagination: {
          limit: limit,
          offset: offset,
          totalRecords: totalRecords
        }
      };
    }
    case "fetch_failure": {
      const { error } = action.payload;
      return {
        ...state,
        items: [],
        loading: false,
        error
      };
    }
    default:
      throw new Error("Missing action type");
  }
}
export default function PackgateForm(props:any) {
    
  const [state, $dispatch] = useReducer(reducer, {
    ...initialState
  });
  let  id  = props.match.params.id;
  const history = useHistory();
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const dispatch = useSafeFunction($dispatch);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [name_pack, setName_pack] = React.useState('');
  const [publics,setPublics] =  React.useState('');
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const url =  'http://127.0.0.1:4000/v1/packgate?limit=' + rowsPerPage + '&offset=' + page + '&query=' + '&qname=';
  const urlassettbs = 'http://127.0.0.1:4000/v1/packgate/'
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const DialogContent = withStyles((theme: Theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);
  const DialogActions = withStyles((theme: Theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (typeof id !== 'undefined') {
        const urlid = "http://127.0.0.1:4000/v1/packgate/byid?id=" +id
                axios.get(urlid, header).then(json2 => {
                  setName_pack(json2.data.items[0].name_pack)
                  setPublics(json2.data.items[0].public);
                })
    }

  }, [])

const savedata = () => {
  const data = {
    name_pack:name_pack,
    public:publics
  }
    axios.post("http://127.0.0.1:4000/v1/packgate?id="+id, data, header)
    .then(function (response: any) {
        if(response.data.ok){
          Swal.fire(
            response.data.message,
            '',
            'success'
          );
            // alert(response.data.message)
            history.replace({
                pathname: `/packgate`,
              })
        }else{
            alert(response.data.message)
        }
    })
};
const handleClickOpendialog = ()=>{
    history.replace({
      pathname: `/packgate`,
    })
  }
function onGenderChange(event:any) {
    setPublics(event.target.value);
}

const form = (
  <React.Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          
          <Typography className={classes.title}  gutterBottom>
          ข้อมูล Item
        </Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
                                    <TextField
                                            name="name_pack"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{
                                                    shrink: true
                                            }}
                                            value={name_pack}
                                            id="name_pack"
                                            label="ชื่อ Packgate"
                                        />
            </Grid>
            <Grid item xs={4} sm={4}>
            <FormControl variant="outlined" fullWidth>
                                                <FormLabel component="legend">ประเภท</FormLabel>
                            <RadioGroup row={true}  aria-label="publics" name="publics" value={publics} onChange={onGenderChange}>
                                <FormControlLabel value="1" control={<Radio />} label="public" />
                                <FormControlLabel value="2" control={<Radio />} label="private" />
                            </RadioGroup>
                                            </FormControl>  
                                
            </Grid>
            <Grid item xs={12} sm={12}>
                                                <PackgateDetail IDCNO={id} />
                                            </Grid>
            <Grid item xs={12} sm={12}>
            <Button  onClick={savedata} variant="contained" color="primary">
            บันทึกข้อมูล
          </Button>
          &nbsp;
            <Button onClick={handleClickOpendialog} variant="contained" color="secondary">
            Cancel
          </Button>
          </Grid>
          </Grid>
    </React.Fragment>
);
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Header/>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainListItems/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>   
    {form}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
     
    </div>
  );
}