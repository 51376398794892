import React, { useState}  from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../Copyright';

import axios from "axios";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  typographys:{
    color:'red'
      },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  
  const { register, handleSubmit ,errors } = useForm();
  const history = useHistory();

  const [uerrorfname, setUerrorname] = useState(false);
  const [uerrorfnametext, setUerrornametext] = useState('');

  const [uerrorlname, setUerrorlname] = useState(false);
  const [uerrorlanemtext, setUerrorlnametext] = useState('');

  const [uerroremail, setUerroremail] = useState(false);
  const [uerroremailtext, setUerroremailtext] = useState('');

  const [uerror, setUerror] = useState(false);
  const [uerrortext, setUerrortext] = useState('');

  const [perror, setPerror] = useState(false);
  const [perrortext, setPerrortext] = useState('');

  const [errortel, setErrortel] = useState(false);
  const [errorteltext, setErrorteltext] = useState('');

  const [errorcid, setErrorcid] = useState(false);
  const [errorcidtext, setErrorcidtext] = useState('');

  const [errorshows,setErrorshows] = useState('');
  

  const doSubmit = (data: any) => {
      http.post("register", data)
        .then(function (response: any) {
          if (response.data.ok) {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("fullname", response.data.fullname);
            history.push({
              pathname: `/login`,
             // state: { userAnswers },
            });
          } else{
            setErrorshows(response.data.message);
          }
          console.log(response.data.token);
          // return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form onSubmit={handleSubmit(doSubmit)} className={classes.form} noValidate>
           <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="fname"
                variant="outlined"
                required
                error={errors?.fname?.type === "required" && (
true
                )}
            
                helperText={errors?.fname?.type === "required" && (
                  <div>กรุณากรอกข้อมูลชื่อ</div>
                                  )}
                inputRef={register({
                    required: "Required"
                })
                }
                fullWidth
                id="fname"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lname"
                label="Last Name"
                name="lname"
                error={errors?.lname?.type === "required" && (
                  true
                                  )}
           
                helperText={errors?.lname?.type === "required" && (
                  <div>
                    กรุณากรอกข้อมูลนามสกุล
                    </div>
                                  )}
                inputRef={register({
                  required: "Required"
              })}
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email" 
                error={errors?.email?.type === "pattern" || errors?.email?.type === "required" && (
                 
                 true
                )}
             
                helperText={errors?.email?.type === "pattern" || errors?.email?.type === "required" && (
                 
                  <div>กรุณากรอก email ให้ถูกต้อง</div>
                )}
                inputRef={register({
                  required: "Required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address"
                  }
                })}
                autoComplete="email"
              />
               
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="username"
                label="Username"
                type="text"
                error={errors?.username?.type === "required"  && (
                  true
                )}
                helperText={errors?.username?.type === "required"  && (
                  <div>กรุณากรอก username</div>
                )}
                inputRef={register({ 
                  required: true
                })}
                id="username"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                error={errors?.password?.type === "required" || errors?.password?.type === "pattern" && (
                  true
                )}
             
                helperText={errors?.password?.type === "required" || errors?.password?.type === "pattern" && (
                  <div>กรุณากรอกรหัสผ่านให้มากกว่า 6 ตัว</div>
                )}
                inputRef={register({ 
                  required: true,
                  // We want a number in 11-digits format.
                  pattern: /\d{6}/,
                })}
                id="password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="cid"
                error={errors?.cid?.type === "required" || errors?.cid?.type === "pattern" && (
                  true
                )}
            
                helperText={errors?.cid?.type === "required" || errors?.cid?.type === "pattern" && (
                  <div>กรุณากรอกเลขบัตรประจำตัวประชาชน</div>
                )}
                inputRef={register({ 
                  required: true,
                  // We want a number in 11-digits format.
                  pattern: /\d{13}/,
                })}
                label="เลขบัตรประจำตัวประชาชน"
                type="number"
                id="cid"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="tel"
                error= {errors?.tel?.type === "required" || errors?.tel?.type === "pattern" && (
                  true
                )}
                helperText={errors?.tel?.type === "required" || errors?.tel?.type === "pattern" && (
                  <div>กรุณากรอกเบอร์ให้ครบ</div>
                )}
                inputRef={register({ 
                  required: true,
                  // We want a number in 11-digits format.
                  pattern: /\d{10}/,
                })}
                label="เบอร์โทร"
                type="number"
                id="tel"
              />
            </Grid>
            <Typography className={classes.typographys} variant="h5" align="center">
        {errorshows}
        </Typography>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}