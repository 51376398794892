import React, { useState, useEffect,useCallback } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import http from "../../http-common";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
function preventDefault(event:any) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
      title: {
        flexGrow: 1,
        backgroundColor:"#cccccc",
        paddingTop:'15px',
        paddingRight:'40px',
        paddingBottom:'15px',
        paddingLeft:'15px'
      }
}));
export default function Listdrugcheck(props:any) {
  const classes = useStyles();
    const [opendialog, setOpendialog] = React.useState(false);
    const [waitcheck,setWaitcheck] = React.useState<any>([]);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const {saveCallback,open} = props;
  const handleClosedialog = () => {
      setOpendialog(false);
    };
    const handleClickSelectdata = (e:any) => {
        saveCallback(e);
        props.handleCloseListwait();
    }
    const handleCloselistwait = () => {
        props.handleCloseListwait();
    };
    const fetchMyAPI = useCallback(async () => {
        await http.get('visit/waitcheckdrug?visit_date='+moment().format('YYYY-MM-DD'), header).then(json => {
            setWaitcheck(json.data.items)
    })
    }, [])
    useEffect(() => {
        if(open){
           fetchMyAPI()
        }
      },[open,fetchMyAPI])
    return (
      <React.Fragment>
 
     <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">รายชื่อรอจ่ายยา</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
   <Grid item xs={12}>
   <Typography className={classes.title} variant="h6" align="left"> รายชื่อ </Typography>
   <TableContainer component={Paper}>
             
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell  align="center">วันที่มา</TableCell>
            <TableCell  align="center">เวลามา</TableCell>
            <TableCell  align="center">CN</TableCell>
            <TableCell  align="center">ชื่อผู้รับบริการ</TableCell>
            <TableCell  align="center">สถานะ</TableCell>
            <TableCell width="15%" align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {waitcheck.map((row: any, index: any) => {
           return( <TableRow >
            <TableCell align="center">{index+1}</TableCell>
           <TableCell align="center">{moment(row.visit_date).add(543, 'year').format('DD/MM/YYYY')}</TableCell>
           <TableCell align="center">{row.visit_time} </TableCell>
           <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.pt_prefix_id}{row.pt_firstname} {row.pt_lastname}</TableCell>
              <TableCell align="center">รอรับยา</TableCell>
              <TableCell align="center"><Button variant="outlined" onClick={() => {
                          {
                           handleClickSelectdata(row)
                          }}}  style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}} color="primary" >
                <ArrowDownwardIcon/>
              </Button></TableCell>
            </TableRow>)
        })}
        </TableBody>
      </Table>
    </TableContainer>  
</Grid>
</Grid>
</DialogContent>
<DialogActions>
     <Button variant="contained" onClick={handleCloselistwait} color="secondary">
       ปิด
     </Button>
   </DialogActions>
</Dialog>


          </React.Fragment>);
}