import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
export default function CertificationForm2(props:any) {
    const classes = useStyles();
    const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
    const [opdscreen, setOpdscreen]= React.useState<any>([]);
    const {initData, saveCallback,initOpdscreen,initPatient,opendialogappointform,id} = props;
    const [visitdate, setVisitdate] = React.useState('');
    const [visit_time, setVisit_time] = React.useState('');
    const [vn, setVn] = React.useState('');
    const [opendialog, setOpendialog] = React.useState(false);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [note,setNote] = React.useState('');
    const [pt_id,setPt_id] = React.useState('');
    const [cer2_bookrun,setCer2_bookrun]= React.useState('');
    const [cer2_numberrun,setCer2_numberrun]= React.useState('');
    const [cer2_locationcheck,setCer2_locationcheck]= React.useState('');
    const [cer2_doctorname,setCer2_doctorname]= React.useState('นายแพทย์ณัฐวัฒน์ หลาบสีดา');
    const [cer2_patition,setCer2_patition]= React.useState('อายุรแพทย์ ประจำคลีนิค');
    const [cer2_fullname,setCer2_fullname]= React.useState('');
    const [cer2_addr,setCer2_addr]= React.useState('');
    const [cer2_license,setCer2_license]= React.useState('53602');
    const [cer2_locationname,setCer2_locationname]= React.useState('');
    const [cer2_width,setCer2_width]= React.useState('');
    const [cer2_height,setCer2_height]= React.useState('');
    const [cer2_bp,setCer2_bp] = React.useState('');
    const [cer2_pulse,setCer2_pulse] = React.useState('');
    const [cer2_body,setCer2_body]= React.useState("1");
    const [cer2_bodydetail,setCer2_bodydetail]=React.useState('');
    const [cer2_approve,setCer2_approve]=React.useState('');
    const [cer2_approve2,setCer2_approve2]=React.useState('');
    const [cer2_cid,setCer2_cid]=React.useState('');
    const [cer2_disease,setCer2_disease]=React.useState('1');
    const [cer2_disease_detail,setCer2_disease_detail]=React.useState('');
    const [cer2_accident,setCer2_accident]=React.useState('1');
    const [cer2_accident_detail,setCer2_accident_detail]=React.useState('');
    const [cer2_treated,setCer2_treated]=React.useState('1');
    const [cer2_treated_detail,setCer2_treated_detail]=React.useState('');
    const [cer2_history,setCer2_history]=React.useState('');
    const [cer2_clinic_addr,setCer2_clinic_addr]=React.useState('');
    const [cer2_other,setCer2_other]=React.useState('');
    const [cer2_date, setCer2_date] = React.useState<Date | null>(
      new Date(),
  );
  const [cer2_date2,setCer2_date2]= React.useState<Date | null>(
    new Date(),
);
    const handleClosedialog = () => {
      props.handleClosedialogapppint();
    };
      React.useEffect(() => {
       if(opendialogappointform){
        http.get("bran/byid?id=1", header).then(json => {
            json.data.items.map((rowbran: any, indexbran: any) => {
                 setCer2_locationcheck(rowbran.bran_fullname);
                 setCer2_locationname(rowbran.bran_fullname);
                 setCer2_clinic_addr(rowbran.bran_addr);
                 setCer2_bookrun(rowbran.bran_cer2_booknumber);
                 const runnum = parseInt(rowbran.bran_cer2_runnumber) + 1;
                 setCer2_numberrun(String(runnum));
            })

          })
        console.log(initPatient);

        setPt_id(initPatient.id);
        setCer2_fullname(initPatient.pt_prefix_id+initPatient.pt_firstname+" "+initPatient.pt_lastname);
        setCer2_addr(initPatient.pt_address +" หมู่ "+initPatient.pt_moi +" ต."+initPatient.DISTRICT_NAME +" อ."+initPatient.AMPHUR_NAME +" จ."+initPatient.PROVINCE_NAME);
        setCer2_cid(initPatient.pt_cid);
        if(id !== ""){
        //  initOpdscreen.map((row: any, index: any) => {
           
            http.get("cer2/byid?id="+id, header).then(jsoncer => {
                jsoncer.data.items.map((rowcer: any, indexcer: any) => {
                    setCer2_bookrun(rowcer.cer2_bookrun);
                    setCer2_numberrun(rowcer.cer2_numberrun);
                    setPt_id(rowcer.cer2_ptid);
                    setVn(rowcer.cer2_vn);
                    setVisitdate(rowcer.visit_date);
                    setCer2_fullname(rowcer.cer2_fullname);
                    setCer2_addr(rowcer.cer2_addr);
                    setCer2_cid(rowcer.cer2_cid);
                    setCer2_disease(rowcer.cer2_disease);
                    setCer2_disease_detail(rowcer.cer2_disease_detail);
                    setCer2_accident(rowcer.cer2_accident);
                    setCer2_accident_detail(rowcer.cer2_accident_detail);
                    setCer2_treated(rowcer.cer2_treated);
                    setCer2_treated_detail(rowcer.cer2_treated_detail);
                    setCer2_history(rowcer.cer2_history);
                    setCer2_locationcheck(rowcer.cer2_locationcheck);
                    const cer2_dates = new Date(rowcer.cer2_date);
                    setCer2_date(cer2_dates);
                    setCer2_doctorname(rowcer.cer2_doctorname);
                    setCer2_license(rowcer.cer2_license);
                    setCer2_locationname(rowcer.cer2_locationname);
                    const cer2_date2s = new Date(rowcer.cer2_date2);
                    setCer2_date2(cer2_date2s);
                    setVisit_time(rowcer.cer2_time);
                    setCer2_width(rowcer.cer2_width);
                    setCer2_height(rowcer.cer2_height);
                    setCer2_bp(rowcer.cer2_bp);
                    setCer2_pulse(rowcer.cer2_pulse);
                    setCer2_body(rowcer.cer2_body);
                    setCer2_bodydetail(rowcer.cer2_bodydetail);
                    setCer2_other(rowcer.cer2_other);
                    setCer2_approve(rowcer.cer2_approve);
                      
                     
                })
            })
         // })
        }else{
          initOpdscreen.map((row: any, index: any) => {
            setVisitdate(row.visit_date);
            setVisit_time(row.visit_time);
            setVn(row.vn);
            setCer2_disease("1");
            setCer2_disease_detail("");
            setCer2_accident("1");
            setCer2_accident_detail("");
            setCer2_treated("1");
            setCer2_treated_detail("");
            setCer2_history("")
            setCer2_width(row.width);
            setCer2_height(row.height);
            setCer2_bp(row.bps+"/"+row.bpd);
            setCer2_pulse(row.pulse);
            setCer2_body("1");
            setCer2_bodydetail("");
            setCer2_approve("");
            setCer2_other("");
          })
        }
        
      }
      }, [initData,initOpdscreen,initPatient,id,opendialogappointform]);
      async function onGenderChange(event:any){
     if(event !== null){
        setCer2_body(event.target.value);
     }
    }

   
    const { register: registersave, getValues, handleSubmit: handleSubmitsave, setValue: setValuesave, control, setError } = useForm();
    const handleSave = (e:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        const cer2_date_save = moment(cer2_date, "DD-MM-YYYY");
        const cer2_date2_save = moment(cer2_date2, "DD-MM-YYYY");
        const tempData = initData || [{}];
        const data = {
             cer2_vn:vn,
             visit_date:visitdate,
             cer2_ptid:pt_id,
            cer2_bookrun:cer2_bookrun,
            cer2_numberrun:cer2_numberrun,
            cer2_fullname:cer2_fullname,
            cer2_addr:cer2_addr,
            cer2_cid:cer2_cid,
            cer2_disease:cer2_disease,
            cer2_disease_detail:cer2_disease_detail,
            cer2_accident:cer2_accident,
            cer2_accident_detail:cer2_accident_detail,
            cer2_treated:cer2_treated,
            cer2_treated_detail:cer2_treated_detail,
            cer2_history:cer2_history,
            cer2_locationcheck:cer2_locationcheck,
            cer2_date:cer2_date_save,
            cer2_doctorname:cer2_doctorname,
            cer2_license:cer2_license,
            cer2_locationname:cer2_locationname,
            cer2_clinic_addr:cer2_clinic_addr,
            cer2_date2:cer2_date2_save,
            cer2_width:cer2_width,
            cer2_height:cer2_height,
            cer2_bp:cer2_bp,
            cer2_pulse:cer2_pulse,
            cer2_body:cer2_body,
            cer2_bodydetail:cer2_bodydetail,
            cer2_other:cer2_other,
            cer2_approve:cer2_approve,
            cer2_approve2:cer2_approve2,
            cer2_time:visit_time
        }
        http.post('cer2?cer2_id='+id,data, header).then(json => {
            props.handleClosedialogapppint();
              Swal.fire(
                  json.data.message,
                  '',
                  'success'
                );
          });

    };
    const handleDateChange = (date: Date | null) => {
        setCer2_date(date);
    };
    const handleDateChange2 = (date: Date | null) => {
        setCer2_date2(date);
    };
   return ( 
    <React.Fragment>
     
     <form onSubmit={e => {
                        handleSave(e)
                    }}>
     <Dialog fullWidth={true} maxWidth="md" open={opendialogappointform} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">ใบรับรองแพทย์ใบขับขี่ใบขับขี่</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={2} sm={2}>
                               <TextField
                                       name="cer2_bookrun"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setCer2_bookrun(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={cer2_bookrun}
                                       id="cer2_bookrun"
                                       label="เล่มที่"
                                       
                                   />
       </Grid>
       <Grid item xs={2} sm={2}>
                               <TextField
                                       name="cer2_numberrun"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_numberrun(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_numberrun}
                                       id="cer2_numberrun"
                                       label="เลขที่"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                                       name="cer2_fullname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_fullname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_fullname}
                                       id="cer2_fullname"
                                       label="ชื่อ-สกุล"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    <TextField
                                       name="cer2_addr"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_addr(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_addr}
                                       id="cer2_addr"
                                       label="สถานที่อยู่"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                    <TextField
                                       name="cer2_cid"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_cid(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_cid}
                                       id="cer2_cid"
                                       label="หมายเลขประจำตัวประชาชน"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={3} sm={3}>
       <FormLabel component="legend">1.โรคประจำตัว</FormLabel>
                            <RadioGroup row={true}  aria-label="cer2_disease" value={cer2_disease} name="cer2_disease"  
                              onChange={(e:any) => {
                                setCer2_disease(e.target.value);
                                console.log(cer2_disease)
                            }}
                            >
                               <FormControlLabel value="1" control={<Radio />} label="ไม่มี" />
                                <FormControlLabel value="2" control={<Radio />} label="มี" />
                            </RadioGroup>
                            </Grid>
                   
                    <Grid item xs={9} sm={9}>
                    <TextField
                                       name="cer2_disease_detail"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_disease_detail(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_disease_detail}
                                       id="cer2_disease_detail"
                                       label="โรคประจำตัว(ระบุ)"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={3} sm={3}>
       <FormLabel component="legend">2.อุบัติเหตุและการผ่าตัด</FormLabel>
                            <RadioGroup row={true}  aria-label="cer2_accident" value={cer2_accident} name="cer2_accident"  
                              onChange={(e:any) => {
                                setCer2_accident(e.target.value);
                                console.log(cer2_accident)
                            }}
                            >
                               <FormControlLabel value="1" control={<Radio />} label="ไม่มี" />
                                <FormControlLabel value="2" control={<Radio />} label="มี" />
                            </RadioGroup>
                            </Grid>
                    <Grid item xs={9} sm={9}>
                    <TextField
                                       name="cer2_accident_detail"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_accident_detail(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_accident_detail}
                                       id="cer2_accident_detail"
                                       label="อุบัติเหตุและการผ่าตัด(ระบุ)"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={3} sm={3}>
       <FormLabel component="legend">3.เคยเข้ารับการรักษาในโรงพยาบาล</FormLabel>
                            <RadioGroup row={true}  aria-label="cer2_treated" value={cer2_treated} name="cer2_treated"  
                              onChange={(e:any) => {
                                setCer2_treated(e.target.value);
                                console.log(cer2_treated)
                            }}
                            >
                               <FormControlLabel value="1" control={<Radio />} label="ไม่มี" />
                                <FormControlLabel value="2" control={<Radio />} label="มี" />
                            </RadioGroup>
                            </Grid>
                   
                    <Grid item xs={9} sm={9}>
                    <TextField
                                       name="cer2_treated_detail"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_treated_detail(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_treated_detail}
                                       id="cer2_treated_detail"
                                       label="เคยเข้ารับการรักษาในโรงพยาบาล(ระบุ)"
                                       
                                     
                                   />
                    </Grid>
                   
                    <Grid item xs={12} sm={12}>
                    <TextField
                                       name="cer2_history"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_history(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_history}
                                       id="cer2_history"
                                       label="4.ประวัติอื่นๆที่สำคัญ"
                                       
                                     
                                   />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                                       name="cer2_locationcheck"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_locationcheck(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_locationcheck}
                                       id="cer2_locationcheck"
                                       label="สถานที่ตรวจ"
                                       
                                     
                                   />
                    </Grid>
                   
     <Grid item xs={4} sm={4}>
             <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                         <DatePicker
                             id="cer2_date"
                             label="วันที่"
                             name="cer2_date"
                             format="DD/MM/YYYY"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             value={cer2_date}
                             yearOffset={543}
                             onChange={(e:any) => {
                               if(e !== null){
                                 console.log(e);
                                handleDateChange(e)
                               }
                             }
                             }
                             inputVariant="outlined"
                         
                         />
</MuiPickersUtilsProvider>
</Grid>
                    <Grid item xs={4} sm={4}>
                    <TextField
                                       name="cer2_doctorname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_doctorname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_doctorname}
                                       id="cer2_doctorname"
                                       label="ข้าพเจ้า"
                                       autoFocus
                                     
                                   />
                              
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer2_license"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_license(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_license}
                                       id="cer2_license"
                                       label="ใบอนุญาตประกอบเลขที่"
                                       autoFocus
                                     
                                   />
       </Grid>
      
       <Grid item xs={4} sm={4}>
       
       <TextField
                                       name="cer2_locationname"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_locationname(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_locationname}
                                       id="cer2_locationname"
                                       label="สถานพยาบาลชื่อ"
                                       autoFocus
                                     
                                   />
                                
       </Grid>
       <Grid item xs={9} sm={9}>
       <TextField
                                       name="cer2_clinic_addr"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_clinic_addr(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_clinic_addr}
                                       id="cer2_clinic_addr"
                                       label="ที่อยู่คลีนิค"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
             <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                         <DatePicker
                             id="cer2_date2"
                             label="เมื่อวันที่"
                             name="cer2_date2"
                             format="DD/MM/YYYY"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             value={cer2_date2}
                             yearOffset={543}
                             onChange={(e:any) => {
                               if(e !== null){
                                 console.log(e);
                                 handleDateChange2(e)
                               }
                             }
                             }
                             inputVariant="outlined"
                         
                         />
</MuiPickersUtilsProvider>
</Grid>
<Grid item xs={3} sm={3}>
       <TextField
                                       name="cer2_width"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_width(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_width}
                                       id="cer2_width"
                                       label="น้ำหนักตัว"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer2_height"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_height(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_height}
                                       id="cer2_height"
                                       label="ความสูง"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer2_bp"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_bp(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_bp}
                                       id="cer2_bp"
                                       label="ความดันโลหิต"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
                                       name="cer2_pulse"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_pulse(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={cer2_pulse}
                                       id="cer2_pulse"
                                       label="ชีพจร"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={3} sm={3}>
       <FormLabel component="legend">สภาพร่างกายทั่วไป</FormLabel>
                            <RadioGroup row={true}  aria-label="cer2_body" value={cer2_body} name="cer2_body"  
                              onChange={(e:any) => {
                                setCer2_body(e.target.value);
                                console.log(cer2_body)
                            }}
                            >
                               <FormControlLabel value="1" control={<Radio />} label="ปกติ" />
                                <FormControlLabel value="2" control={<Radio />} label="ผิดปกติ" />
                            </RadioGroup>
                            </Grid>
                            <Grid item xs={8} sm={8}>
       <TextField
                                       name="cer2_bodydetail"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_bodydetail(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer2_bodydetail"
                                       label="ผิดปกติ(ระบุ)"
                                       value={cer2_bodydetail}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="cer2_other"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_other(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer2_other"
                                       label="4.อืนๆ (ถ้ามี)"
                                       
                                       value={cer2_other}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="cer2_approve"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setCer2_approve(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="cer2_approve"
                                       label="สรุปความเห็นและข้อแนะนำของแพทย์"
                                       multiline
                                       rows={3}
                                       value={cer2_approve}
                                   />
       </Grid>
     </Grid>
   </DialogContent>
   <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>
 </form>
 </React.Fragment>
   );
}