import React from 'react';
type ContextProps = { 
    authenticated: boolean,
    lang: string,
    theme: string,
    patient:string
  };
 const ThemeContext = React.createContext<Partial<ContextProps>>({});
 export default ThemeContext;

