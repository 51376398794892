import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
export default function Print() {
   
   return ( 
    <React.Fragment>
         <Typography variant="h5" component="h2">
    ใบส่งต่อ
    </Typography>
    <Grid   container spacing={1}>
      <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    ชื่อ
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    น้ำหนัก ส่วนสูง BMI อุณหภูมิ BP ชีพจร
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    Chief Complaint
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    PE
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    Diag
    </Typography>
    
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    รายการยา
    </Typography>
    
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    paracetamol
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    ibruprofen
    </Typography>
    </Grid>
    <Grid style={{ display:'flex', justifyContent:'left' }} item xs={12}>
    <Typography >
    amitrib
    </Typography>
    </Grid>
    </Grid>
 </React.Fragment>
   );
}