import React from 'react';
import './App.css';
import SignInSide from './components/login/Login';
import SignUp from './components/login/SignUp';
import Forgot from './components/login/forgot';
import Homes from './components/home/Homes';
import Print from './components/home/Print'
import PatientForm from './components/patient/PatientForm';
import UserForm from './components/usermanage/UserForm';
import Patientlist from './components/patient/Patientlist';
import Listdrug from './components/drug/Listdrug';
import Listappoint from './components/appointment/Listappoint'
import Listusermanage from './components/usermanage/Listusermanage'
import Report from './components/report/Report';
import Packgate from './components/packgate/Packgate';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {ThemeProvider} from '@material-ui/styles';
import ThemeContext from './ThemeContext';
import DrugForm from './components/drug/DrugForm';
import PackgateForm from './components/packgate/PackgateForm';
import Config from './components/config/Config';
import Excellimport from './components/excellimport/Excellimport';
import Rxorder from './components/rxorder/Rxorder';
import Finance from './components/finance/Finance';
import Listdruguse from './components/druguses/Listdruguse';
import {
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";
const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#1976d2',
      },
      secondary: {
          main: '#dc004e',
      },
      error: {
          main: '#f44336',
      },
      warning: {
          main: '#ff9800',
      },
      info: {
          main: '#2196f3',
      },
      success: {
          main: '#4caf50',
      },
  },
});
export const AppContext = React.createContext({
  patient: null,
  setPatient: () => {
  },
  user: {},
  initUser: () => {
  }
  ,
  setUser: () => {
  },
  currentVisit: {},
  setCurrentVisit: () => {
  },
  medicineTran: {},
  setMedicineTran: () => {
  },
  orderTran: {},
  setOrderTran: () => {
  },
  currentDepartment: {departmentName: 'ห้องตรวจอายุรกรรม', 'departmentCode': '010'},
  setCurrentDepartment: () => {
  },
  appointmentList: null,
  setAppointmentList: () => {
  },
  appointmentOrder: [],
  setAppointmentOrder: () => {
  },
  selectedAppointmentList: {data: [], dateSelected: ''},
  setSelectedAppointmentList: () => {
  },
  checkTodayVisit: (hn:any) => {
  },
  onSelectedPatient: (v:any) => {
  },
  clearPatient: () => {
  },
  constant: {},
  setConstant: () => {
  },
  setOpenMenu: () => {
  },
  isActionAllow: (role:any) => {

  },
  medicalCertificate: null,
  setMedicalCertificate: () => {
  },
  documentList: {
      setOR: [],
      refer: []
  },
  setDocumentList: () => {
  },
  loading: false,
  setLoading: () => {
  },
});

const App: React.FC = () => {
  const context = ThemeContext;
  const [patient, setPatient] = React.useState("");
  const contexts = AppContext;
  
React.useEffect(() => {
 // setPatient("panya")
})

Object.assign(context, {
  patient,
  setPatient
});
  

  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{
        patient:patient
      }}>
    <div className="App">
      <Switch>
      <Route path="/register">
         <SignUp />
        </Route>
        <Route path="/forgot">
         <Forgot />
        </Route>
        <Route path="/login">
         <SignInSide />
        </Route>
        <Route path="/listuser">
         <Listusermanage />
        </Route>
        <Route path="/userform" component={UserForm}></Route>
        <Route path="/userformedit/:id" render={(props) => <UserForm {...props} />} ></Route>
        <Route path="/rxorder">
         <Rxorder />
        </Route>
        <Route path="/drug">
         <Listdrug />
        </Route>
        <Route path="/drugform" component={DrugForm}></Route>
        <Route path="/drugformedit/:id" render={(props) => <DrugForm {...props} />} ></Route>
        <Route path="/patient">
         <Patientlist />
        </Route>
        <Route path="/patientform" component={PatientForm}></Route>
        <Route path="/patientformedit/:id" render={(props) => <PatientForm {...props} />} ></Route>
        <Route path="/appointment">
         <Listappoint />
        </Route>
        <Route path="/report">
         <Report />
        </Route>
        <Route path="/excellimport">
         <Excellimport />
        </Route>
        <Route path="/finance">
         <Finance />
        </Route>
        <Route path="/config">
         <Config />
        </Route>
        <Route path="/packgate">
         <Packgate />
        </Route>
        <Route path="/print">
        <Print/>
        </Route>
        <Route path="/druguse">
        <Listdruguse/>
        </Route>
        <Route path="/packgateform" component={PackgateForm}></Route>
        <Route path="/packgateformedit/:id" render={(props) => <PackgateForm {...props} />} ></Route>
        <Route path="/">
         <Homes />
        </Route>
      </Switch>
    </div>
    </ThemeContext.Provider>
  </ThemeProvider>
  );
}

export default App;
