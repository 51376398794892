import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from '../home/Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
export default function Formdruguse(props:any) {
    const classes = useStyles();
    const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
    const {initData, saveCallback,open,id} = props;
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [drugusage,setDrugusage]= React.useState('');
    const [code,setCode]= React.useState('');
    const [name1,setName1]= React.useState('');
    const [name2,setName2]= React.useState('');
    const [name3,setName3]= React.useState('');
    const [shortlist,setShortlist]= React.useState('');
    const [common_name,setCommon_name]= React.useState('');
    const handleClosedialog = () => {
      props.handleClosedialog();
    };
      React.useEffect(() => {
        if(open){
          console.log(id)
          if(id){
            http.get("druguse/byid?id="+id, header).then(jsoncer => {
              jsoncer.data.items.map((row: any, index: any) => {
                setDrugusage(row.drugusage);
                setCode(row.code);
                setName1(row.name1);
                setName2(row.name2);
                setName3(row.name3);
                setShortlist(row.shortlist);
                setCommon_name(row.common_name);
              })
            })
          }else{
          http.get("druguse/maxid", header).then(jsoncer => {
            setDrugusage(String(parseInt(jsoncer.data.items.maxId)+1));
            setCode("");
                setName1("");
                setName2("");
                setName3("");
                setShortlist("");
                setCommon_name("");
          })
        }
        }
        
      }, [open,id]);
  
   
    const { register: registersave, getValues, handleSubmit: handleSubmitsave, setValue: setValuesave, control, setError } = useForm();
    const handleSave = (e:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        const tempData = initData || [{}];
        const data = {
          drugusage:drugusage,
          code:code,
            name1:name1,
            name2:name2,
            name3:name3,
            shortlist:shortlist,
            common_name:common_name
        }
        http.post('druguse?id='+id,data, header).then(json => {
            props.handleClosedialog();
              Swal.fire(
                  json.data.message,
                  '',
                  'success'
                );
          });
    };
   
   return ( 
    <React.Fragment>
     
     <form onSubmit={e => {
                        handleSave(e)
                    }}>
     <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">กำหนดวิธีการใช้ยา</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={6} sm={6}>
                               <TextField
                                       name="drugusage"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setDrugusage(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={drugusage}
                                       id="drugusage"
                                       label="No."
                                       
                                   />
       </Grid>
       <Grid item xs={6} sm={6}>
       <TextField
                                       name="code"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setCode(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={code}
                                       id="code"
                                       label="Code"
                                       
                                   />
         </Grid>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="name1"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setName1(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={name1}
                                       id="name1"
                                       label="บรรทัดที่1"
                                       
                                   />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                   <TextField
                                       name="name2"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setName2(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={name2}
                                       id="name2"
                                       label="บรรทัดที่2"
                                       
                                   />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                   <TextField
                                       name="name3"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setName3(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={name3}
                                       id="name3"
                                       label="บรรทัดที่3"
                                       
                                   />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                       name="shortlist"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setShortlist(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={shortlist}
                                       id="shortlist"
                                       label="Short List"
                                       
                                   />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                       name="common_name"
                                       variant="outlined"
                                       onChange={(e) => {
                                        setCommon_name(e.target.value)
                                       
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={common_name}
                                       id="common_name"
                                       label="Common name"
                                       
                                   />
       </Grid>
     </Grid>
   </DialogContent>
   <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>
 </form>
 </React.Fragment>
   );
}