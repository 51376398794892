import React, { useState, useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { createStyles, lighten, makeStyles, Theme,withStyles }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems  from '../home/listItems';
import ThemeContext from '../../ThemeContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Copyright from '../Copyright';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import useSafeFunction from "../usermanage/useSafeFunction";
import Header from '../home/Header'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from "@material-ui/core/InputAdornment";
import MomentUtils from "@date-io/moment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import "dayjs/locale/th";
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Icon from '@material-ui/core/Icon';
import Swal from 'sweetalert2';
import http from "../../http-common";
import Formconfirm from './Formconfirm';
const drawerWidth = 240;
moment.locale("th");
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  table: {
    minWidth: 650,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
},
}));

export default function Formfinance(props:any) {
  const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
  const appContext = React.useContext(ThemeContext);
  const classes = useStyles();
  const history = useHistory();
  const [open,setOpen] = React.useState(false);

  const [rxlistuser,setRxlistuser] = React.useState([{id:"",visit_date:"",visit_time:"",pt_prefix_id:"",pt_firstname:"",pt_lastname:"",ptid:""}]);
  const [order,setOrder] = React.useState([]);
  const [drug,setDrug] = React.useState([]);
  const {selectusers, rowsdata,handleRefease} = props;
  const [statusdata,setStatusdata]  = React.useState('3');
  const [editdata,setEditdata] =  React.useState([{id:"",order_tran_code:"",order_qty:"",order_price:"",id_unit:"",order_tran_pay:"",drugusage:"",name_unit:""}])
  const [sumdata,setSumdata] = React.useState('');
  
  const getlistoder = async (rowsdata:any)=>{
    await  http.get("order/byid?id="+rowsdata.id, header).then(json => {
        setOrder(json.data.order)
        setDrug(json.data.items)
        setSumdata(json.data.sumall);

    }).catch(function (error) {
      history.replace({
        pathname: `/login`,
      })
    });
  }
  useEffect(() =>{
      if(selectusers !== ""){
        getlistoder(rowsdata);
        setStatusdata(props.statusdata)
        console.log(rowsdata.visit_status)
      }
   
  },[selectusers,rowsdata])
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: true
  })
  const handleDrawerOpen = () => {
    setOpen(true);
    setEditdata([{id:"",order_tran_code:"",order_qty:"",order_price:"",id_unit:"",order_tran_pay:"",drugusage:"",name_unit:""}])
  };
  const confirmdrug =()=>{
    setOpen(true)
  }
  const cancelconfirmdrug=()=>{
    const data = {

    }
    swalWithBootstrapButtons.fire({
      title: '',
      text: "คุณต้องยืนยันยกเลิกใบเสร็จหรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        http.delete( "finance/deletefinance/"+rowsdata.bil_id, header).then(json => {
          getlistoder(rowsdata);
          setStatusdata("3");
          handleRefease();
         });
      }
    });
  }
  const editOrder = (row:any)=>{
    setEditdata(row)
    setOpen(true);
  }
  const deleteOrder = (row:any)=>{
    swalWithBootstrapButtons.fire({
      title: '',
      text: "คุณต้องการลบรายการนี้หรือไม่",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
       
        http.delete("order/" + row.id,header)
        .then(function (response: any) {
          getlistoder(rowsdata);
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        })
        
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        
      }
    })
  }
  const printsticker=async()=>{
    window.open("http://45.32.107.235/report/billpayment.php?vn="+rowsdata.id+"&pt_id="+rowsdata.visit_pt_id+"&autolization="+localStorage.getItem("token")+"&bran_id="+localStorage.getItem("bran_id"));
  }
  const orderDrugupdate= (row:any,newvalue:any) => {
    const order_tran_pay = newvalue * row.order_price ;
    const data = {
        order_qty:newvalue,
        order_tran_pay:order_tran_pay
    }
    http.put("order/"+row.id,data, header).then(json => {
      getlistoder(rowsdata);
    })
  }

  const form = (
    <React.Fragment>
         <Grid  container spacing={3}>
            <Grid  item xs={12}>ชื่อ สกุล {selectusers}</Grid>
            <Grid  item xs={12}>
<div className="pull-left">
{statusdata == "2" ?    <Button variant="contained" onClick={handleDrawerOpen} size="small" color="primary">เพิ่มรายการ</Button>: ""}                   
            </div>
            </Grid>
            <Grid  item xs={12}>
      <Table  size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell width="5%" align="center">#</TableCell>
            <TableCell width="5%" align="center">ประเภท</TableCell>
            <TableCell width="30%" align="center">รายการ</TableCell>
            <TableCell width="5%" align="center">จำนวน</TableCell>
            <TableCell width="5%" align="center">ราคา</TableCell>
            <TableCell width="5%" align="center">รวมราคา</TableCell>
            <TableCell width="25%" align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {drug.map((row: any, index: any) => {
           return( <TableRow  key={index}>
              <TableCell >{index+1}</TableCell>
           <TableCell align="center">{row.order_type}</TableCell>
           <TableCell align="center">{row.order_name}</TableCell>
           <TableCell align="center"> {row.order_tran_status == 2 ?  <TextField
                    value={row.order_qty}
                    onChange={(e) => {
                      const value = e.target.value;
                     orderDrugupdate(row,value)
                    }}
                    id={'qty-' + index}
                    name={'qty-' + index}
                    label="จำนวน"
                    type="number"
                    fullWidth
                  /> : row.order_qty }</TableCell> 
              <TableCell align="center">{row.order_price}</TableCell>
              <TableCell align="center">{row.order_qty * row.order_price}</TableCell>
             
              <TableCell align="center">
                
              {row.order_tran_status == 2 ?   <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px',minHeight: '30px'}} variant="outlined" onClick={() => {
               editOrder(row)
              }}  color="primary" >
                      <EditIcon/>
                    </Button> :""}
                    {row.order_tran_status == 2 ?  <Button variant="outlined" onClick={() => {
                          {
                  deleteOrder(row)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px',minHeight: '30px'}} color="secondary">
                <DeleteIcon/>
              </Button>:""}</TableCell>
            </TableRow>)
        })}
         {order.map((row: any, index: any) => {
           return( <TableRow key={index}>
              <TableCell >{index+1}</TableCell>
           <TableCell align="center">{row.order_type}</TableCell>
           <TableCell align="center">{row.order_name}</TableCell>
           <TableCell align="center"> {row.order_qty} </TableCell>
              <TableCell align="center">{row.order_price}</TableCell>
              <TableCell align="center">{row.order_qty * row.order_price}</TableCell>
              <TableCell width="5%" align="center"></TableCell>
              <TableCell align="center">
              {row.order_tran_status == 2 ?  <Button style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px',minHeight: '30px'}} variant="outlined" onClick={() => {
               editOrder(row)
              }}  color="primary" >
                      <EditIcon/>
                    </Button> : ""}
                    {row.order_tran_status == 2 ? <Button variant="outlined" onClick={() => {
                          {
                  deleteOrder(row)
                          }}} style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px',minHeight: '30px'}} color="secondary">
                <DeleteIcon/>
              </Button> :""}</TableCell>
            </TableRow>)
        })}
          <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="center">รวม</TableCell>
        <TableCell align="center">{sumdata}</TableCell>
        <TableCell align="center">บาท</TableCell>
        <TableCell align="center"></TableCell>
        </TableRow>
        </TableBody>
      </Table>
        </Grid>
        <Grid  item xs={12}>
<div className="pull-right">
{statusdata == "3" ?   <Button variant="contained" size="small" onClick={() => {
confirmdrug()
        }} color="primary">ยืนยันการชำระเงิน</Button> : ""}

{statusdata == "4" ? <Button variant="contained" size="small" onClick={() => {
          cancelconfirmdrug()
                  }} color="secondary">ยกเลิกการชำระเงิน</Button>
                  :""}
                  &nbsp;
{statusdata == "4" ? <Button variant="contained" onClick={() => {
          printsticker()
                  }} color="secondary" size="small" >พิมใบเสร็จ</Button>
                  :""}

        </div>
        <Formconfirm opendialogeditdrug = {open} rowsdata={rowsdata} sumdata={sumdata} handleClosedialog={() => {
  setOpen(false);
  getlistoder(rowsdata);
  handleRefease();
}}/>
        </Grid>
        
    </Grid>
</React.Fragment>
        );
  return (
    <div className={classes.root}>
    {form}
    </div>
  );
}
