import React from 'react';
import { makeStyles, Theme, createStyles ,withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Title from './Title';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from "moment";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {calAge,thDate,calMonth,calDay} from '../../Function';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useForm, Controller } from "react-hook-form";
import MenuItem from '@material-ui/core/MenuItem';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ThemeContext from '../../ThemeContext';
import DialogTitle from '@material-ui/core/DialogTitle';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import {AppContext} from '../../App';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AnyCnameRecord } from 'dns';
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import http from "../../http-common";
const useStyles = makeStyles((theme) => ({
    seeMore: {
      marginTop: theme.spacing(3),
    },
    table: {
      minWidth: 650,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  MuiTabPanelroots:{
    padding:0
  },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    avatar: {
      backgroundColor: red[500],
      },
      media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      }
      ,expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },expandOpen: {
        transform: 'rotate(180deg)',
      }
  }));
export default function Appointment(props:any) {
    const classes = useStyles();
    const [cerrentdatetime,SetCurrentdate] = React.useState<any>("");
    const [opdscreen, setOpdscreen]= React.useState<any>([]);
   const {initData, saveCallback,initOpdscreen,initPatient,opendialogappointform,id} = props;
    const [visitdate, setVisitdate] = React.useState('');
   
    const [vn, setVn] = React.useState('');
    const [opendialog, setOpendialog] = React.useState(false);
    const [app_type,setApp_type]= React.useState(0);
    const [nexttime,setNexttime]= React.useState('');
    const [app_no,setApp_no]= React.useState(0);
    const [clinic,setClinic] = React.useState('');
    const [userinbran,setUserinbran] = React.useState([{user_id:"",title:"",fname:"",lname:""}]);
    const [advice,setAdvice] = React.useState([{name_advice:""}]);
    const [doctor_id,setDoctor_id] = React.useState([{user_id:"",title:"",fname:"",lname:""}]);
    const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
    const [note,setNote] = React.useState('');
    const [pt_id,setPt_id] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
      new Date(),
  );
    const handleClosedialog = () => {
      props.handleClosedialogapppint();
    };
      React.useEffect(() => {
        http.get("userinbran/", header).then(json => {
          setUserinbran(json.data.items);
        })
        http.get("advice/", header).then(json => {
          setAdvice(json.data.items);
        })
        setPt_id(initPatient.id);
        if(id){
          initOpdscreen.map((row: any, index: any) => {
            setVisitdate(row.visit_date);
            setVn(row.vn);
            http.get("appoint/byid?id="+id, header).then(json => {
              json.data.items.map((row2: any, index2: any) => {
                setApp_type(row2.app_type);
                      setApp_no(row2.app_no);
                      setNote(row2.note);
                      setNexttime(row2.nexttime);
                      const nextdates = new Date(row2.nextdate);
                      setSelectedDate(nextdates);
                  if (Object.keys(userinbran).length > 0 && userinbran.length > 0) {
                        let i = 0;
                        for (const item of userinbran) {
                          if (item.user_id == row2.doctor) {
                            setDoctor_id([{user_id:item.user_id,title:item.title,fname:item.fname,lname:item.lname}])
                          }
                          i++;
                        }
                      }
              })
            })
          })
        }else{
          initOpdscreen.map((row: any, index: any) => {
            setVisitdate(row.visit_date);
            setVn(row.vn);
            setApp_type(0);
            setApp_no(0)
            setNote("")
            setNexttime("");
            setDoctor_id([{user_id:"",title:"",fname:"",lname:""}]);
          })
        }
        
       
      }, [initData,initOpdscreen,initPatient,id]);
      async function onGenderChange(event:any){
     if(event !== null){
      setApp_type(event.target.value);
     }
    }

    const setappdatecal = async(values :any)=>{
      const sumapp = app_type * app_no;
      const dates = new Date();
      dates.setDate(dates.getDate() + sumapp);
      setSelectedDate(dates)

    }
    const { register: registersave, getValues, handleSubmit: handleSubmitsave, setValue: setValuesave, control, setError } = useForm();
    const handleSave = (e:any) => {
        const header = { headers: { Authorization: 'Bearer ' + localStorage.getItem("token") } };
        const nextdates = moment(selectedDate, "DD-MM-YYYY");
        const tempData = initData || [{}];
        const data = {
            vn:vn,
            visit_date:visitdate,
            doctor:doctor_id[0].user_id,
            app_no:app_no,
            app_type:app_type,
            nextdate:nextdates,
            nexttime:nexttime,
            note:note,
            clinic:clinic,
            pt_id:pt_id
        }
      console.log(nextdates);
        http.post('appoint?id_app='+id,data, header).then(json => {
        
          props.handleClosedialogapppint();
            Swal.fire(
                json.data.message,
                '',
                'success'
              );
        });
    };
    const handleDateChange = (date: Date | null) => {
      setSelectedDate(date);
    };
   return ( 
    <React.Fragment>
     
     <form onSubmit={e => {
                        handleSave(e)
                    }}>
     <Dialog fullWidth={true} maxWidth="md" open={opendialogappointform} onClose={handleClosedialog} aria-labelledby="form-dialog-title">
   <DialogTitle id="form-dialog-title">AppointMent</DialogTitle>
   <DialogContent>
   <Grid container spacing={2}>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="visit_date"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setVisitdate(e.target.value)
                                       
                                    }}
                                       
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       aria-readonly={true}
                                       value={visitdate}
                                       id="visit_date"
                                       label="Visit Date"
                                       autoFocus
                                     
                                   />
       </Grid>
       <Grid item xs={4} sm={4}>
                               <TextField
                                       name="vn"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setVn(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       value={vn}
                                       id="vn"
                                       label="Visit ID"
                                       autoFocus
                                     
                                   />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        
                    </Grid>
                    <Grid item xs={4} sm={4}>
                    <Autocomplete
                     value={doctor_id[0]}
                    id="doctor_id"
                    onChange={(event: any, newValue: any) => {
if(newValue){
                      setDoctor_id([{user_id:newValue.user_id,title:newValue.title,fname:newValue.fname,lname:newValue.lname}]);

}
                        }}
                        getOptionSelected={(option, value) => option.user_id === value.user_id}
                      getOptionLabel={(option) => option.title +option.fname+" "+option.lname+ ""}
                      options={userinbran}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="นัดมาพบแพทย์"
                        variant="outlined"
                      />
                    )}
                  />
                              
       </Grid>
       <Grid item xs={3} sm={3}>
       <TextField
          onChange={(e:any) => {
            if(e.target.value !== 0){
              setApp_no(e.target.value)
            }
            
        }}
        type="number"
                                       name="app_no"
                                       variant="outlined"
                                       value={app_no}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="app_no"
                                       label="ระยะเวลา"
                                       autoFocus
                                       
                                     
                                   />
       </Grid>
       <Grid item xs={5} sm={5}>
       <FormLabel component="legend"></FormLabel>
                            <RadioGroup row={true}  aria-label="app_type" value={app_type} name="app_type"  
                              onChange={(e:any) => {
                                setApp_type(e.target.value);
                                console.log(app_type)
                            }}
                        
                            >
                               <FormControlLabel value="1" control={<Radio />} label="วัน" />
                                <FormControlLabel value="7" control={<Radio />} label="สัปดาห์" />
                                <FormControlLabel value="30" control={<Radio />} label="เดือน" />
                                <FormControlLabel value="365" control={<Radio />} label="ปี" />
                            </RadioGroup>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                    <MuiPickersUtilsProvider  utils={MomentUtils} locale={'th'}>
                                                <DatePicker
                                                    id="nextdate"
                                                    label="วันที่นัดมา"
                                                    name="nextdate"
                                                    format="DD/MM/YYYY"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    value={selectedDate}
                                                    yearOffset={543}
                                                    onChange={(e:any) => {
                                                      if(e !== null){
                                                        console.log(e);
                                                       handleDateChange(e)
                                                      }
                                                    }
                                                    }
                                                    inputVariant="outlined"
                                                
                                                />
        </MuiPickersUtilsProvider>
       </Grid>
       <Grid item xs={4} sm={4}>
       
                                    <InputLabel id="demo-simple-select-label">เวลาที่นัดมา</InputLabel>
                                    <Select
                                    name="nexttime"
                                    
                                    value={nexttime}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(e:any) => {
                                          if(e !== null){
                                            console.log(e.target.value)
                                           setNexttime(e.target.value);
                                          }
                                        
                                        }}
                                    >
                                        <MenuItem value={'08:00:00'}>08.00</MenuItem>
                                        <MenuItem value={'09:00:00'}>09.00</MenuItem>
                                        <MenuItem value={'10:00:00'}>10.00</MenuItem>
                                        <MenuItem value={'11:00:00'}>11.00</MenuItem>
                                        <MenuItem value={'12:00:00'}>12.00</MenuItem>
                                        <MenuItem value={'13:00:00'}>13.00</MenuItem>
                                        <MenuItem value={'14:00:00'}>14.00</MenuItem>
                                        <MenuItem value={'15:00:00'}>15.00</MenuItem>
                                        <MenuItem value={'16:00:00'}>16.00</MenuItem>
                                        <MenuItem value={'17:00:00'}>17.00</MenuItem>
                                        <MenuItem value={'18:00:00'}>18.00</MenuItem>
                                    </Select>

                                
       </Grid>
       <Grid item xs={4} sm={4}>
       <Button variant="contained" onClick={setappdatecal} color="primary">
       คำนวนวันที่นัด
     </Button>
       </Grid>
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="clinic"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setClinic(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="clinic"
                                       label="เหตุผลที่นัดมา"
                                       value={clinic}
                                   />
       </Grid>
       <Grid item xs={12} sm={12}>
       <Autocomplete
                    //  value={doctor_id[0]}
                    id="temp"
                    onChange={(event: any, newValue: any) => {
                      if(newValue !== null){
                        console.log(newValue.name_advice)
                        setNote(note+' '+newValue.name_advice)
                      }
                       
                        }}
                      getOptionSelected={(option, value) => option.name_advice === value.name_advice}
                      getOptionLabel={(option) => option.name_advice+ ""}
                      options={advice}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="คำแนะนำก่อนมาตามนัด (ชุดข้อความ)"
                        variant="outlined"
                      />
                    )}
                  />
                           
       </Grid>
       
       
       
       <Grid item xs={12} sm={12}>
       <TextField
                                       name="note"
                                       variant="outlined"
                                       onChange={(e) => {
                                        console.log(e.target.value)
                                        setNote(e.target.value)
                                    }}
                                       fullWidth
                                       InputLabelProps={{
                                               shrink: true
                                       }}
                                       id="note"
                                       label="คำแนะนำก่อนมาตามนัด"
                                       multiline
                                       rows={3}
                                       value={note}
                                   />
       </Grid>
     </Grid>
   </DialogContent>
   <DialogActions>
   <Button variant="contained" onClick={handleSave} color="primary">
       บันทึกข้อมูล
     </Button>
     <Button variant="contained" onClick={handleClosedialog} color="secondary">
       Cancel
     </Button>
   </DialogActions>
 </Dialog>
 </form>
 </React.Fragment>
   );
}